import React from "react";
import combinator from "../../assets/images/combinator.png";
import stripe from "../../assets/images/stripe.png";
import openSea from "../../assets/images/openSea.png";
import doorDarsh from "../../assets/images/doorDarsh.png";
import razor from "../../assets/images/razor.png";
import america from "../../assets/images/america.png";
import morgan from "../../assets/images/morgan.png";
import dummy1 from "../../assets/images/dummy1.png";
import Button from "@mui/joy/Button";
import Stack from "@mui/material/Stack";
import { textAlign } from "@mui/joy/node_modules/@mui/system";

function HireCandidates() {
  return (
    <>
      <div className="hire_candidate_layout_sec">
        <div className="hire_cand_main_sec">
          <div
            className="col-sm-12 col-md-12 col-lg-5"
            style={{ paddingTop: "2%" }}
          >
            <div className="companies_list">
              <h2 className="hire_heading_txt">
                1000 + Companies hired outstanding talent on zepul
              </h2>
              <div className="comp_grid_con">
                <img src={combinator} />
                <img src={stripe} />
                <img src={openSea} />
                <img src={doorDarsh} />
                <img src={razor} />
                <img src={america} />
                <img src={morgan} />
                <h2 className="hire_heading_txt">& many more</h2>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-7">
            <div className="companies_hire_sec">
              {/* <div style={{ padding: "30px" }}>
                <div className="compan_inside_sec">
                  <Button
                    variant="contained"
                    style={{
                      background: "#465585",
                      color: "#ffffff",
                      fontFamily: "Inter ,sans-serif",
                      fontWeight: "700",
                    }}
                  >
                    For Employers
                  </Button>
                  <h2 className="hire_txt">
                    Hire better tech <br />
                    Talent, faster
                  </h2>
                  <p className="hire_para_txt">
                    Looking to hire ? The candidates on Hired are <br />
                    qualified and ready to interview
                  </p>
                  <Button
                    variant="contained"
                    style={{
                      background: "#FFFFFF",
                      color: "#081854",
                      fontFamily: "Inter ,sans-serif",
                      fontWeight: "700",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Hire candidates Now
                  </Button><br/>
                  <Button
                    variant="contained"
                    style={{
                      background: "#081854",
                      border:"1px solid #ffffff",
                      color: "#ffffff",
                      fontFamily: "Inter ,sans-serif",
                      fontWeight: "700",
                      textAlign: "center",
                      marginTop: "10px",
                    }}
                  >
                    Learn more
                  </Button>
                </div>
              </div> */}
              <div className="inside_main_section">
                <div style={{ padding: "30px" }}>
                  <div className="compan_inside_sec">
                    <Button
                      variant="contained"
                      style={{
                        background: "#465585",
                        color: "#ffffff",
                        fontFamily: "Inter ,sans-serif",
                        fontWeight: "700",
                      }}
                      onClick={() =>
                        window.open(
                          "https://app.zepul.com/login?user_type=CA",
                          "_blank"
                        )
                      }
                    >
                      For Employers
                    </Button>
                    {/* <h2 className="hire_txt">
                    Hire better tech <br />
                    Talent, faster
                  </h2> */}

                    <h2 className="hire_txt">Hire using Zepul</h2>

                    {/* <p className="hire_para_txt">
                    Looking to hire ? The candidates on Hired are <br />
                    qualified and ready to interview
                  </p> */}
                    <p className="hire_para_txt">
                      We incentivise candidates and referral partners to refer
                      suitable candidates from their network to jobs posted on
                      Zepul platform
                    </p>
                    <Button
                      variant="contained"
                      style={{
                        background: "#FFFFFF",
                        color: "#081854",
                        fontFamily: "Inter ,sans-serif",
                        fontWeight: "700",
                        textAlign: "center",
                        marginTop: "10px",
                        // width:"50%"
                      }}
                      onClick={() =>
                        window.open(
                          "https://app.zepul.com/login?user_type=CA",
                          "_blank"
                        )
                      }
                    >
                      Hire candidates Now
                    </Button>
                    <br />
                    {/* <Button
                      // variant="contained"
                      style={{
                        background: "#081854",
                        border: "1px solid #ffffff",
                        color: "#ffffff",
                        fontFamily: "Inter ,sans-serif",
                        fontWeight: "700",
                        textAlign: "center",
                        marginTop: "10px",
                        // width:"50%"
                      }}
                    >
                      Learn more
                    </Button> */}
                  </div>
                </div>
                <img src={dummy1} className="dumm1_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HireCandidates;
