import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel3";
import Button from "@mui/joy/Button";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Benefits from "../../../assets/images/Benefits.png";
import noJobs from "../../../assets/images/no_jobs.svg";

function OverViewPage2({cProfile}) {
  let array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      gap: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const perksList = [
    {
      name: "Meals",

      selected: false,

      image: "https://img.icons8.com/fluency/48/000000/buffet-breakfast.png",
    },

    {
      name: "Health Care",

      selected: false,

      image: "https://img.icons8.com/doodle/48/000000/nature-care.png",
    },

    {
      name: "Retirement",

      selected: false,

      image:
        "https://img.icons8.com/external-smashingstocks-thin-outline-color-smashing-stocks/67/000000/external-benefits-finance-smashingstocks-thin-outline-color-smashing-stocks.png",
    },

    {
      name: "Parental Leave",

      selected: false,

      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-parental-parenthood-flaticons-flat-flat-icons.png",
    },

    {
      name: "Equity Benefits",

      selected: false,

      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-equity-accounting-flaticons-flat-flat-icons.png",
    },

    {
      name: "Remote",

      selected: false,

      image: "https://img.icons8.com/doodle/48/000000/home-office.png",
    },

    {
      name: "Vaccation",

      selected: false,

      image:
        "https://img.icons8.com/external-gradient-design-circle/66/000000/external-vacation-hotel-and-services-gradient-design-circle.png",
    },

    {
      name: "Wellness",

      selected: false,

      image:
        "https://img.icons8.com/external-bearicons-detailed-outline-bearicons/64/000000/external-Wellness-human-mind-bearicons-detailed-outline-bearicons.png",
    },

    {
      name: "Pet Freindly",

      selected: false,

      image: "https://img.icons8.com/officel/80/000000/dog-park.png",
    },

    {
      name: "Others",

      selected: false,

      image: "https://img.icons8.com/ios/50/000000/gift--v1.png",
    },
  ];

  const matchedPerks = perksList.filter((perk) => cProfile?.company?.perks?.includes(perk.name));

  return (
    <>
      <div className="overView_sec2">
        <h2 className="benefits_txt">Benefits reported by employees</h2>
        <div className="benefits_sec">
          {matchedPerks?matchedPerks.map((each, index) => (
            <div className="card_section" key={index}>
              <div className="company_inside_sect">
                <div
                  style={{
                    // height: "44px",
                    // width: "44px",
                    borderRadius: "50%",
                    border: "1px",
                  }}
                >
                  <img src={each.image} style={{height:"30px",width:"30px"}}/>
                </div>
                <p className="benefits_heading">{each.name}</p>
              </div>
            </div>
          )):  <div className="d-flex align-items-center flex-column">
          <img
            src={noJobs}
            style={{ width: "300px", height: "300px" }}
            alt="no jobs"
          />
          <p>No Benefits Found</p>
        </div>}
        </div>
      </div>



    </>
  );
}

export default OverViewPage2;
