import React, { useEffect, useState } from "react";
import "./login.css";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { updateNumber, sendOtp } from "../helpers/dataFetcher/profile/update";
import OtpInput from "react18-input-otp";
import CountdownTimer from "./CountDown/CountdownTimer";
import { verifyMobileOTP } from "../helpers/dataFetcher/otpVerifiy/verify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import { MuiTelInput } from "mui-tel-input";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

function ChangePhoneNumber() {
  const [userDetails, setUserDetails] = useState();
  const [phone, setPhone] = React.useState("");
  const [confPhone, setConfPhone] = React.useState("")
  const [oldNum, setOldNum] = React.useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const userData = useSelector(state => state.User);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const { data } = await getProfile();
    setUserDetails(data);
  };

  const obj = {
    profile_image: userData.user.profile_image,
    last_name: userData.user.last_name,
    first_name: userData.user.first_name,
    email: userDetails?.email,
    dob: userData.user.dob,
    gender: userData.user.gender,
    resume_file: userData.user.resume_file,
    phone_number: userDetails?.phone_number,
    country: userData.user.country,
    state: userData.user.state,
    city: userData.user?.city,
    gender: userData.user?.gender,
    postal_code: userData.user?.postal_code,
    address: userData.user?.address?.description,
    description: userData.user?.about,
    update_type_to_send_email: "mobile",
  };
  let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  const [otpModalView, setOtpModalView] = useState(false);
  const [otp, setOtp] = useState("");
  const [hasError, setHasError] = useState(false);
  const handleChange = code => setOtp(code);

  /*Verify otp and update function start*/
  const handleOTP = async () => {
    const { status } = await verifyMobileOTP(otp);
    {
      /*if (status !== 201) {
      setHasError(true);
      toast.error("invalid otp");
      return;
    } else if (status == 200){
        const number = getValues("newNumber")
        
        const {data,status} = await updateNumber(number)
        navigate("/profile");
    }*/
    }
    if (status === 200) {
      // const number = getValues("newNumber");
      const number = phone
      const { data, status } = await updateNumber("about_me", o, number);
      if (status === 200) {
        toast.success("Updated Successfully!!");
      } else if (status !== 200) {
        toast.error("Something went wrong");
        return;
      }
    }

    setOtpModalView(false);
    navigate("/profile");
    setHasError(false);
  };
  /*Verify otp and update function End*/

  /*send otp function */
  const changeNumber = async numbers => {
  
    // const { status } = await sendOtp("mobile", numbers.phone.toString());
    const { status } = await sendOtp("mobile", phone.toString());
    setOtpModalView(true);
    // if(phone){
    //   setOtpModalView(true);
    // }else{
    //   toast.error("please enter number")
    // }
  };

  /*send otp function End*/

  /*Mobile Number Extraction with zero spaces start*/
  const handleChangeNum = newPhone => {
    var mobile = "";
    if (newPhone.slice(0, 1) == "+" || newPhone.slice(0, 1) == "0") {
      mobile = newPhone.replace(/[^a-zA-Z0-9+]/g, "");
    } else {
      mobile = newPhone.replace(/[^a-zA-Z0-9]/g, "");
    }
    setPhone(mobile);
  };
  const handleChangeOldNum = oldNum => {
    var oldMobile = "";
    if (oldNum.slice(0, 1) == "+" || oldNum.slice(0, 1) == "0") {
      oldMobile = oldNum.replace(/[^a-zA-Z0-9+]/g, "");
    } else {
      oldMobile = oldNum.replace(/[^a-zA-Z0-9]/g, "");
    }
    setOldNum(oldMobile);
  };

  const handleChangeConfNum = confNum => {
    var confMobile = "";
    if (confNum.slice(0, 1) == "+" || confNum.slice(0, 1) == "0") {
      confMobile = confNum.replace(/[^a-zA-Z0-9+]/g, "");
    } else {
      confMobile = confNum.replace(/[^a-zA-Z0-9]/g, "");
    }
    setConfPhone(confMobile);
  };
  /*Mobile Number Extraction with zero spaces End*/

  return (
    <div className="main_container">
      <h1 className="password_heading">Number</h1>
      <p className="password_paragraph">
        Please enter your current number to change your number
      </p>
      {/* <hr /> */}
      <Box
        component="form"
        onSubmit={handleSubmit(data =>changeNumber(data))}
        // noValidate
        sx={{ mt: 1 }}
        className="form_container"
      >
        <div className="bank_card_cont">
          <p>Current Number</p>
          <div className="bank_input_filed_card">
            {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  //   id="email"
                  label="Old number"
                  name="email"
                  type="text"
                  //   autoComplete="email"
                  autoFocus
                  // error={errors.oldPassword ? true : false}
                  {...register("oldNumber", { required: true })}
                  InputProps={{
                    style: { borderRadius: "5px" },
                  }}
                /> */}
            <MuiTelInput
              defaultCountry="US"
              label="Old Number"
              margin="normal"
              fullWidth
              required
              // countryCodeEditable={true}
              value={oldNum}
              onChange={e => handleChangeOldNum(e)}
              error={errors.oldNumber?true:false}
              InputProps={{
                style: { borderRadius: "5px", height: "50px" },
              }}
              // {...register("oldNumber", { required: true })}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="bank_card_cont">
          <p>New Number</p>
          <div className="bank_input_filed_card">
            {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  //   id="email"
                  label="New number"
                  name="email"
                  type="text"
                  //   autoComplete="email"
                  autoFocus
                  // error={errors.newPassword ? true : false}
                  {...register("newNumber", { required: true })}
                  InputProps={{
                    style: { borderRadius: "5px" },
                  }}
                /> */}
            <MuiTelInput
              defaultCountry="IN"
              margin="normal"
              label="New Number"
              fullWidth
              // countryCodeEditable={true}
              value={phone}
              onChange={e => handleChangeNum(e)}
              error={errors.newNumber?true:false}
              InputProps={{
                style: { borderRadius: "5px", height: "50px" },
              }}
              // {...register("newNumber", { required: true })}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="bank_card_cont">
          <p>Confirm Number</p>
          <div className="bank_input_filed_card">
            {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  //   id="email"
                  label="New number"
                  name="email"
                  type="text"
                  //   autoComplete="email"
                  autoFocus
                  // error={errors.newPassword ? true : false}
                  {...register("newNumber", { required: true })}
                  InputProps={{
                    style: { borderRadius: "5px" },
                  }}
                /> */}
            <MuiTelInput
              defaultCountry="IN"
              margin="normal"
              label="Confirm Number"
              fullWidth
              // countryCodeEditable={true}
              value={confPhone}
              onChange={e => handleChangeConfNum(e)}
              error={errors.confirmNumber?true:false}
              InputProps={{
                style: { borderRadius: "5px", height: "50px" },
              }}
              // {...register("confirmNumber", { required: true })}
            />
          </div>
        </div>
        {/* <hr /> */}
        <div className="d-flex justify-content-center submit-button mt-4">
          <Stack spacing={2} direction="row">
           
            <Button variant="contained" type="submit" style={{background:"#081753",color:"#ffffff",textTransform:"capitalize"}}>
              Update
            </Button>
          </Stack>
        </div>
      </Box>
      {otpModalView ? (
        <>
          <Modal
            // {...props}
            // onClick={()=>setOtpModalView(false)}
            show={otpModalView}
            backdrop="static"
            className="custom-delete-modal mt-5 pt-5"
            keyboard={false}
          >
            <Modal.Header
              className="border-0 px-4 bg-transparent"
              onClick={() => setOtpModalView(false)}
              closeButton
            >
              OTP Verify
            </Modal.Header>
            <Modal.Body
              className="pt-0"
              style={{ padding: "30px", backgroundColor: "#bebebe" }}
            >
              <div className="d-flex justify-content-center pb-0 py-4">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  shouldAutoFocus
                  isInputNum={true}
                  inputStyle="otp_input_items"
                  errorStyle={"otp_input_items_error"}
                  hasErrored={hasError}
                  focusStyle={"otp_input_items_focus"}
                  separator={<span> </span>}
                  onSubmit={handleOTP}
                />
              </div>

              <CountdownTimer
                email={userData.user.email}
                totalSec={20 * 10000}
              />

              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleOTP}
                >
                  Verify OTP
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ChangePhoneNumber;
