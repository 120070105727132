import React from "react";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/joy/Chip";
import getSymbolFromCurrency from "currency-symbol-map";
import Divider from "@mui/material/Divider";

function JobInsights(props) {

  return (
    <>
      <div className="insights_sec">
        <Typography className="job_insights">Job role insights</Typography>

        <div className="jobs_title_card pb-5">
          <Chip
            variant="solid"
            color="neutral"
            size="sm"
            style={{ color: "#777AD6", background: "#F5ECFF" }}
          >
            {props.jobDetails?.job_type?.replace("_", " ")}
          </Chip>

          <Chip
            variant="solid"
            color="neutral"
            size="sm"
            // icon={<LocationOnIcon />}
            style={{ color: "#081753", background: "rgba(8, 23, 83, 0.07)" }}
          >
            {props.jobDetails?.location?.length <= 17
              ? props.jobDetails?.location
              : props.jobDetails?.location?.slice(0, 35) + ".."}
          </Chip>

          <Chip
            variant="solid"
            color="neutral"
            size="sm"
            // icon={<LocationOnIcon />}
            style={{ color: "#081753", background: "rgba(8, 23, 83, 0.07)" }}
          >
            {props.jobDetails?.min_exp}-{props.jobDetails?.max_exp}
            &nbsp; Yrs
          </Chip>
          <Chip
            variant="soft"
            color="neutral"
            size="sm"
            style={{ background: "#F2F8F6", color: "#07A996" }}
          >
            {getSymbolFromCurrency(props.jobDetails?.currency)}
            {Math.round(props.jobDetails?.min_sal / 1000, 2)}k-
            {Math.round(props.jobDetails?.max_sal / 1000, 2)}k
          </Chip>
          <Chip
            variant="soft"
            color="neutral"
            size="sm"
            style={{ background: "#F2F8F6", color: "#07A996" }}
          >
            {props.jobDetails?.work_mode?.replace("_", " ")}
          </Chip>
        </div>
      </div>
      <Divider orientation="horizontal" sx={{ height: "auto !important", }} />
    </>
  );
}

export default JobInsights;
