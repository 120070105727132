import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { NotificationSwitch } from "../../assets/MUI/globalStyles";
import { NotificationTitleTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTypography } from "../../assets/MUI/globalStyles";
import { NotificationDescTextTypography } from "../../assets/MUI/globalStyles";

function NotificationTable() {
  const [showAll, setShowAll] = useState(true);
  const [showEmailAll, setShowEmailAll] = useState(true);

  const [NotificationsList, setNotificationsList] = useState([
    {
      id: 0,
      type: "email",
      name: "Matching Job Alerts",
      description: {
        heading:
          "Receive curated job recommendations based on your qualifications and interests.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      applicationStatus: true,
      applicationEmailStatus: true,
    },
    {
      id: 2,
      name: "Track my Application.",
      type: "email",
      description: {
        heading:
          "Easily track the progress of your job applications in real-time, all in one place.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      applicationStatus: true,
      applicationEmailStatus: true,
    },
    {
      id: 3,
      type: "email",
      name: "Perfectly matching job email alerts",
      description: {
        heading:
          "Be the first to know about new job openings perfectly matching your skills with personalized email alerts.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      applicationStatus: true,
      applicationEmailStatus: true,
    },
    {
      id: 4,
      name: "Schedule interview alerts",
      type: "email",
      description: {
        heading:
          "Stay ahead of your interview game with real-time notifications for upcoming scheduled interviews.",
        statusDescription:
          "You will receive a notification when you make a change in the administration such as enabling or disabling features",
      },
      applicationStatus: true,
      applicationEmailStatus: true,
    },
  ]);

  /*Global Check func start here for both normal & email */
  const handleChangeSwitch = e => {
    const result = NotificationsList?.map(each => {
      return {
        ...each,
        applicationStatus:!showAll
      }
     });
     // Do something with the updated result
     setNotificationsList(result);
     setShowAll(!showAll);
  };

  const handleChangeEmailSwitch = e => {
    const result = NotificationsList?.map(each => {
     return {
       ...each,
       applicationEmailStatus:!showEmailAll
     }
    });
    // Do something with the updated result
    setNotificationsList(result);
    setShowEmailAll(!showEmailAll);
  };

 /*Global Check func start here for both normal & email */
 

/* Individual checks of both normal & email func start here */
  const handleSwitchIterate = (row) => {
    const result = NotificationsList?.map(each => {
      if (each.id == row.id) {
        return {
          ...each,
          applicationStatus: !each.applicationStatus,
        };
      }
      return each;
    });
    setNotificationsList(result);
  };

  const handleEmailNotify = row => {
  
    const result = NotificationsList?.map(each => {
      if (each.type == row.type && each.id == row.id) {
        return {
          ...each,
          applicationEmailStatus: !each.applicationEmailStatus,
        };
      }
      return each;
    });
    setNotificationsList(result);
  };
/* Individual checks of both normal & email func End here */

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="start">Notification Settings</TableCell>
              <TableCell align="start">
                In Application{" "}
                <NotificationSwitch
                  onChange={e => handleChangeSwitch(e)}
                  checked={showAll ? showAll : false}
                />
              </TableCell>
              <TableCell align="start">
                Email{" "}
                <NotificationSwitch
                  onChange={e => handleChangeEmailSwitch(e)}
                  checked={showEmailAll ? showEmailAll : false}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {NotificationsList?.map(row => (
              <TableRow
                //   key={row.name}
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {/* {row.name} */}
                  <NotificationTitleTypography>
                    {row.name}
                  </NotificationTitleTypography>
                </TableCell>
                {/* <TableCell align="right">{row.calories}</TableCell> */}
                <TableCell align="start">
                  <Grid>
                    <NotificationDescTypography>
                      {row.description.heading}
                    </NotificationDescTypography>
                    <NotificationDescTextTypography>
                      {row.description.statusDescription}
                    </NotificationDescTextTypography>
                  </Grid>
                </TableCell>

                <TableCell align="start">
                  <NotificationSwitch
                     checked={
                      showAll
                        ? showAll
                        : row.applicationStatus
                        ? row.applicationStatus
                        : row.applicationStatus
                    }
                    onChange={e => handleSwitchIterate(row)}
                  />
                </TableCell>
                <TableCell align="start">
                  <NotificationSwitch
                    // checked={showEmailAll ? showEmailAll : false}
                    // checked={showEmailAll?showEmailAll:}
                    onChange={e => handleEmailNotify(row)}
                    checked={
                      showEmailAll
                        ? showEmailAll
                        : row.applicationEmailStatus
                        ? row.applicationEmailStatus
                        : row.applicationEmailStatus
                    }
                  />
                </TableCell>
                {/* <TableCell align="start">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default NotificationTable;
