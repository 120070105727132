import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllFunctionalArea } from "../../helpers/dataFetcher/jobAttributes/functionalArea";
import { findAllFuncArea } from "../../helpers/dataFetcher/jobs/jobs";

function MoreJobs() {
  const [FunctionalArea, setFunctionalArea] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher();
  }, []);
  const dataFetcher = async () => {
    const { data: fn_areas } = await findAllFuncArea();
    console.log(fn_areas, "fn_areas");
    setFunctionalArea(
      fn_areas?.map(item => ({
        name: item.name,
        id: item.id,
        img: "bx bxs-envelope",
        url: `/jobs-list?fn_areas=${item.id}`,
        vacancies: item.countOfJobs,
      }))
    );
  };
  const navigate = useNavigate();

  return (
    <>
      <section className="section-box mt-20">
        <div className="container">
          <h2 className="section-title mb-20 wow animate__animated animate__fadeInUp" style={{fontSize:"22px",fontFamily:"Inter"}}>
            All Categories
          </h2>
          <p className="text-md-lh28 color-black-5 wow animate__animated animate__fadeInUp">
            Find the type of work you need, clearly defined and ready to start.
            Work begins as soon as you purchase and provide requirements.
          </p>
          <div className="p-4">
            <div className="row mt-30">
              {FunctionalArea.map(item => (
                <div
                  key={item.id}
                  className="col-lg-3 col-md-6 col-sm-12 col-12 mb-3"
                >
                  <div
                    className="card-grid cursor-pointer card-radius hover-up wow animate__animated animate__fadeInUp"
                    data-wow-delay=".1s"
                    onClick={() => navigate(item.url)}
                  >
                    <div className="text-center mb-3 jobs-icon fa-2x">
                      <i className={item.img}></i>
                    </div>
                    <h5 className="text-center card-heading" style={{fontSize:"18px",fontFamily:"Inter"}}>
                      {item.name} ({item.vacancies?item.vacancies:"0"})
                    </h5>
                    {/* <p className="text-center text-stroke-40">{item.vacancies}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MoreJobs;
