import React from 'react';
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Container } from "reactstrap";
import CompanyOverViewPage from './CompanyOverviewPage';
import CompanyBasedJobs from './CompanyBasedJobs';

function CompanyOverViewJobs({company,compJobList}){
 
    const [value, setValue] = React.useState("1");

    const StyledTab = styled(Tab)(({ theme }) => ({
      fontSize: 16,
      fontFamily: "Inter,sans-serif",
      textTransform: "none",
      "&:first-letter": {
        textTransform: "capitalize",
      },
    }));
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <>
           <Box
              sx={{
                // width: "88%",
                width:"100%",
                typography: "body1",
                // border: "2px solid whitesmoke",
                borderRadius: "10px",
                marginTop: "10px",
                // "@media (max-width: 577px)": {
                //   marginLeft: "10%",
                // },
              }}
            >
              <TabContext value={value}>
                <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    sx={{
                      overflowX: "scroll !important",
                      ".MuiTabs-indicator": {
                        backgroundColor: "#081753",
                        color: "#081753",
                      },
                      ".MuiTab-root.Mui-selected ": {
                        color: "#081753",
                        // backgroundColor:"#081753",
                        borderRadius: "8px",
                      },
                      "& .css-heg063-MuiTabs-flexContainer": {
                        overflow: "auto",
                      },
                    }}
                  >
                    <StyledTab label=" Overview" value="1" />
                    <StyledTab label=" Jobs" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <CompanyOverViewPage company={company}/>
                </TabPanel>
                <TabPanel value="2" style={{height:"400px",overflow:"auto"}}>
                <CompanyBasedJobs compJobList={compJobList}/>
                </TabPanel>
               
                
              </TabContext>
            </Box>
        </>
    )
}

export default CompanyOverViewJobs;