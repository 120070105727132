import FeatherIcon from "feather-icons-react";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import moment from "moment";
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { Link, Navigate } from "react-router-dom";
import { resumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import { useSelector } from "react-redux";
// import { getScreeningQuestions } from "../../../helpers/dataFetcher/jobs/jobs";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Box, Divider, Grid, TextField } from "@mui/material";
import { Col, Form, Row, Input } from "reactstrap";
import Stack from "@mui/material/Stack";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { updateScreeningQuestion } from "../../../helpers/dataFetcher/jobs/jobs";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
export default function ScreeningQuestions({
  show,
  onHide,
  step,
  purpose,
  setStep,
  setStatus,
  details,
  setResumeDetails,
  screeningQuestions,
  setScreeningQuestions,
  setSeshuModal,
}) {
  const user = useSelector(state => state.User.user);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [screeningQuestionsList, setScreeningQuestionsList] = useState([]);
  const textareaRef = useRef();

  const [approved, setApproved] = React.useState(false);

  const onSubmit = async data => {
    const answerValue = getValues();
    //  let answersList = [data]
    let answersList = [answerValue];

    const filteredScreeningData = screeningQuestions?.questions_data?.filter(
      each => {
        const question = each.job_post_question[0];
        return Object.keys(answersList[0]).includes(question);
      }
    );

    const finalData = filteredScreeningData.map((each, i) => {
      return {
        question: each.id,
        answer: Object.values(answersList[0])[i],
        user_job_post_id: each.user_job_post_id,
      };
    });

    const { status } = await updateScreeningQuestion({
      job_post_id: screeningQuestions?.questions_data[0]?.job_post_id,
      answers_data: finalData,
    });
    if (status == 200) {
      toast.success("Answers submitted successfully");
      setStep(1);
      onHide();
      setSeshuModal();
    } else {
      toast.error("Something went error");
      onHide();
    }
  };

  const escapeQuotes = question => {
    return question.replace(/"/g, '\\"'); // Replace double quotes with escaped double quotes
  };

  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Typography
            id="layout-modal-title"
            component={Link}
            level="inherit"
            to={`../job/${details?.id}`}
            className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
            fontSize="1.25em"
            mb="0.25em"
          >
            <FeatherIcon icon="briefcase" size="14" />
            {details?.job_title}
          </Typography>
          {purpose === "self" ? (
            <>
              {" "}
              <Form
                style={{
                  overflowY: "scroll",
                  height: "40vh",
                }}
                onSubmit={handleSubmit(onSubmit)}
                className="candidate_details_fields"
              >
                {/* <Row style={{ width: "100%", height: "", overflowX: "hidden" }}>
                  {screeningQuestions?.questions_data?.map(each => (
                    <Col className="mt-4" xs={12} md={12} lg={12}>
                      <div
                        style={{
                          maxHeight: "100%",
                          overflowY: "scroll",
                        }}
                        key={each?.id}
                      >
                        <label>{each?.job_post_question[0]}</label>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Answer here…"
                          // error={Boolean(errors[each.question])}
                          {...register(`${each?.job_post_question[0]}`, { required: true })}
                          // {...register("answer", { required: true })}
                          style={{
                            minHeight: "60px",
                            maxHeight: "61px",
                            borderRadius: "8px",
                            // borderColor: errors[each.question] ? "red" : "#ced4da",
                            overflowY: "scroll !important",
                          }}
                          // ref={textareaRef}
                        />
                         
                      </div>
                    </Col>
                  ))}
                </Row> */}

                <Row style={{ width: "100%", height: "", overflowX: "hidden" }}>
                  {screeningQuestions?.questions_data?.map(each => (
                    <Col
                      className="mt-4"
                      xs={12}
                      md={12}
                      lg={12}
                      key={each?.id}
                    >
                      <div style={{ maxHeight: "100%", overflowY: "scroll" }}>
                        <label>
                          {each?.job_post_question[0]?.replace(/["']/g, "")}
                        </label>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Answer here…"
                          {...register(
                            escapeQuotes(
                              each?.job_post_question[0]?.replace(/["']/g, "")
                            ),
                            { required: true }
                          )}
                          style={{
                            minHeight: "60px",
                            maxHeight: "61px",
                            borderRadius: "8px",
                            overflowY: "scroll !important",
                          }}
                          // ref={textareaRef}
                          innerRef={textareaRef}
                        />
                      </div>
                    </Col>
                  ))}
                </Row>

                <Stack spacing={2} direction="row" sx={{ marginTop: "30px" }}>
                  {/* <Button
                    type="submit"
                    // className="theme_new_btn"
                    className="mt-2"
                    onClick={() => {
                      // setStep(step - 1);
                      setStep((pre)=>pre-1)
                    }}
                  >
                    Back
                  </Button> */}
                  <Button type="submit" className="mt-2">
                    Submit
                  </Button>
                </Stack>
              </Form>
            </>
          ) : (
            <></>
          )}
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
