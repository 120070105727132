import FeatherIcon from "feather-icons-react";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import moment from "moment";
import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Typography from "@mui/joy/Typography";
import { formatDistance } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { Grid } from "@mui/joy";
import { referSelf } from "../../../helpers/dataFetcher/jobs/jobs";
import { toast } from "react-hot-toast";

export default function LayoutModalDialog({
  show,
  onHide,
  step,
  setStep,
  details,
  purpose,
  setPurpose,
}) {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Modal
        open={!!show}
        onClose={() => {
          onHide("");
          setStep(1);
        }}
        style={{ overflowY: "scroll", maxHeight: "100vh" }}
      >
        <ModalDialog
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
        >
          <ModalClose
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Typography
            id="layout-modal-title"
            component={Link}
            level="inherit"
            to={`../job/${details?.id}`}
            className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
            fontSize="1.25em"
            mb="0.25em"
          >
            <FeatherIcon icon="briefcase" size="14" />
            {details?.job_title}
          </Typography>
          <Grid container spacing={2}>
            <Grid xs={6}>
              <Typography
                mb="0.25em"
                fontSize={"1em"}
                className="text_modal_padding d-flex align-items-center gap-2 justify-content-start"
                component={Link}
                to={`../employer-single-list/${details?.company_user_id}`}
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="link" size="14" />
                {details?.company_name}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                mb="0.25em"
                fontSize={"1em"}
                className="text_modal_padding d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="clock" size="14" />
                {moment(details?.application_deadline).format("DD-MM-YYYY")}
              </Typography>
            </Grid>

            <Grid xs={6}>
              <Typography
                mb="0.25em"
                fontSize={"1em"}
                className="text_modal_padding d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="dollar-sign" size="14" />
                {details?.min_sal} {details?.currency} - {details?.max_sal}{" "}
                {details?.currency}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                mb="0.25em"
                fontSize={"1em"}
                className="text_modal_padding d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="type" size="14" />
                {details?.job_type?.includes("_") &&
                  details?.job_type?.replace("_", " ")}
              </Typography>
            </Grid>
            <Grid xs={6}>
              <Typography
                mb="0.25em"
                fontSize={"1em"}
                className="text_modal_padding d-flex align-items-center gap-2 justify-content-start"
                id="layout-modal-description"
                textColor="text.tertiary"
              >
                <FeatherIcon icon="globe" size="14" />
                {details?.work_mode?.replace("_", " ")}
              </Typography>
            </Grid>
          </Grid>
          <p className="text_small_p">Job Description</p>
          <div
            className="text-description mb-4 text_modal_padding"
            style={{ maxHeight: "20vh", overflowY: "scroll" }}
            dangerouslySetInnerHTML={{ __html: details?.job_desc }}
          ></div>

          <div className="d-flex justify-content-end gap-4">
            <CssVarsProvider>
              <Button
                variant="solid"
                onClick={async () => {
                  if (purpose === "self") {
                    const { status } = await referSelf();
                    if (status === 200) setStep(step + 1);
                    else {
                      navigate("/profile");
                      toast.error("Please Update Your Profile First");
                    }
                  } else {
                    setStep(step + 1);
                  }
                }}
              >
                Next
              </Button>
            </CssVarsProvider>
          </div>
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
