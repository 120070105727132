import React,{useEffect,useState} from "react";
import mobile1 from "../../assets/images/mobile1.png";
import zepulMobile1 from "../../assets/images/zepulMobile1.png";
import zepulMobileImage2 from "../../assets/images/zepulMobileImage2.png";
import zepulMobile3 from "../../assets/images/zepulMobile3.png";
import vibrationImage2 from "../../assets/images/vibrationImage2.png";
import openJob from '../../assets/images/openJob.png';
import applyJob from '../../assets/images/applyJob.png';
import earnJob from '../../assets/images/earnJob.png';
import openJob2 from '../../assets/images/openJob2.png';
import applyJob2 from '../../assets/images/applyJob2.png';


function ZepulJobsDesc() {
  /* Testing start*/
  useEffect(() => {
    const handleScroll = () => {
      const images = document.querySelectorAll(".image-transition");

      images.forEach((image, index) => {
        const imagePosition = image.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (imagePosition < windowHeight) {
          image.style.transitionDelay = `${index * 1}s`;
          image.classList.add("show");
        } else {
          image.classList.remove("show");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*Testing End */

  return (
    <>
      <div className="animation_section">
        <img src={openJob} className="image-transition zepul_mobile_img" />
        <img src={applyJob} className="image-transition zepul_mobile_img" />
        <img src={earnJob} className="image-transition zepul_mobile_img" />
      </div>

      <div className="animation_section2">
        <img src={openJob2} className="image-transition zepul_mobile_img" />
        <img src={applyJob2} className="image-transition zepul_mobile_img" />
        <img src={earnJob} className="image-transition zepul_mobile_img" />
      </div>


      {/* <div className="zepul_work_layout mt-5">
        <div className="search_jobs_txt">
          <img src={zepulMobile1} className="zepul_mobile_img"/>
          <div style={{paddingTop:"20px"}} className="zepul_trust_heading_cont">
          <h2 className="zepul_search_txt">
            Search wide <br />
            Variety Jobs
          </h2>
          <p className="zepul_search_para_txt">
            We are helping
            <br />
            individuals refering and<br/> graphic design
          </p>
          </div>
        </div>

        <div  className="search_jobs_txt">
          <img src={zepulMobileImage2} className="zepul_mobile_img"/>
          <div style={{paddingTop:"20px"}} className="zepul_trust_heading_cont">
          <h2 className="zepul_search_txt">
            Search wide <br />
            Variety Jobs
          </h2>
          <p className="zepul_search_para_txt">
            We are helping
            <br />
            individuals refering and<br/> graphic design
          </p>
          </div>
        </div>
        
        <div  className="search_jobs_txt">
          <img src={zepulMobile3} className="zepul_mobile_img"/>
          <div style={{paddingTop:"20px"}} className="zepul_trust_heading_cont">
          <h2 className="zepul_search_txt">
            Refer your <br />
            Friends
          </h2>
          <p className="zepul_search_para_txt">
            We are helping
            <br />
            individuals refering and<br/> graphic design
          </p>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default ZepulJobsDesc;
