import React from "react";
import helpingHands from "../../../../src/assets/images/infoImages/helping.jpg";
import refer from "../../../assets/images/refersection.png";
import CompanyListCarousal from "../../Home/CompanyListCarousal";
import CompanyTrustCarousal from "../../AboutUs/AboutsUsPages/CompanyTrustCarousal";
import CompanyIndustyrCardsCarousal from "./CompanyIndustryCardsCarousal";

function CompanyIndustries(props) {
  return (
    <>
      {/* <div className="hire_candidates_card_section_layout"> */}
      <div>
        <section className="refer_section_tab">
          <div className="refer_main_sec2">
            <div className="refer_partition_sec1">
              <div className="refer_sec1">
                {/* <CompanyTrustCarousal /> */}
                <CompanyIndustyrCardsCarousal
                  industryLists={props.industryList}
                  selectedIndustryById={id => props.selectedIndustry(id)}
                  industryChecked={props.industryCheck}
                  setShownFilterBtn = {props.setShownFilterBtn}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default CompanyIndustries;
