import React from "react";
import "../../../../src/assets/css/AboutUs/About.css";
import CompanyCarousal from '../../Home/CompanyCarousal';
function Categories() {
  return (
    <>
     <section className="companies_carousal_sec" style={{marginTop:"20px"}}>
          <div className="companies_car_main_sec">
            <div className="comp_inside_sec">
              <h2 className="comp_heading">
                1000+ <span className="comp_span_text">Enterprises</span> Choose
                us
              </h2>
              <div className="comp_carousal_section">
                <CompanyCarousal />
              </div>
            </div>
          </div>
        </section>
     
     
     
     
     
      {/* <section className="section-box ">
        <div className="container">
          <h2 className="section-title text-center mb-15 wow animate__animated animate__fadeInUp" style={{fontWeight:"bold"}}>
            What we do
          </h2>
          <div className="text-normal text-center color-black-5 box-mw-60 wow animate__animated animate__fadeInUp pb-3 texts">
            Find the type of work you need, clearly defined and ready to start.
            Work begins as soon as you purchase and provide requirements.
          </div>
          <div className="row">
            <div className="col-lg-11 mx-auto">
              <div className="row pt-5">
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-grid-4 hover-up">
                    <div className="image-top-feature">
                      <figure>
                        <img
                          alt="Zepul"
                          src="assets/imgs/page/about/market-research.svg"
                        />
                      </figure>
                    </div>
                    <div className="card-grid-4-info">
                      <h5 className="mt-20">Market Research</h5>
                      <p className="text-normal mt-15 mb-20">
                        It is a long established fact that a reader will be.
                      </p>
                      <span className="btn-readmore icon-arrow">Read more</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-grid-4 hover-up">
                    <div className="image-top-feature">
                      <figure>
                        <img
                          alt="Zepul"
                          src="assets/imgs/page/about/creative-layout.svg"
                        />
                      </figure>
                    </div>
                    <div className="card-grid-4-info">
                      <h5 className="mt-20">Creative Layout</h5>
                      <p className="text-normal mt-15 mb-20">
                        It is a long established fact that a reader will be.
                      </p>
                      <span className="btn-readmore icon-arrow">Read more</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-md-30">
                  <div className="card-grid-4 hover-up">
                    <div className="image-top-feature">
                      <figure>
                        <img
                          alt="Zepul"
                          src="assets/imgs/page/about/digital-marketing.svg"
                        />
                      </figure>
                    </div>
                    <div className="card-grid-4-info">
                      <h5 className="mt-20">Digital Marketing</h5>
                      <p className="text-normal mt-15 mb-20">
                        It is a long established fact that a reader will be.
                      </p>
                      <span className="btn-readmore icon-arrow">Read more</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                  <div className="card-grid-4 hover-up">
                    <div className="image-top-feature">
                      <figure>
                        <img
                          alt="Zepul"
                          src="assets/imgs/page/about/seo-backlink.svg"
                        />
                      </figure>
                    </div>
                    <div className="card-grid-4-info">
                      <h5 className="mt-20">SEO & Backlinks</h5>
                      <p className="text-normal mt-15 mb-20">
                        It is a long established fact that a reader will be.
                      </p>
                      <span className="btn-readmore icon-arrow">Read more</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

     
    </>
  );
}
export default Categories;
