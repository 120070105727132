import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useSelector } from "react-redux";
import Sidebar from "../Accounts/Sidebar/Sidebar";
import DemoChatSidebar from "./DemoChatSidebar";
import DemoChatMain from "./DemoChatMain";
import JobsListChat from "./JobsListChat";
import chatImg from "../../assets/images/chat.svg";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import "./DemoChat.css";
import {
  findChatBasedOnId,
  findContactsBasedOnUserId,
  getAllChats,
  getAllChatsByChannel,
  sendMessage,
  sendUserMessages,
} from "../../helpers/dataFetcher/chats/chats";
import { getAllAppliedJobsList } from "../../helpers/dataFetcher/chats/chats";
import JobDescription from "../BrowseJobsList/JobDescription";
import JobDrawChatDescription from "../BrowseJobsList/JobDrawChatDescription";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { useNavigate } from "react-router-dom";
import SocketConnection from "../../hooks/socket";
import { get_chat_active_user_list,deleteChatActiveUser } from "../../helpers/sockets";
import DemoChatSidebarDummy from "./DemoChatSidebar2";
import DemoChatMainDummy from "./DemoChatMainDummy";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ZepulLoader from "../../Loader/ZepulLoader";
// import messageSent from '../../assets/alerts/messageSent.mp3'
// import messageSent from '../../assets/alerts/tick1.mp3'
import messageSent from '../../assets/alerts/tick2.mp3'

function ChatChannelIndex() {
  const [openKeys, setOpenKeys] = useState(["unread", "allmessage"]);
  const [chat, setChat] = useState([]);
  const rootSubmenuKeys = ["unread"];
  const [selectedChat, setSelectedChat] = useState([]);
  const [senderId, setSenderId] = useState("");
  const [receiverId, setReceiverId] = useState({ id: "" });
  const [text, setText] = useState("");
  const [attachment, setAttachment] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [postImg, setPostImg] = useState("");
  const [contacts, setContacts] = useState([]);
  const [modelOpen, setModelOpen] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [jobList, setJobList] = useState(true);
  const [chatMain, setChatMain] = useState(true);
  const [userJobDetails, setUserJobDetails] = useState({});
  const [job, setJob] = useState();
  const [jobId, setJobId] = useState();
  const [hideJobSec, setHideJobSec] = useState(false);
  const [jobDesc, setJobDesc] = useState(false);
  const [jobPostId, setJobPostId] = useState({ id: "" });
  const [userJobPostId, setUserJobPostId] = useState({ id: "" });
  const [channelId, setChannelId] = useState({ id: "" });
  const [selectedChannelData, setSelectedChannelData] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedChannelDetails, setSelectedChannelDetails] = useState();
  const [routeNavigate, setRouteNavigate] = useState(false);
 const [userStatus,setUserStatus] = useState([])
  const navigate = useNavigate();
  const [loading,setLoading] = useState(true)
  const [channelsPage,setChannelsPage] = useState(false)

  const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();

  /*Required state @seshu */
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [seshuReceiverId, setSeshuReceiverId] = useState();
  const [activeSocketUsersList, setActiveSocketUsersList] = useState();

  /*Required state End@seshu */

  const searchParams = new URLSearchParams(window.location.search)

const id1 = searchParams.get("id1")
const id2 = searchParams.get("id2")

  const userData = useSelector(state => state.User);

  useEffect(() => {
    setUserId(userData.user.id);
  }, [userData]);

  const toggleModel = () => {
    setModelOpen(!modelOpen);
  };

  const handleImageUpload = async data => {
    const url = await getPreSignedURL(data);
    setPostImg(url);
    toggleModel();
  };
 


  
  /* Demochat Integration start @seshu */
  useEffect(()=>{
    getjobById(id2);
    
  },[])

  useEffect(()=>{
    getjobById(id2);
  },[id2])

  // useEffect(() => {
  //   getAllJobs();
  // }, []);

  useEffect(()=>{
    // getActiveUsersDataSocket()
  },[connectionStatus])

 

  async function getAllJobs() {
    const getAllJobsList = await getAllAppliedJobsList();
    setAppliedJobs(getAllJobsList?.data?.data);
    setJobId(getAllJobsList?.data?.data[0]?.job_post_id);
    setJobDesc(false);
  }

  /* Demochat Integration end @seshu */

  /* Get Job Description By Id code start Here@ seshu */
  useEffect(() => {
    getjobById(jobId);
  }, [jobId]);
  const getjobById = async id => {
    // setSelectedButton(data.job_id === selectedButton ? null : data.job_id);
    const jobDataList = await getJobPostById(id);
    setJob(jobDataList?.data);
    setJobDesc(true);
  };
  /* Get Job Description By Id code end Here@ seshu */

  /* Get chats based on ID Code start here @seshu */

  const getChatById = async receiverId => {
    const result = await findChatBasedOnId();
  };

  /* Get chats based on ID Code End here @seshu */

  /* Get All chats Code start Here */
  useEffect(() => {
    if(channelId.id){
        getAllChatsData(channelId.id);
        getActiveUsersDataSocket();
    }
   
  }, [channelId.id]);
  

  const getAllChatsData = async id => {
    const allChatsData = await getAllChatsByChannel(id);
    const sortedData = allChatsData?.data?.data.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.updatedAt);
    });
    setSelectedChannelData(sortedData);
    setLoading(false)
  };

 
  const getActiveUsersDataSocket = async () => {
    const activeUsersList = await get_chat_active_user_list();
    const activeUserId = activeUsersList?.data?.filter(each => {
        if (each.userId == receiverId.id) {
          return each.userId;
        }
      });
/*testing */
    //  if(activeUserId){
    //   sendSocketMessage(
    //     JSON.stringify({
    //       type: "To_recipient",
    //       data: {
    //         data: "new connection",
    //       },
    //       reciever_connectionId: activeUserId[0]?.connectionId,
    //     })
    //   );
    //  }
/*testing */

      setUserStatus(activeUserId)
    setActiveSocketUsersList(activeUsersList?.data);
  };

  /*Get All chats code End here */

  /* Get incoming messages based on socket last message code start */
  useEffect(() => {
    if(lastMessage){
        getIncomingMessages();  
    }
  }, [lastMessage]);
  

  const getIncomingMessages = async () => {
    // if (lastMessage !== null || lastMessage !== "" || lastMessage !== "") {
    //   const incomingMsgsList = await getAllChatsByChannel(channelId.id);
    //   const sortedData = incomingMsgsList?.data?.data.sort((a, b) => {
    //     return new Date(a.createdAt) - new Date(b.updatedAt);
    //   });
    //   setSelectedChannelData(sortedData);
    // }
    if(lastMessage){
      const incomingMsgsList = await getAllChatsByChannel(channelId.id);
      const sortedData = incomingMsgsList?.data?.data.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setSelectedChannelData(sortedData);
    }
  };

  /* Get incoming messages based on socket last message code End */

  /* Message send alert sound starts here @seshu */
  const playMessageSentSound = () => {
    const audio = new Audio(messageSent);
    audio.play();
  };
  /*Message send alert sound ends here @seshu */

  /*Send Messages code start here @seshu */

  const sendUserMessage = async (attachment) => {
    const activeUsersList = await get_chat_active_user_list();
    const activeUserId = activeUsersList?.data?.filter(each => {
        if (each.userId == receiverId.id) {
          return each.userId;
        }
      });

    /* Iteration active users start */
    // const activeUserId = activeSocketUsersList?.filter(each => {
    //   if (each.userId == receiverId.id) {
    //     return each.userId;
    //   }
    // });
   
    /* Iteration active users End */

  let type 
if(attachment?.length >0){
  const extension = attachment.split(".")[1]
  console.log(extension,"extension")
  const isExtension = /\.(jpe?g|png|jpeg|gif|)$/i.test(
    attachment
  );
  const fileExtension = /\.(pdf|docx)$/i.test(
    attachment
  );
  if(isExtension){
    type = "image"
  }else {
    type = extension
  }
  
}else {
  const isExtension = /\.(jpe?g|png|jpeg|gif|pdf|docx|json)$/i.test(
    text
  );
  type="text"
}
    const data = {
      user_job_post_id: userJobPostId.id,
      receiver_id: receiverId.id,
      job_post_id: jobPostId.id,
      message: text ? text : attachment,
      message_type:text?"text":attachment?type:"text"
    };
    if (data.message !== "") {
      const {status} = await sendUserMessages(data);
      playMessageSentSound()
      if (activeUserId && status) {
      if(status === 200){
        sendSocketMessage(
            JSON.stringify({
              type: "To_recipient",
              data: {
                data: text,
              },
              reciever_connectionId: activeUserId[0]?.connectionId,
            })
          );
      }
    }

    }

   
   

    setText("");
    setAttachment("");
    const retriveMessagesData = await getAllChatsByChannel(channelId.id);
    const sortedData = retriveMessagesData?.data?.data.sort((a, b) => {
      return new Date(a.createdAt) - new Date(b.updatedAt);
    });
    setSelectedChannelData(sortedData);
    setChannelsPage(true)
  };
  /*Send Messages code End here @seshu */

  /* Navigation route implementation code start here @seshu */

  /* Navigation route implementation code End here @seshu */

  return (
    <>
       <Container
        fluid
        className="chat_main_cont"
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          marginTop: "30px",
          marginLeft: "51px",
        }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-0 col-lg-2">
            <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky zepulScroll">
              <Sidebar />
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-10"
            style={{ paddingLeft: "28px", height: "80vh",maxHeight:"", overflowY: "scroll" }}
          >
            
              <div className="demochat_main_cont">
                <div className="col-sm-12 col-md-6 col-lg-4 jobsPageWidth">
                  <DemoChatSidebarDummy
                    setReceiverId={setReceiverId}
                    setSelectedChat={setSelectedChat}
                    setSenderId={setSenderId}
                    chat={chat}
                    setChat={setChat}
                    receiverId={receiverId}
                    contacts={contacts}
                    setJobList={setJobList}
                    setSeshuReceiverId={setSeshuReceiverId}
                    userJobDetails={userJobDetails}
                    setHideJobSec={setHideJobSec}
                    jobDetails={job}
                    setJobPostId={setJobPostId}
                    userJobPostId={userJobPostId}
                    setChannelId={setChannelId}
                    setUserJobPostId={setUserJobPostId}
                    setSelectedChannelDetails={setSelectedChannelDetails}
                    channelsPage={channelsPage}
                    setChannelsPage= {setChannelsPage}
                    playMessageSentSound={playMessageSentSound}
                    getAllChatsData={getAllChatsData}
                  />
            
                </div>

                <div className="col-sm-12 col-md-6 col-lg-8">
                  {selectedChannelDetails ? (
                    <DemoChatMainDummy
                      setChat={setChat}
                      receiverId={receiverId}
                      senderId={senderId}
                      setShowPicker={setShowPicker}
                      showPicker={showPicker}
                      setText={setText}
                      selectedChat={selectedChat}
                      setSelectedChat={setSelectedChat}
                      text={text}
                      setJobPostId={setJobPostId}
                      userJobPostId={userJobPostId}
                      selectedChannelData={selectedChannelData}
                      userId={userId}
                      sendUserMessage={sendUserMessage}
                      setAttachment={setAttachment}
                      selectedChannelDetails={selectedChannelDetails}
                      activeSocketUsersList={activeSocketUsersList}
                      userStatus={userStatus}
                     
                    />
              
                  ): <div className="">
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                      <div className="contact_no_show">
                        <img src={chatImg} alt="chat" />
                        <p>You haven't selected any chat yet..</p>
                        {/* <span className="pat-20 end_to_end">
                          End to End Encrypted
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>}
                </div>
              </div>
            
          </div>

        </div>
      </Container> 
    </>
  );
}

export default ChatChannelIndex;
