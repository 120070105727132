import React, { useEffect } from "react";
import "../../assets/JobList/MainSerachFilter.css";
// import AddBoxIcon from "@mui/icons-material/AddBox";
import { useState } from "react";
import "antd/dist/antd.min.css";
import { useSearchParams } from "react-router-dom";
import { PlacesAutocomplete } from "../../data/locationSearch";
import { Form } from "reactstrap";
import Input from "@mui/joy/Input";
import { Search } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";

function MainSearchFilterBar({ setJobsList, setLoad, limit, offset }) {
  const [searchLocation, setSearchLocation] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setValue("job_name", searchParams.get("title"));
  }, [searchParams.get("title")]);
  const onSubmit = async data => {
    await setSearchParams({
      title: data.job_name,
      location: searchLocation,
    });
  };

  return (
    <>
      <section className="section-box-2">
        <div className="none-bg">
          <div className="comp_filter_search_cont">
            <div className="box-shadow-bdrd-15 box-filters">
              <Form className="row" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-lg-5 col-sm-12 col-md-5 ">
                  <Input
                    startDecorator={
                      <Search
                        // color={`${errors.job_name ? "danger" : "#081753 !important"}`}
                        style={{color:`${errors.job_name ? "danger" : "#081753"}`}}
                      />
                    }
                    type="text"
                    className="input-search-job mb-1"
                    error={errors.job_name ? true : false}
                    {...register("job_name", { required: false })}
                    placeholder="Job title, Keyskills, or Company name..."
                  />
                </div>
                <div className="col-lg-5 col-sm-12 col-md-5">
                  <div className="box-search-job search-job-cont">
                    <div className="form-search-job search-job-cont">
                      <PlacesAutocomplete
                        className="input-search-location"
                        location={searchLocation}
                        setLocation={e => {
                          setSearchLocation(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=" col-lg-2 col-sm-12 col-md-2 find_jobs_btn">
                  <CssVarsProvider>
                    <Button
                      type="submit"
                      style={{ height: "50px",color:"#ffffff",background:"#081753",fontFamily:"Inter,sans-serif" }}
                      // className="btn btn-default float-right job_find_btn"
                    >
                      Find Jobs
                    </Button>
                  </CssVarsProvider>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default MainSearchFilterBar;

// <form
//   className="form-search-job search-job-cont"
//   onSubmit={async e => {
//     e.preventDefault();
//     await setSearchParams({
//       title: searchInput,
//       location: searchLocation,
//     });
//   }}
// >
//   <input
//     type="search"
//     className="input-search-job mb-1"
//     value={searchInput}
//     placeholder="Job title, Keyskills, or Company name..."
//     onChange={e => setSearchInput(e.target.value)}
//   />
//   <div className="col-lg-5 col-sm-12 col-md-5">
//     <div className="box-search-job search-job-cont">
//       <div className="form-search-job search-job-cont">
//         <PlacesAutocomplete
//           className="input-search-location"
//           location={searchLocation}
//           setLocation={e => {
//             setSearchLocation(e);
//           }}
//         />
//       </div>
//     </div>
//   </div>
//   <div className="box-button-find col-lg-2 col-sm-12 col-md-2">
//     <button type="submit" className="btn btn-default float-right job_find_btn">
//       Find Jobs
//     </button>
//   </div>
// </form>;
