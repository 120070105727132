import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
} from "@material-ui/core";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import "../../assets/css/chat/chat.css";
import startChat from "../../assets/images/start_chat.svg";
import { formatDistance } from "date-fns";
import { findChatBasedOnId } from "../../helpers/dataFetcher/chats/chats";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { imageURL } from "../../Constants/commonURLS";
import { blue } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { StyledInputBase } from "../../assets/MUI/globalStyles";
import { SearchContainer } from "../../assets/MUI/globalStyles";
import { SearchIconWrapper } from "../../assets/MUI/globalStyles";
import MailIcon from "@mui/icons-material/Mail";
import SocketConnection from "../../hooks/socket";

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "rgba(192, 223, 250, 0.4) !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "rgba(192, 223, 250, 0.4) !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
 
  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
    letterSpacing: "-0.005em",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  listBtn: {
    padding:"9px !important"
  },
});

const shapeStyles = {
  background: "rgba(39, 174, 96, 0.31)",
  width: 25,
  height: 25,
  marginLeft: "5px",
};
const shapeCircleStyles = { borderRadius: "50%" };
const rectangle = <Box component="span" sx={shapeStyles} />;
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);

function JobsListChat({
  setReceiverId,
  receiverId,
  setSelectedChat,
  setSenderId,
  chat,
  setJobList,
  setUserJobDetails,
  appliedJobs,
  setAppliedJobs,
  setJobId,
  setHideJobSec,
  jobId,
  setRouteNavigate,
}) {
  const classes = useStyles();
  const [activeUsers, setActiveUsers] = useState([]);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const [searchAppliedJobs, setSearchAppliedJobs] = useState(appliedJobs);
  const [selectedButton, setSelectedButton] = useState(null);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const inputWidth = isFocused ? "70%" : "45%";

  async function getActiveUsers() {
    const data = await get_chat_active_user_list();
    setActiveUsers(data?.data);
  }

  useEffect(() => {
    setSelectedButton(jobId);
   
  }, []);

  useEffect(() => {
    setSearchAppliedJobs(appliedJobs);
  }, [appliedJobs]);
  /* Jobs list display start Here@seshu */

  const handleSendJobId = async data => {
    // navigate(`../jobchat/${data.user_job_post_id}/${data.job_post_id}`);
    navigate(`/jobchat?id1=${data.user_job_post_id}&id2=${data.job_post_id}`);
    // setRouteNavigate(true);
    // setUserJobDetails(data);
    // setJobList(false);
    // setHideJobSec(true);
  };
  /* Jobs list display End Here@seshu */

  /* Search By Job Chat list code start here @seshu */
  const handleSearchTextChange = event => {
    const value = event.target.value;
    if (value.trim() !== "") {
      const filteredRows = appliedJobs?.filter(each => {
        const companyName =
          each?.job_post_in_user_job_post?.company?.company_name?.toLowerCase() ||
          "";
        const jobTitle =
          each?.job_post_in_user_job_post?.job_title?.toLowerCase() || "";

        return (
          companyName.includes(value.toLowerCase()) ||
          jobTitle.includes(value.toLowerCase())
        );
      });

      setSearchAppliedJobs(filteredRows);
    } else {
      setSearchAppliedJobs(appliedJobs);
    }
  };

  /* Search By Job Chat list code End here @seshu */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className="demochat_side_main">
        <div className="demochat_side_inside_cont">
          <div className="chat_job_search_cont pt-0">
          </div>

          <div
            className="chats_name_count pt-0 pb-3"
            style={{ background: "" }}
          >
            <div style={{gap:'16px',alignItems:'center'}} className="d-flex flex-row pt-4 pl-3">
              <h2 className="chats_name1">Chats</h2>
              <Badge
                  badgeContent={searchAppliedJobs ? searchAppliedJobs.length : "0"}
                  slotProps={{
                    badge: {
                      style: {
                        backgroundColor: "#64db97f2",
                        width: "20px",
                        height: "20px",
                        color: "#081753",
                        fontSize: "10px",
                        fontWeight: 500,
                      },
                    },
                  }}
                />
            </div>

            <ThemeProvider theme={globalTheme}>
              <TextField
                placeholder="Search..."
                // style={{ width: "70%" ,marginTop:"15px"}}
                style={{ width: inputWidth, marginTop: "15px" }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleSearchTextChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </ThemeProvider>
          </div>
          <Divider
            orientation="horizontal"
            sx={{
              height: "auto !important",
              borderWidth: "0.1px",
              width: "100%",
            }}
          />

          {/* <div className="company_chat_list">
            <a href="#" class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <Avatar
                  alt="D"
                  src="/static/images/avatar/2.jpg"
                  className={classes.avatar}
                />
                <span class="active"></span>
              </div>
              <div class="flex-grow-1 ms-3">
                <h3 className="chat_list_txt">
                  Dinesh{" "}
                  <span>
                    {" "}
                    <Chip label="Account Manager" className={classes.chip} />
                  </span>
                </h3>
                <p className="last_msg">Good, How about you?</p>
              </div>
            </a>
          </div> */}

          {searchAppliedJobs?.length > 0 ? (
            <div className="list_scroll">
              {searchAppliedJobs?.map(each => (
                <List
                  sx={{ width: "100%", bgcolor: "background.paper" }}
                  key={each.id}
                >
                  <ListItemButton
                    alignItems="flex-start"
                    onClick={(e) => {
                      e.preventDefault();
                      setJobId(each.job_post_id),
                        setSelectedButton(
                          each.job_post_id === selectedButton
                            ? null
                            : each.job_post_id
                        );
                        $(".jobbox").addClass("showbox");
                        if (windowWidth <= 992) {
                          $(".jobbox").removeClass("hideJobBox");
                        }
                    }}
                    style={{
                      backgroundColor:
                        selectedButton === each.job_post_id
                          ? "#EFF7FF"
                          : "#FFFFFF",
                      width: "100%",
                      padding: "10px !important",
                    }}
                    className={classes.listBtn}
                  >
                    <ListItemAvatar>
                      <Avatar
                        loading="lazy"
                        alt={`${each?.job_post_in_user_job_post?.company?.company_name.charAt(
                          2
                        )}`}
                        src={`${imageURL}${each?.job_post_in_user_job_post?.company?.company_image}`}
                        className={classes.avatar1}
                        sx={{background:"rgba(192, 223, 250, 0.4) !important",color:"rgba(0, 0, 0, 0.7) !important"}}
                      />
                    </ListItemAvatar>
                    <div className="names_link_cont">
                      <div className="d-flex flex-column">
                        <Typography className={classes.name}>
                          {each?.job_post_in_user_job_post?.job_title}
                        </Typography>
                        <Typography className={classes.msg}>
                          {
                            each?.job_post_in_user_job_post?.company
                              ?.company_name
                          }
                        </Typography>
                      </div>
                      {/* <div className="d-flex flex-column align-items-end">
                        <div className="badge_cont">5</div>
                      </div> */}

                      <IconButton
                        onClick={() =>
                          handleSendJobId({
                            user_job_post_id: each.id,
                            job_post_id: each.job_post_id,
                          })
                        }
                      >
                        <Badge
                          badgeContent={`${
                            each?.chatChannelsCount
                              ? each.chatChannelsCount
                              : "0"
                          }`}
                          color="success"
                        >
                          <ChatBubbleOutlineIcon
                            style={{ height: "20px", width: "20px" }}
                          />
                        </Badge>
                      </IconButton>
                    </div>
                  </ListItemButton>

                  <Divider
                    variant="inset"
                    component="li"
                    sx={{ marginLeft: "68px" }}
                  />
                </List>
              ))}
            </div>
          ) : (
            <div className="contact_no_show">
              <img src={startChat} />
              <p>
                You haven't started a chat please head over to contacts and
                start chat.
                {searchAppliedJobs.length == 0 ? "No search found" : "seshu"}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default JobsListChat;
