import React, { useEffect, useState } from "react";
import "./profile.css";
import ProfileModal from "./ProfileModals/ProfileModals";
import UploadModel from "../../../components/common/uploadModel";
import { getPreSignedURL } from "../../../helpers/dataFetcher/pre_signed_urls/upload";
import EditIcon from "@mui/icons-material/Edit";
import { getProfile } from "../../../helpers/dataFetcher/profile/profile";
import FileViewer from "react-file-viewer";
import {
  updateDeleteProfile,
  updateProfileNormal,
  updateSkill,
} from "../../../helpers/dataFetcher/profile/update";
import FeatherIcon from "feather-icons-react";
import { imageURL } from "../../../Constants/commonURLS";
import { format } from "date-fns";
import { Col, Row } from "reactstrap";
import { convertintoStringDates } from "../../../functions/date";
import { deepPurple } from "@mui/material/colors";
import { Avatar, CircularProgress, IconButton, Skeleton } from "@mui/material";
import { toast } from "react-hot-toast";
import Divider from "@mui/material/Divider";
import { Button, CssVarsProvider } from "@mui/joy";
import Close from "@mui/icons-material/Close";
import Chip from "@mui/joy/Chip";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Tooltip from "@mui/material/Tooltip";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import Stack from "@mui/material/Stack";
import UploadFileModal from "../../../Reusable/FileUpload";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { resumeParser } from "../../../helpers/dataFetcher/resume/resume_parser";
import { updateKeySkills } from "../../../helpers/dataFetcher/jobAttributes/keyskills";
import { useSelector } from "react-redux";

// const getFileExtension = (url) => {
//   const parts = url.split(".");
//   return parts[parts.length - 1].toLowerCase();
// };

// const getFileType = (extension) => {
//   if (extension === "pdf") {
//     return "pdf";
//   } else if (extension === "doc" || extension === "docx") {
//     return "docx";
//   } else if (extension === "xls" || extension === "xlsx") {
//     return "xlsx";
//   } else if (extension === "csv") {
//     return "csv";
//   }
//   // Add more file type checks as needed

//   // Default to "file" type if the extension is not recognized
//   return "file";
// };

function Profile(props) {
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState(false);
  const [resumeLoad, setResumeLoad] = useState(false);
  const [preview, setPreview] = useState(false);
  const [resumePre, setResumePre] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [profileModal, setProfileModal] = useState({
    modal: false,
    type: "",
    action: "",
    modalType: "",
    details: {},
    file: "",
  });

  const [model, setModel] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const userData = useSelector(state => state.User);
  // const fileLink = userDetails ? `${imageURL}${userDetails.resume_url}` : "";
  // const fileExtension = getFileExtension(fileLink);
  // const fileType = getFileType(fileExtension);

  // const filePath = userDetails ? `${imageURL}${userDetails.resume_url}` : "";
  // const fileExtension = filePath.split('.').pop().toLowerCase();

  const supportedFileTypes = ["pdf", "docx", "xlsx", "csv"];
  const filePath = userDetails ? `${imageURL}${userDetails.resume_url}` : "";

  let fileType = supportedFileTypes.find(type => {
    const fileExtension = filePath.split(".").pop().toLowerCase();
    return supportedFileTypes.includes(fileExtension);
  });

  // If no supported file type is found, set a default fileType
  if (!fileType) {
    fileType = "docx";
  }

  useEffect(() => {
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { data, status } = await getProfile();
    setUserDetails(data);
    if (status === 200) {
      setStatus(true);
    }
  }

  async function handleImage(data) {
    const url = await getPreSignedURL(data);
  }

  const visaStatus = [
    { status: "Administrative processing", exp_date: "11 Sep 2022" },
  ];

  async function removeExperience(id) {
    const newArray = userDetails.work_exp_details.filter(
      item => item.is_active === true
    );

    const filterExperience = newArray.filter(each => {
      if (each.id !== id) {
        return { ...each };
      }
    });

    const updatedOne = filterExperience.map(item => {
      return {
        title: item.title,
        company: item.company,
        description: item.description,
        location: item.location,
        notice_period:
          item?.notice_period === null ? 0 : Number(item.notice_period),
        is_currently_working: item.is_currently_working,
        start_date: item.start_date,
        end_date:
          item.end_date === ""
            ? new Date()
            : // ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
              item.end_date,
      };
    });

    const { dataa, status } = await updateDeleteProfile("work_experience", {
      update_type_to_send_email: "email",
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      work_exp: updatedOne,
    });

    dataFetcher();
  }

  // async function removeSkill(id) {

  //   console.log(userDetails.skill_details)
  //   const fliterArray = userDetails.skill_details.map((item)=>{
  //     if(item.id === id){
  //       return {
  //         ...item,is_active:false
  //       }
  //     }else {
  //       return {
  //         ...item,is_active:true
  //       }
  //     }
  //   })
  //   const finalArray = fliterArray.filter((each)=>each.is_active === true)
  //   console.log("aa",fliterArray)
  //   const result = finalArray.map(item => item.id)
  //   console.log(result)

  //   const { dataa, status } = await updateProfileNormal("skills", {
  //     email: userDetails.email,
  //     first_name: userDetails.first_name,
  //     last_name: userDetails.last_name,
  //     skills: result,
  //   });

  //   dataFetcher();
  // }

  {
    /*async function removeLanguages(name) {
    const filterLanguages = userDetails?.languages?.filter(item => item !== name) 
    const { status } = await updateProfileNormal("languages", {
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      languages: filterLanguages,
    });
    dataFetcher();
  }*/
  }

  async function removeEducation(id) {
    const newArray = userDetails.education_training_details.filter(
      item => item.is_active === true
    );
    const filterExperience = newArray.filter(each => {
      if (each.id !== id) {
        return { ...each };
      }
    });

    const updatedOne = filterExperience?.map(item => {
      return {
        institute_name: item.institute_name,
        certificate_name: item.certificate_name,
        start_date: item.start_date,
        /*end_date:
          item.end_date === ""
            ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
        : item.end_date,*/
        end_date: item.end_date,
        description: item.description,
      };
    });

    const { dataa, status } = await updateProfileNormal("education_training", {
      update_type_to_send_email: "email",
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      education_training: updatedOne,
    });

    dataFetcher();
  }

  // const convertToProperName = (name) => {
  //       if (!name) {
  //         return ''; // Return empty string if the name is empty or undefined
  //       }

  //

  //       const words = name.split(' '); // Split the name into an array of words
  //       const properName = words
  //         .map((word) => {
  //           const lowerCaseWord = word.toLowerCase(); // Convert the word to lowercase
  //           const capitalizedWord =
  //             lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1); // Capitalize the first character of the word
  //           return capitalizedWord;
  //         })
  //         .join(' '); // Join the words back into a single string

  //

  //       return properName;
  //     };
  //

  //  const properName = convertToProperName("seshu syoft");
  //  console.log(properName,"properName")

  return (
    <>
      {status ? (
        <>
          <UploadModel
            show={model}
            onHide={() => {
              setModel(false);
            }}
            handleImage={handleImage}
          />
          <div className="profile_main_cont_shrink zepulScroll">
            <div className="profile_card profile_card_2">
              <div className="d-flex justify-content-between">
                <div className="d-flex gap-2">
                  <div>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <Avatar
                        alt="avatar"
                        src={`${imageURL}${userDetails?.profile_image}`}
                        // sx={{ width: 38, height: 38, bgcolor: deepPurple[500] }}
                        sx={{ width: 80, height: 80, background: "#ECECEC" }}
                        onClick={() =>
                          setProfileModal({
                            modal: "center",
                            type: "imageUpload",
                          })
                        }
                      >
                        <span className="profile_letter_txt">
                          {userDetails.first_name?.charAt(0) +
                            "" +
                            userDetails.last_name?.charAt(0)}
                        </span>
                      </Avatar>
                    </IconButton>
                  </div>
                  <div className="pt-2 user_socila_links">
                    <p className="user_title_name">
                      {userDetails.first_name} {userDetails.last_name}
                    </p>

                    <div className="user_designation">
                      {/* <p>{userDetails.email}</p> */}
                      <p>{userDetails?.job_role ? userDetails.job_role : ""}</p>
                    </div>
                    {/* <Stack direction="row" spacing={1}>
                      <FaTwitter size={20} color="#1DA1F2" />
                      <FaFacebook size={20} color="#3b5998" />
                      <FaLinkedin color="#0077B5" size={20} />
                    </Stack> */}
                  </div>
                </div>
                {/* <CssVarsProvider>
                  <Button
                    size="sm"
                    variant="outlined"
                    onClick={() =>
                      setProfileModal({ modal: "center", type: "profile" })
                    }
                  >
                    <EditIcon fontSize="sm" />{" "}
                    <span className="p-1">Edit Profile</span>
                  </Button>
                </CssVarsProvider> */}
              </div>
            </div>

            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Carrer Profile</h2>
                <i
                  className="bx bxs-pencil"
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                  onClick={() =>
                    setProfileModal({
                      modal: "center",
                      type: "profile",
                    })
                  }
                ></i>
              </div>
              <div className="about_card">
                {/* <p className="text_headling_p">About Me</p> */}
                {/* <Row className="user-desc">
                <Col xs={6} className="about_me_details">
                  <p>Mobile Number</p>
                  <p>{userDetails?.phone_number}</p>
                </Col>
                <Col xs={6} className="about_me_details">
                  <p>Email</p>
                  <p>{userDetails?.email}</p>
                </Col>
                <Col xs={6} className="about_me_details">
                  <p>Location</p>
                  <p>{userDetails?.location}</p>
                </Col>
                <Col xs={6} className="about_me_details">
                  <p>Postal Code</p>
                  <p>{userDetails?.postal_code}</p>
                </Col>
                <Col xs={6} className="about_me_details">
                  <p>Expected CTC</p>
                  <p>5 Lakhs - 10 Lakhs</p>
                </Col>
                <Col xs={6} className="about_me_details">
                  <p>Date of Birth</p>
                  <p>
                    {convertintoStringDates({ createdAt: userDetails?.dob })}
                  </p>
                </Col>
              </Row> */}

                <Row
                  className="user-desc"
                  md="4"
                  sm="2"
                  xs="1"
                  sx={{ padding: "40px" }}
                >
                  <Col xs={12} className="about_me_details">
                    <p>Mobile Number</p>
                    <p>{userDetails?.phone_number}</p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Email</p>
                    <p>{userDetails?.email}</p>
                  </Col>
                  {/* <Col xs={6} className="about_me_details">
                    <p>Languages</p>
                    <p>
                      {userDetails?.languages ? userDetails.languages : "--"}
                    </p>
                  </Col> */}
                  <Col xs={12} className="about_me_details">
                    <p>Desired Employment Type</p>
                    <p>
                      {userDetails?.work_mode
                        ? userDetails.work_mode.replace("_", "")
                        : "--"}
                    </p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Location</p>
                    <p style={{ color: "#008000" }}>
                      {userDetails?.location
                        ? userDetails.location
                        : "Add Location"}
                    </p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Date Of Birth</p>
                    <p>
                      {userDetails?.dob ? userDetails.dob.slice(0, 10) : "--"}
                    </p>
                  </Col>

                  {/* <Col xs={6} className="about_me_detailss">
                    <p
                      style={{
                        fontWeight: 400,
                        color: " rgba(0, 0, 0, 0.75)",
                        fontSize: 13,
                      }}
                    >
                      Job Role
                    </p>
                   
                    {userDetails?.job_roles_details
                      ? userDetails.job_roles_details.map(item => (
                          <p key={item.id}>{item}</p>
                        ))
                      : "--"}
                  </Col> */}

                  <Col xs={12} className="about_me_details">
                    <p>Postal Code</p>
                    <p>{userDetails?.postal_code}</p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Current CTC</p>
                    <p>
                      {userDetails?.current_ctc
                        ? userDetails.current_ctc
                        : "--"}
                    </p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Expected CTC</p>
                    <p>
                      {userDetails?.expected_ctc
                        ? userDetails.expected_ctc
                        : "--"}
                    </p>
                  </Col>
                  <Col xs={12} className="about_me_details">
                    <p>Notice Period</p>
                    <p>
                      {userDetails?.notice_period
                        ? userDetails.notice_period
                        : "--"}
                    </p>
                  </Col>
                  {/* <Col xs={6} className="about_me_details">
                    <p>Date of Birth</p>
                    <p>
                      {convertintoStringDates({ createdAt: userDetails?.dob })}
                    </p>
                  </Col> */}
                </Row>
              </div>
            </div>

            <div>
              <div className="profile_category_desc">
                {/* <Divider orientation="vertical" className="profile_divider" /> */}
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Profile Summary</h2>
                <i
                  className="bx bxs-pencil"
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                  onClick={() =>
                    setProfileModal({
                      modal: "center",
                      type: "profileDescription",
                    })
                  }
                ></i>
              </div>
              <div className="about_card">
                {/* <p className="text_headling_p">Profile Summary</p> */}
                <p className="user-desc">{userDetails?.about}</p>
              </div>
            </div>

            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Resume</h2>
                {/* <i
                  className="bx bxs-plus-circle"
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "profile" })
                  }
                ></i> */}
              </div>

              {/* <div className="about_card">
              
                <Row className="user-desc">
                  <Col xs={6}>
                    <p
                      style={{ fontSize: "10px" }}
                    >{`${imageURL}${userDetails?.resume_url}`}</p>
                  </Col>

                  <Col xs={2}>
                    <Button
                      type="button"
                      variant="outlined"
                      className="user-desc"
                      style={{
                        background: "#081753",
                        borderRadius: "8px",
                        height: "48px",
                        color: "#ffffff",
                      }}
                      onClick={async () => {
                        const input_field = document.createElement("input");
                        input_field.type = "file";
                        input_field.accept = "application/*";
                        input_field.click();
                        input_field.onchange = async e => {
                          setResumeLoad(true);
                          const file = e.target.files;
                          const data = await getPreSignedURL(file);

                          const { status } = await updateProfileNormal(
                            "resume",
                            {
                              resume_file: data.replace(imageURL, ""),
                              first_name: userDetails.first_name,
                              last_name: userDetails.last_name,
                              email: userDetails.email,
                            }
                          );
                          if (status === 200) {
                            setResumeLoad(false);
                            const { data } = await getProfile();
                            setUserDetails(data);
                          } else {
                            toast.error("Something went wrong");
                          }
                        };
                      }}
                    >
                      <span>
                        {!resumeLoad ? (
                          <>
                        
                            {userDetails?.resume === "" ? "Add" : "Update"}{" "}
                            resume
                          </>
                        ) : (
                          <CircularProgress thickness={1} color="primary" />
                        )}
                      </span>
                    </Button>
                  </Col>

                  <Col xs={2}>
                    <FeatherIcon
                      icon="download"
                      size="14"
                      style={{ color: "#000000", cursor: "pointer" }}
                      onClick={() => {
                        window.open(`${imageURL}${userDetails?.resume_url}`);
                      }}
                    />
                  </Col>
                  <Col xs={2}>
                    <FeatherIcon
                      icon="trash-2"
                      size="14"
                      style={{ color: "#c13515", cursor: "pointer" }}
                      onClick={() => {
                        window.open(`${imageURL}${userDetails?.resume_url}`);
                      }}
                    />
                  </Col>
                </Row>
              </div> */}

              <div className="about_card">
                <div className="resume_main_cont">
                  <div className="col-sm-12 col-md-5 col-lg-5">
                    <div className="resume_url_cont">
                      {/* <p
                        style={{
                          fontSize: "10px",
                          paddingTop: "3%",
                          paddingLeft: "3%",
                        }}
                      >{`${imageURL}${userDetails?.resume_url}`}</p> */}
                      {userDetails?.resume_url ? (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                          }}
                        >{`${imageURL}${userDetails?.resume_url}`}</p>
                      ) : (
                        <p
                          style={{
                            fontSize: "10px",
                            paddingTop: "3%",
                            paddingLeft: "3%",
                          }}
                        ></p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-7 col-lg-7">
                    <div className="resume_name_cont">
                      <Button
                        type="button"
                        variant="outlined"
                        className="user-desc"
                        style={{
                          background: "#081753",
                          borderRadius: "8px",
                          height: "48px",
                          color: "#ffffff",
                          marginRight: "5%",
                        }}
                        onClick={async () => {
                          const input_field = document.createElement("input");
                          input_field.type = "file";
                          input_field.accept = "application/*";
                          input_field.click();
                          input_field.onchange = async e => {
                            setResumeLoad(true);
                            const file = e.target.files;
                            setResumePre(
                              URL.createObjectURL(e.target.files[0])
                            );
                            setPreviewUrl(
                              URL.createObjectURL(e.target.files[0])
                            );
                          
                            const parseData = await resumeParser(
                              file[0],
                              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Ilh5Zyt4flUlPklQU1MtO2oiLCJpYXQiOjE2NzAyMzY4NzN9.f86Y2GG0a9eQENcKPpfB7tZHNsNFWvuME9WbqPBfonU"
                            );
                          
                            // const skillData =
                            //   parseData?.data?.ResumeParserData?.SegregatedSkill?.map(
                            //     each => each.Skill
                            //   );
                            // const userCustomSkills =
                            //   userData?.user?.skill_details?.map(
                            //     each => each.name
                            //   );
                            // const appendedSkills = new Set([
                            //   ...skillData,
                            //   ...userCustomSkills,
                            // ]);
                      
                            // const skillUpdate = await updateSkill(appendedSkills)
                            const data = await getPreSignedURL(file);
                            const { status } = await updateProfileNormal(
                              "resume",
                              {
                                resume_file: data.replace(imageURL, ""),
                                first_name: userDetails.first_name,
                                last_name: userDetails.last_name,
                                email: userDetails.email,
                                update_type_to_send_email: "email",
                              }
                            );
                            if (status === 200) {
                              setResumeLoad(false);
                              const { data } = await getProfile();
                              setUserDetails(data);
                            } else {
                              toast.error("Something went wrong");
                            }
                          };
                        }}
                      >
                        <span>
                          {!resumeLoad ? (
                            <>
                              {/* <EditIcon fontSize="sm" /> */}
                              {userDetails?.resume_url === null
                                ? "Add"
                                : "Update"}{" "}
                              resume
                            </>
                          ) : (
                            <CircularProgress thickness={1} color="primary" />
                          )}
                        </span>
                      </Button>

                      <Tooltip title="Preview">
                        <VisibilityOutlinedIcon
                          style={{ marginRight: "5%", size: "8" }}
                          onClick={() => {
                            setPreview(!preview);
                            setProfileModal({
                              // modal: "center",
                              // type: "resumePreview",
                              // file: resumePre,
                            });
                          }}
                        />
                      </Tooltip>
                      {/* {preview ? (
                        <a
                          href={URL.createObjectURL(resumePre)}
                          download={resumePre.name}
                        >
                          {resumePre.name}
                        </a>
                      ) : (
                        ""
                      )} */}

                      <Tooltip title="Download">
                        <FeatherIcon
                          icon="download"
                          size="18"
                          style={{
                            color: "#000000",
                            cursor: "pointer",
                            marginRight: "5%",
                          }}
                          // onClick={() => {
                          //   window.open(
                          //     `${imageURL}${userDetails?.resume_url}`
                          //   );
                          // }}
                          onClick={() => {
                            const link = document.createElement("a");
                            link.href = `${imageURL}${userDetails?.resume_url}`;
                            link.download = "resume.pdf";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                        />
                      </Tooltip>

                      {/* <Tooltip title="Delete">
                        <FeatherIcon
                          icon="trash-2"
                          size="14"
                          style={{ color: "#c13515", cursor: "pointer" }}
                          onClick={() => {
                            window.open(
                              `${imageURL}${userDetails?.resume_url}`
                            );
                          }}
                        />
                      </Tooltip> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {preview && (
              // <DocViewer
              //   pluginRenderers={DocViewerRenderers}
              //   documents={[{ uri: `${imageURL}${userDetails?.resume_url}` }]}
              //   style={{ width: "100%", height: "350px",overflow:"auto" }}
              //   config={{ header: { disableFileName: true } }}
              // />
              <div style={{ width: "100%", height: "350px", overflow: "auto" }}>
                <FileViewer
                  fileType={fileType}
                  filePath={`${imageURL}${userDetails?.resume_url}`}
                />
              </div>
            )}
            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Skills</h2>
                <i
                  className="bx bxs-plus-circle"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "skills" })
                  }
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                ></i>
              </div>

              <div className="about_card">
                {/* <p className="text_headling_p">
                <span>Skills</span>
                <i
                  className="bx bxs-pencil"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "skills" })
                  }
                ></i>
              </p> */}

                <div className="bg-transparent skills_cont">
                  {userDetails?.skill_details?.length > 0 ? (
                    <div className="language_cont_main">
                      {userDetails?.skill_details?.map((item, idx) => (
                        // <div className="languages_cont" key={idx}>
                        //   {item.name}
                        // </div>

                        <Chip
                          key={item.id}
                          variant="solid"
                          color="primary"
                          style={{
                            background: "#ECECEC",
                            color: "#081753",
                            fontFamily: "Inter,sans-serif",
                          }}
                          //  endDecorator={<Close fontSize="sm" onClick={()=>removeSkill(item.id)}/>}
                          //  onClick={()=>removeSkill(item.id)}
                          //  {...getTagProps({ index })}
                        >
                          {item.name}
                        </Chip>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center h4 mt-3 mb-3 no_data_txt">
                      No data found
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Languages</h2>
                <i
                  className="bx bxs-plus-circle"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Languages" })
                  }
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                ></i>
              </div>

              <div className="about_card">
                {/* <p className="text_headling_p">
                <span>Skills</span>
                <i
                  className="bx bxs-pencil"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "skills" })
                  }
                ></i>
              </p> */}

                <div className="bg-transparent skills_cont">
                  {userDetails?.languages?.length > 0 ? (
                    <div className="language_cont_main">
                      {userDetails?.languages?.map((item, idx) => (
                        // <div className="languages_cont" key={idx}>
                        //   {item.name}
                        // </div>

                        <Chip
                          key={item.id}
                          variant="solid"
                          color="primary"
                          style={{
                            background: "#ECECEC",
                            color: "#081753",
                            fontFamily: "Inter,sans-serif",
                          }}
                          //  endDecorator={<Close fontSize="sm" onClick={()=>removeSkill(item.id)}/>}
                          //  onClick={()=>removeSkill(item.id)}
                          //  {...getTagProps({ index })}
                        >
                          {item}
                        </Chip>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center h4 mt-3 mb-3 no_data_txt">
                      No data found
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="about_card">
              <p className="text_headling_p">
                <span>Languages</span>
                <i
                  className="bx bxs-pencil"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Languages" })
                  }
                ></i>
              </p>

              <div className="bg-transparent skills_cont">
                {userDetails?.languages?.length > 0 ? (
                  <div className="language_cont_main">
                    {userDetails?.languages?.map((item, index) => (
                      <Chip
                        variant="solid"
                        color="primary"
                        // endDecorator={<Close fontSize="sm" onClick={()=>removeLanguages(item.id)}/>}
                        // onClick={()=>removeLanguages(item)}
                        //  {...getTagProps({ index })}
                      >
                        {item}
                      </Chip>
                    ))}
                  </div>
                ) : (
                  <div className="text-center h4 mt-3 mb-3 no_data_txt">
                    Hmm You haven't added any skills add something
                  </div>
                )}
              </div>
            </div> */}

            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Work Experience</h2>
                <i
                  className="bx bxs-plus-circle"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "experience" })
                  }
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                ></i>
              </div>

              <div className="about_card">
                {/* <p className="text_headling_p">
                <span>Work Experience</span>
                <i
                  className="fa-regular fa-plus"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "experience" })
                  }
                ></i>
              </p> */}

                <div className="pt-0 bg-transparent work_experience_card_cont">
                  {userDetails?.work_exp_details?.length > 0 ? (
                    userDetails?.work_exp_details
                      ?.filter(({ is_active }) => is_active === true)
                      ?.map(item => {
                        if (item.is_active) {
                          return (
                            <div
                              key={item.id}
                              className="row work_experience_card"
                            >
                              <div className="col-sm-12 col-md-12 col-lg-12 work_experience_card_items_cont">
                                {/* <div className="company_profile_image_cont">
                                <img
                                  src="https://www.syoft.com/assets/img/favicon.png"
                                  alt=""
                                />
                              </div> */}
                                <div className="work_experience_cart_cont">
                                  <h5 className="experience_title_name">
                                    {item?.title}
                                  </h5>
                                  <p className="experience_comp_name">
                                    {item?.company}
                                    <br />
                                    {/* Full-Time{" "} */}
                                  </p>
                                  {!item?.is_currently_working ? (
                                    <p className="experience_comp_name">
                                      {format(
                                        new Date(item?.start_date),
                                        "LLLL"
                                      )}
                                      .{" "}
                                      {new Date(item?.start_date).getFullYear()}{" "}
                                      -{" "}
                                      {format(new Date(item?.end_date), "LLLL")}
                                      . {new Date(item?.end_date).getFullYear()}
                                    </p>
                                  ) : (
                                    <p className="experience_comp_name">
                                      {format(
                                        new Date(item?.start_date),
                                        "LLLL"
                                      )}
                                      .{" "}
                                      {new Date(item?.start_date).getFullYear()}{" "}
                                      -{"present"}
                                    </p>
                                  )}
                                  <p className="experience_comp_name">
                                    {item?.location}
                                  </p>
                                  <p className="experience_comp_desc">
                                    {item?.description}
                                  </p>
                                </div>
                                <div className="profile_works_icon_cont">
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-trash float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() => removeExperience(item.id)}
                                    ></i>
                                  </span>
                                  <span>
                                    {" "}
                                    <i
                                      className="bx bxs-pencil float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() =>
                                        setProfileModal({
                                          modal: "center",
                                          id: item.id,
                                          type: "edit-experience",
                                        })
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                  ) : (
                    <div className="text-center h4 mt-3 mb-3 no_data_txt">
                      No Data Found
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Education</h2>
                <i
                  className="bx bxs-plus-circle"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Training" })
                  }
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                ></i>
              </div>
              <div className="about_card">
                {/* <p className="text_headling_p">
                  <span>Educational Qualifications</span>
                  <i
                    className="fa-regular fa-plus"
                    onClick={() =>
                      setProfileModal({ modal: "center", type: "Training" })
                    }
                  ></i>
                </p> */}
                <div className="">
                  {userDetails?.education_training_details?.length > 0 ? (
                    userDetails?.education_training_details
                      ?.filter(each => each.is_active === true)
                      ?.map((item, index) => {
                        if (item.is_active) {
                          return (
                            <div
                              key={index}
                              className="mb-4 work_experience_card"
                            >
                              <div className="col-sm-12 col-md-12 col-lg-12 work_experience_card_items_cont">
                                {/* <div className="company_profile_image_cont">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/2480/2480655.png"
                                    alt=""
                                  />
                                </div> */}
                                <div
                                  className="work_experience_cart_cont"
                                  style={{ paddingLeft: "23px" }}
                                >
                                  <h5 className="experience_title_name">
                                    {item?.certificate_name}
                                  </h5>
                                  <p className="experience_comp_name">
                                    {item?.institute_name}
                                  </p>

                                  <p className="experience_comp_dates">
                                    {/* FullTime .{" "} */}
                                    {format(new Date(item?.start_date), "LLLL")}
                                    {new Date(
                                      item?.start_date
                                    ).getFullYear()}-{" "}
                                    {format(new Date(item?.end_date), "LLLL")}
                                    {new Date(item?.end_date).getFullYear()}
                                  </p>
                                  <p className="experience_comp_dates">
                                    {item?.location}
                                  </p>
                                  <p className="experience_comp_desc">
                                    {item?.description}
                                  </p>
                                  {/* <div className="profile_documents_cont">
                              <div>
                                <img
                                  src="https://img.freepik.com/free-vector/classy-ornamental-certificate-template-beige-black_53876-116396.jpg?w=2000"
                                  alt=""
                                />
                              </div>
                              <div>cerificate.jpeg</div>
                            </div> */}
                                </div>
                                <div className="profile_works_icon_cont">
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-trash float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() => removeEducation(item.id)}
                                    ></i>
                                  </span>
                                  <span>
                                    {" "}
                                    <i
                                      className="bx bxs-pencil float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() =>
                                        setProfileModal({
                                          modal: "center",
                                          id: item.id,
                                          type: "edit-education",
                                        })
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                  ) : (
                    <div className="text-center h4 mt-3 mb-3 no_data_txt">
                      No Data Found
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              {/* <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Certifications</h2>
                <i
                  className="bx bxs-plus-circle"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Files" })
                  }
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                ></i>
              </div> */}

              <div className="">
                {/* <p className="text_headling_p">
                <span>Certifications</span>
                <i
                  className="fa-regular fa-plus"
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Files" })
                  }
                ></i>
              </p> */}
                {/* <div className="">
                  {userDetails?.cerifications?.length > 0 ? (
                    userDetails?.education_training_details
                      ?.filter(each => each.is_active === true)
                      ?.map((item, index) => {
                        if (item.is_active) {
                          return (
                            <div
                              key={index}
                              className="mb-4 work_experience_card"
                            >
                              <div className="col-sm-12 col-md-12 col-lg-12 work_experience_card_items_cont">
                                <div className="company_profile_image_cont">
                                  <img
                                    src="https://cdn-icons-png.flaticon.com/512/2480/2480655.png"
                                    alt=""
                                  />
                                </div>
                                <div className="work_experience_cart_cont">
                                  <h5 className="experience_title_name">
                                    {item?.institute_name}
                                  </h5>
                                  <p className="experience_comp_name">
                                    {item?.certificate_name}
                                  </p>
                                  <p className="experience_comp_dates">
                                    {" "}
                                    {format(new Date(item?.start_date), "LLLL")}
                                    . {new Date(item?.start_date).getFullYear()}
                                    - {format(new Date(item?.end_date), "LLLL")}
                                    . {new Date(item?.end_date).getFullYear()}
                                  </p>
                                  <p className="experience_comp_dates">
                                    {item?.location}
                                  </p>
                                  <p>{item?.description}</p>
                                
                                </div>
                                <div className="profile_works_icon_cont">
                                  <span>
                                    {" "}
                                    <i
                                      className="fa fa-trash float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() => removeEducation(item.id)}
                                    ></i>
                                  </span>
                                  <span>
                                    {" "}
                                    <i
                                      className="bx bxs-pencil float-right text-center cursor-pointer profile_works_icons"
                                      onClick={() =>
                                        setProfileModal({
                                          modal: "center",
                                          id: item.id,
                                          type: "edit-education",
                                        })
                                      }
                                    ></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })
                  ) : (
                    <div className="text-center h4 mt-3 mb-3 no_data_txt">
                      No Data Found
                    </div>
                  )}
                </div> */}
              </div>
            </div>

            {/* <div>
              <div className="profile_category_desc">
                <div
                  style={{ borderWidth: "2px", borderRadius: "18%" }}
                  className="profile_divider"
                ></div>
                <h2 className="profile_summ_txt">Other Links</h2>
                <i
                  className="bx bxs-pencil"
                  style={{
                    fontSize: "18px",
                    paddingLeft: "8px",
                    paddingBottom: "3px",
                  }}
                  onClick={() =>
                    setProfileModal({ modal: "center", type: "Files" })
                  }
                ></i>
              </div>
              <div className="about_card">
                <Row
                  className="user-desc"
                  md="4"
                  sm="2"
                  xs="1"
                  sx={{ padding: "40px" }}
                >
                  <Col xs={6} className="about_me_details">
                    <p>LinkedIn</p>
                    <p>LinkedIn/saikrishnav</p>
                  </Col>
                  <Col xs={6} className="about_me_details">
                    <p>Github</p>
                    <p>Github/saikrishnav</p>
                  </Col>
                  <Col xs={6} className="about_me_details">
                    <p>Dribble</p>
                    <p>Dribble/saikrishnav</p>
                  </Col>
                  <Col xs={6} className="about_me_details">
                    <p>Website</p>
                    <p>www.saikrish.ccbp.tech</p>
                  </Col>
                </Row>
              </div>
            </div> */}
          </div>
          <ProfileModal
            setProfileModal={doc => setProfileModal(doc)}
            show={profileModal.modal}
            onHide={data => {
              setProfileModal({
                modal: data,
                modalType: "",
                type: "",
                id: "",
                details: {},
              });
            }}
            params={profileModal}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
          />
        </>
      ) : (
        <>
          {Array.from(new Array(15)).map((item, index) => (
            <div style={{ paddingLeft: "50px" }}>
              <div className="card-employers" id="card-employers" key={index}>
                <div className="align-items-center comp_card_main_cont">
                  <div className="comp_employers_logo">
                    <div
                      className="comp_profile_card_cont"
                      id="comp_profile_card_cont"
                    >
                      <Skeleton variant="rectangular" width={80} height={80} />
                    </div>
                  </div>
                  <div className="employer_name_cont">
                    <p className="employer_name">
                      <span>
                        <strong className="comp_name">
                          <Skeleton width="100%" />
                        </strong>
                      </span>
                    </p>
                    <div className="d-flex comp_position_cont">
                      <Skeleton width="100%" />
                    </div>
                    <div className="comp_specs_cont">
                      <Skeleton width="100%" />
                    </div>
                  </div>
                  <div className="comp_employers_redirection_cont"></div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default Profile;
