const Constants = {
  // category: "Browse Jobs by Functional Area",
  category: "Browse Jobs by Categories",
  job: "Latest Jobs",
  job_reminder: "Set a job reminder",
  from_blog: "From Blog",
  reminder: "Reminder",
  location: "Location",
  type: "Job Type",
  experience: "Experience Level",
  salary: "Salary Range",
  singup_text: "Sign up to get",
  signup_text2: " the latest jobs",
  subscribe: "Subscribe",
  blog: "Relevant news and more for you. Welcome to our blog",
  news: "Latest News",
  tags: "Popular Tags",
  browse: "Browse All",
  get_app: "Get the zepul app",
  find_button: "Find Now",
  sign_in: "Sign in to your account",
  create_account: "Create An Account",
};

export { Constants };
