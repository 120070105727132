import React, { useEffect, useState } from "react";
import SignUpForJobs from "../../JobsList/SignUpForJobs/SignUpForJobs";
import AreHiring from "../../Reusable/AreHiring";
import BlogsListsCategoriesTags from "../BlogsList/BlogslistsCategoriesTags";
import Breadcrumb from "../Breadcrumb";
import BlogsDescription from "./BlogsDescription";
import RecruiterProfile from "./RecruiterProfile";
import { useParams } from "react-router-dom";
import {
  allCategories,
  getBlogsBasedOnId,
} from "../../../helpers/dataFetcher/blogs";
import edjsHTML from "editorjs-html";

function BlogsSingleIndex() {
  let html;
  const edjsParser = edjsHTML();
  const [categories, setCategories] = useState([]);

  const [description, setDescription] = useState([]);
  const [blogData, setBlogData] = useState({});
  const { id } = useParams();
  useEffect(() => {
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { data: categories } = await allCategories();
    setCategories(categories?.data);
    const { data } = await getBlogsBasedOnId(id);
    console.log(data);
    const blocking = { blocks: data?.data[0]?.content?.blocks };
    html = edjsParser.parse(blocking);
    setDescription(html);
    setBlogData(data?.data[0]);
  }
  return (
    <>
      <div className="archive-header pt-50 pb-50 text-center">
        <div className="container">
          <h3 className="mb-30 text-center w-75 mx-auto">{blogData?.title}</h3>
          <div className="post-meta text-muted d-flex align-items-center mx-auto justify-content-center">
            {/* <div className="author d-flex align-items-center mr-30">
              <img alt="Zepul" src="assets/imgs/avatar/ava_16.png" />
              <span>Sarah Harding</span>
            </div>
            <div className="date mr-30">
              <span>
                <i className="fi-rr-edit mr-5 text-grey-6"></i>06 Sep 2022
              </span>
            </div>
            <div className="icons">
              <span>
                <i className="fi fi-rr-bookmark mr-5 text-muted"></i>
              </span>
              <span>
                <i className="fi fi-rr-heart text-muted"></i>
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <div className="post-loop-grid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <BlogsDescription blogData={blogData} description={description} />
              {/* <RecruiterProfile /> */}
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30">
              <BlogsListsCategoriesTags categories={categories} />
              {/* <AreHiring /> */}
            </div>
          </div>
        </div>
        <SignUpForJobs />
      </div>
    </>
  );
}
export default BlogsSingleIndex;
