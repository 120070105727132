import axios from "axios";

// const API_URL = "http://localhost:8082/api";
// const API_URL = "http://192.168.29.163:8082/api";
// const API_URL = "http://192.168.0.114:8082/api";

const API_URL = "https://backend.zepul.com/api/";

const axiosApi = axios.create({
  
  baseURL: API_URL,
});
function listner() {
  return localStorage.getItem("token");
}
export default async function callApi(
  url,
  data = {},
  method = "GET",
  config = {}
) {
  let newURl = url;
  if (url?.startsWith("/")) {
    newURl = url?.replace("/", "");
  }

  return await axiosApi({
    method: method,
    url: newURl,
    data,
    headers: {
      Authorization: "Bearer " + listner(),
      ...config,
    },
  })
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        if (res?.data?.message?.length > 0) {
        }
      } else if (res.status === 400) {
      }
      return { data: res.data, status: res.status };
    })
    .catch(error => {
      if (error.response) {
        if (error?.response.status === 0) {
          return;
        }
        return { status: error?.response.status, data: error?.response?.data };
      } else if (error.request) {
      } else {
      }
    });
}
