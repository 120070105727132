import React, { useState, Fragment, useEffect } from "react";
import updateProfile, {
  updateProfileNormal,
} from "../helpers/dataFetcher/profile/update";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PlacesAutocomplete } from "../data/locationSearch";
import { convertToInputDates } from "../functions/date";
import { Form, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
// import Box from "@mui/joy/Box";
import Box from "@mui/material/Box";
import Textarea from "@mui/joy/Textarea";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import Button from "@mui/joy/Button";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import {
  LocationCityOutlined,
  LocationOnOutlined,
  Map,
} from "@mui/icons-material";

import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { globalTheme } from "../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  textarea: {
    "& .css-1onucoh-JoyTextarea-textarea": {
      height: "0px !important",
    },

    "& textarea": {
      minHeight: "48px !important",
      overflow: "auto !important",
      fontFamily: "Inter,sans-serif",
    },
    "& textarea:focus": {
      border: "none !important",
    },
  },
});

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  // const script = document.createElement("script");
  // script.setAttribute("async", "");
  // script.setAttribute("id", id);
  // script.src = src;
  // position.appendChild(script);
}

const autocompleteService = { current: null };

function EditWorkExperience(props) {
  const { onHide, setUserDetails, userDetails } = props;
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const classes = useStyles();
  const [location, setLocation] = useState("");
  const [options, setOptions] = useState([]);
  const [inputFields, setInputFields] = useState([
    {
      title: "",
      company: "",
      description: "",
      location: "",
      notice_period: "",
      start_date: "",
      end_date: "",
    },
  ]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: `${inputFields[0].title ? inputFields[0].title : ""}`,
      company: `${inputFields[0].company ? inputFields[0].company : ""}`,
      location: `${inputFields[0].location ? inputFields[0].location : ""}`,
      description: `${
        inputFields[0].description ? inputFields[0].description : ""
      }`,
      notice_period: `${
        inputFields[0].notice_period ? inputFields[0].notice_period : ""
      }`,
      start_date: `${
        inputFields[0].start_date ? inputFields[0].start_date.slice(0, 10) : ""
      }`,
      end_date: `${
        inputFields[0].end_date ? inputFields[0].end_date.slice(0, 10) : ""
      }`,
    },
    mode: "onChange",
  });

  /*location search  start*/
  const [inputValue, setInputValue] = React.useState("");
  const loaded = React.useRef(false);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);
  /*Location search end *.

  /*Date changes functonality start */
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const minEndDate = startDate ? new Date(startDate + "-01T00:00:00") : null;

  useEffect(() => {
    const today = new Date();
    setMaxDate(today);
    setValue("start_date", "");
  }, [setValue]);

  const handleStartDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    if (endDate && selectedDate > new Date(endDate + "-01T00:00:00")) {
      setValue("end_date", ""); // Clear the end date
    }
    setValue("start_date", selectedDate.toISOString().slice(0, 7));
  };

  const handleEndDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    setValue("end_date", selectedDate.toISOString().slice(0, 7));
  };

  /*Date changes functonality End */

  // const startDate = watch("start_date");

  useEffect(() => {
    if (props?.id) {
      setInputFields([
        userDetails?.work_exp_details?.find(({ id }) => id === props.id),
      ]);
    }
  }, [props?.params?.id]);

  useEffect(() => {
    let defaults = {
      title: `${inputFields[0].title ? inputFields[0].title : ""}`,
      company: `${inputFields[0].company ? inputFields[0].company : ""}`,
      description: `${
        inputFields[0].description ? inputFields[0].description : ""
      }`,
      // location: `${
      //   inputFields[0].location ? inputFields[0].location : ""
      // }`,
      location: inputFields[0]?.location || "",
      notice_period: `${
        inputFields[0].notice_period ? inputFields[0].notice_period : ""
      }`,
      start_date: `${
        inputFields[0].start_date ? inputFields[0].start_date.slice(0, 10) : ""
      }`,
      end_date: `${
        inputFields[0].end_date ? inputFields[0].end_date.slice(0, 10) : ""
      }`,
    };
    reset(defaults);
  }, [inputFields, reset]);
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      title: "",
      company: "",
      description: "",
      location: "",
      notice_period: "",
      start_date: "",
      end_date: "",
    });
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "title") {
      values[index].title = event.target.value;
    } else if (event.target.name === "currently_working") {
      values[index].is_currently_working = !values[index].is_currently_working;
    } else if (event.target.name === "company") {
      values[index].company = event.target.value;
    } else if (event.target.name === "description") {
      values[index].description = event.target.value;
    } else if (event.target.name === "location") {
      values[index].location = event.target.value;
    } else if (event.target.name === "noticeperiod") {
      values[index].notice_period = event.target.value;
    } else if (event.target.name === "start_date") {
      values[index].start_date = event.target.value;
    } else if (event.target.name === "end_date") {
      values[index].end_date = event.target.value;
    }
    setInputFields(values);
  };

  const resetForm = e =>
    setInputFields([
      {
        title: "",
        company: "",
        description: "",
        location: "",
        noticeperiod: "",
        start_date: "",
        end_date: "",
      },
    ]);

  const onSubmit = async editData => {
    const editedData = [editData];
    const index = userDetails?.work_exp_details.indexOf(
      ({ id }) => id === props.id
    );

    inputFields[0].title = editedData[0].title;
    inputFields[0].company = editedData[0].company;
    inputFields[0].description = editedData[0].description;
    inputFields[0].notice_period = editedData[0].notice_period;
    inputFields[0].start_date = editedData[0].start_date;
    inputFields[0].end_date = editedData[0].end_date;
    inputFields[0].location = editedData[0].location;
    // const data = userDetails?.work_exp_details;
    // data[index] = inputFields[0];

    const oldWorkExpFilter = userDetails?.work_exp_details?.filter(each => {
      if (each.is_active) {
        return { ...each };
      }
    });

    const data = oldWorkExpFilter;
    data[index] = inputFields[0];
    const updatedOne = data.map(item => {
      return {
        title: item.title,
        company: item.company,
        description: item.description,
        location: item.location,
        notice_period:
          item?.notice_period === null ? 0 : Number(item.notice_period),
        is_currently_working: item.is_currently_working,
        start_date: item.start_date,
        end_date: item.end_date,
      };
    });
    const { status } = await updateProfileNormal("work_experience", {
      update_type_to_send_email: "email",
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      work_exp: updatedOne,
    });
    if (status !== 200) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    const { data: result } = await getProfile();
    setUserDetails(result);
    onHide();
  };

  async function closeModal() {
    onHide();
  }
  // const minEndDate = startDate ? startDate : null;
  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <Form
          onSubmit={handleSubmit(data => onSubmit(data))}
          className="about_us_form"
        >
          <div className="row form-row">
            {inputFields?.map((inputField, index) => (
              <Fragment key={`${inputField}~${index}`}>
                {/* <div className="form-group col-6">
              <label className="form-lables">Title</label>
              <Input
                type="text"
                placeholder="Title"
                className="form-control"
                id="title"
                name="title"
                value={inputField.title}
                invalid={inputField.title == ""?error:null}
                onChange={event => handleInputChange(index, event)}
              />
            </div> */}

                <Col xs={12} md={6} className="mt-4">
                  <TextField
                    variant="outlined"
                    size="small"
                    // label={inputField.title}
                    label="Role"
                    name="title"
                    // defaultValue={inputField.title}
                    // value={inputField.title}
                    // value={null}
                    error={errors.title ? true : false}
                    id="first_name"
                    type="text"
                    // defaultValue={userDetails?.first_name}
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    {...register("title", { required: true })}
                  />
                </Col>

                {/* <div className="form-group col-6">
              <label htmlFor="company">Company</label>
              <Input
                type="text"
                placeholder="Company"
                className="form-control"
                id="company"
                name="company"
                value={inputField.company}
                invalid={inputField.company == ""?error:null}
                onChange={event => handleInputChange(index, event)}
              />
            </div> */}

                <Col xs={12} md={6} className="mt-4">
                  <TextField
                    variant="outlined"
                    size="small"
                    // label={inputField.company}
                    // value={inputField.company}
                    label="Company"
                    value={null}
                    name="company"
                    error={errors.company ? true : false}
                    id="first_name"
                    type="text"
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    {...register("company", { required: true })}
                  />
                </Col>

                {/* <div className="form-group col-12">
                <label htmlFor="location">Location</label>
                <PlacesAutocomplete
                  location={inputField.location}
                  setLocation={e => {
                    handleInputChange(index, {
                      target: { value: e, name: "location" },
                    });
                  }}
                />
              </div> */}

                <Col xs={12} md={6} className="mt-4 w-100">
                  {/* <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOnOutlined
                            // color={errors.location ? "danger" : "primary"}
                            sx={{
                              color: errors.location ? "red" : "#081753",
                              "&:hover": {
                                color: errors.location ? "red" : "#081753",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    size="small"
                    label="Enter Your Location"
                    name="location"
                    error={errors.location ? true : false}
                    id="first_name"
                    type="text"
                    // defaultValue={userDetails?.first_name}
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    {...register("location", { required: true })}
                  /> */}
                </Col>

                <Col className="mt-4" xs={12}>
                  <Autocomplete
                    size="small"
                    id="google-map-demo"
                    value={getValues("location") || ""}
                    getOptionLabel={option =>
                      typeof option === "string" ? option : option.description
                    }
                    filterOptions={x => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="No locations"
                    {...register("location", { required: true })}
                    onChange={(event, newValue) => {
                      setValue("location", newValue);
                      setOptions(newValue ? [newValue, ...options] : options);
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        error={errors?.location ? true : false}
                        {...register("location", { required: true })}
                        label="location"
                        placeholder="Add a location"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => {
                      const matches =
                        option.structured_formatting
                          .main_text_matched_substrings || [];

                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map(match => [
                          match.offset,
                          match.offset + match.length,
                        ])
                      );

                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: "flex", width: 44 }}>
                              <LocationOnIcon
                                sx={{ color: "text.secondary" }}
                              />
                            </Grid>
                            <Grid
                              item
                              sx={{
                                width: "calc(100% - 44px)",
                                wordWrap: "break-word",
                              }}
                            >
                              {parts.map((part, index) => (
                                <Box
                                  key={index}
                                  component="span"
                                  sx={{
                                    fontWeight: part.highlight
                                      ? "bold"
                                      : "regular",
                                  }}
                                >
                                  {part.text}
                                </Box>
                              ))}
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />
                </Col>

                <div className="mb-3 col-12 form-check pt-2">
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="currently_working"
                    checked={inputField?.is_currently_working}
                    onChange={event => handleInputChange(index, event)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Currently Working
                  </label>
                </div>
                {/* <div className="form-group col-12">
              <label htmlFor="start_date">Start Date</label>
              <Input
                type="date"
                className="form-control"
                id="start_date"
                name="start_date"
                invalid={inputField.start_date == ""?error:null}
                value={convertToInputDates(inputField.start_date)}
                onChange={event => handleInputChange(index, event)}
              />
            </div> */}

                <Col xs={12} md={6} className="mt-2 ">
                  <label>Start Date</label>
                  {/* <TextField
                  variant="outlined"
                  size="small"
                  // label={inputField.start_date}
                  name="start_date"
                  // value={convertToInputDates(inputField.start_date)}
                  error={errors.start_date ? true : false}
                  id="first_name"
                  type="date"
                  onChange={event => handleInputChange(index, event)}
                  className="form-control"
                  {...register("start_date", { required: true })}
                /> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      value={startDate ? startDate : null}
                      views={["year", "month"]}
                      onChange={value => handleStartDateChange(value)}
                      renderInput={props => (
                        <TextField
                          {...props}
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={errors.start_date ? true : false}
                          // onChange={event => handleInputChange(index, event)}
                          // InputProps={{
                          //   ...props.InputProps,
                          //   max: maxDate,
                          // }}
                          {...register("start_date", { required: true })}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>

                {!inputField.is_currently_working ? (
                  // <div className="form-group col-12">
                  //   <label htmlFor="end_date">End Date</label>
                  //   <Input
                  //     type="date"
                  //     className="form-control"
                  //     id="end_date"
                  //     name="end_date"
                  //     value={convertToInputDates(inputField.end_date)}
                  //     invalid={inputField.end_date == ""?error:null}
                  //     onChange={event => handleInputChange(index, event)}
                  //   />
                  // </div>

                  <Col xs={12} md={6} className="mt-2">
                    <label>End Date</label>
                    {/* <TextField
                    variant="outlined"
                    size="small"
                    // label={inputField.end_date}
                    name="end_date"
                    error={errors.end_date ? true : false}
                    // id="first_name"
                    type="date"
                    onChange={event => handleInputChange(index, event)}
                    inputProps={{
                      min: minEndDate
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    className="form-control"
                    {...register("end_date", { required: true })}
                  /> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        value={endDate ? endDate : null}
                        minDate={minEndDate}
                        views={["year", "month"]}
                        onChange={value => handleEndDateChange(value)}
                        renderInput={props => (
                          <TextField
                            {...props}
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={errors.end_date ? true : false}
                            {...register("end_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                ) : null}
                {inputField.is_currently_working ? (
                  // <div className="form-group col-12">
                  //   <label htmlFor="noticeperiod">Notice Period</label>
                  //   <Input
                  //     type="number"
                  //     placeholder="Notice Period"
                  //     className="form-control"
                  //     id="noticeperiod"
                  //     name="noticeperiod"
                  //     value={inputField.noticeperiod}
                  //     invalid={inputField.noticeperiod == ""?error:null}
                  //     onChange={event => handleInputChange(index, event)}
                  //   />
                  // </div>

                  <Col xs={12} md={6} className="mt-2">
                    <label>Notice Period</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      label={inputField.noticeperiod}
                      // value={inputField.noticeperiod}
                      // name="Start_Date"
                      error={errors.notice_period ? true : false}
                      id="first_name"
                      type="number"
                      onChange={event => handleInputChange(index, event)}
                      className="form-control"
                      {...register("notice_period", {
                        required: true,
                        validate: value => value > 0,
                      })}
                    />
                  </Col>
                ) : null}
                {/* <div className="form-group col-12">
                <label htmlFor="description">Description</label>
                <Input
                  type="text"
                  placeholder="Description"
                  className="form-control text_area_input"
                  id="description"
                  name="description"
                  style={{ height: 73 }}
                  value={inputField.description}
                  invalid={inputField.description == "" ? error : null}
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

                <Col
                  xs={12}
                  className="mt-4"
                  // style={{ height: "10vh", overflowY: "scroll" }}
                >
                  {/* <TextField
                  style={{ width: "100%" }}
                  // id="outlined-multiline-static"
                  // label={inputField.description}
                  error={errors.description ? true : false}
                  // value={inputField.description}
                  row={5}
                  {...register("description", { required: true })}
                /> */}
                  <Box>
                    {/* <Textarea
                      placeholder="Type in here…"
                      minRows={2}
                      maxRows={4}
                      // error={errors.description ? true : false}
                      error={Boolean(errors.description)}
                      {...register("description", { required: true })}
                      className={classes.textarea}
                      sx={{
                        borderRadius: "4px !important",
                        borderColor: errors.description ? "red" : "#ced4da",
                      }}
                    /> */}

                    <label htmlFor="description">Description</label>
                    <TextareaAutosize
                      id="description"
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Type in here…"
                      error={Boolean(errors.description)}
                      {...register("description", { required: true })}
                      style={{
                        minHeight: "60px",
                        maxHeight: "60px",
                        borderRadius: "8px",
                        borderColor: errors.description ? "red" : "#ced4da",
                      }}
                    />
                  </Box>
                </Col>

                {/* <div className="d-flex justify-content-end form-group ">
                  <Tooltip title="Remove Experiances" placement="top">
                    <button
                      className="btn btn-link me-2 icon-hover"
                      type="button"
                      disabled={index === 0}
                      onClick={() => handleRemoveFields(index)}
                    >
                      <RemoveIcon />
                    </button>
                  </Tooltip>
                  <Tooltip title="Add Experiances" placement="top">
                    <button
                      className="btn btn-link me-2 icon-hover"
                      type="button"
                      onClick={() => handleAddFields()}
                    >
                      <AddIcon />
                    </button>
                  </Tooltip>
                </div> */}
              </Fragment>
            ))}
          </div>
          <div className="d-flex justify-content-end submit-button mt-2">
           

            <Button
              type="reset"
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
              onClick={resetForm}
            >
              Reset
            </Button>
            <Button
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
              type="submit"
            >
              Save
            </Button>
            <Button
              onClick={() => closeModal()}
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
            >
              Close
            </Button>
          </div>
        </Form>
      </ThemeProvider>
    </>
  );
}

export default EditWorkExperience;