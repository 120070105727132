import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./login.css";
import { login } from "../helpers/dataFetcher/profile/profile";
import { Col, Form, Row } from "reactstrap";
import google from "../assets/images/google.png";
import { useDispatch } from "react-redux";
import { addUserAction } from "../store/auth/actions";
import { useForm } from "react-hook-form";
import { useRef } from "react";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { CssVarsProvider } from "@mui/joy/styles";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";
import { resetPassword } from "../helpers/dataFetcher/auth";
import { toast } from "react-hot-toast";
function ResetPassword() {
  const [loginStatus, setLoginStatus] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [divClick, setDivClick] = useState(false);
  const [passwordClick, setPasswordClick] = useState(false);
  const usePasswordRef = useRef(null);
  const ref = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        // setDivClick(false);
      } else {
        setError(false);
        setDivClick(true);
      }
      if (
        usePasswordRef.current &&
        !usePasswordRef.current.contains(event.target)
      ) {
        // setPasswordClick(false);
      } else {
        setError(false);
        setPasswordClick(true);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const loginFunc = async data => {
    setLoginStatus(true);
    if (data?.password !== data?.cpassword) return;
    const { status, data: loginObj } = await resetPassword(
      { password: data?.password },
      searchParams?.get("token")
    );
    if (status !== 200) {
      setError(true);
      setLoginStatus(false);

      toast.error(loginObj?.data);
      return;
    }
    toast.success("Password Reset Successfully");
    setLoginStatus(false);
    navigate("/login");
    localStorage.setItem("token", loginObj.access);
    localStorage.setItem("user_id", loginObj.id);
    localStorage.setItem("user_type", loginObj.usertype);
    dispatch(addUserAction(loginObj));
    return;
  };
  console.log(watch("password") === watch("cpassword"));
  return (
    <>
      <div className="row login_main_cont">
        <div className="col-sm-12 col-md-12 col-lg-6 login_left_cont">
          <h1 className="login_description_text">
            Join Zepul,
            <br />
            Start
            <span> Your Carrer</span>
          </h1>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 login_right_cont">
          <div className="login_inner_main_cont">
            <div className="mb-3">
              <h4>Reset Your Password</h4>
              <p>Enter Your New Password And Confirm it</p>
            </div>

            <Form onSubmit={handleSubmit(loginFunc)}>
              <Row>
                <Col xs={12} className="mb-4">
                  <div ref={usePasswordRef}>
                    <div
                      className={`${
                        !errors.password
                          ? passwordClick
                            ? "clicked"
                            : "_1jj61m9"
                          : "eror_showing_pass"
                      }`}
                    >
                      <label
                        className="_1yw7hpv"
                        htmlFor="password-login-password"
                      >
                        <div
                          className={`${
                            passwordClick ? "label_move" : "_1jn0ze9"
                          }`}
                          style={{ transition: "0.1s ease-in-out" }}
                        >
                          <div
                            className={`${
                              passwordClick
                                ? "password_text"
                                : "small_text_label"
                            }`}
                            style={{
                              fontWeight: `${
                                errors.password ? "530" : "normal"
                              }`,
                              color: `${errors.password ? "red" : "black"}`,
                            }}
                          >
                            Password
                          </div>
                        </div>
                        <div dir="ltr">
                          <div
                            className={`${
                              passwordClick ? "new_inpt_place" : "_js9i23"
                            }`}
                          >
                            <input
                              placeholder="Password"
                              name="user[password]"
                              data-testid="password-login-email"
                              aria-required="true"
                              className={`${
                                passwordClick ? "click_input" : "_c5rhl5"
                              }`}
                              id="password-login-password"
                              autoComplete="off"
                              inputMode="password"
                              type="password"
                              {...register("password", { required: true })}
                              aria-describedby="password-login-password-InputField-help"
                            />
                          </div>
                        </div>{" "}
                      </label>
                    </div>
                    <div className="_klarpw"></div>
                  </div>
                </Col>
                <Col xs={12} className="mb-4">
                  <div ref={ref}>
                    <div
                      className={`${
                        !errors.cpassword
                          ? divClick
                            ? "clicked"
                            : "_1jj61m9"
                          : "eror_showing_pass"
                      }`}
                    >
                      <label className="_1yw7hpv" htmlFor="c-login-cpassword">
                        <div
                          className={`${divClick ? "label_move" : "_1jn0ze9"}`}
                          style={{
                            transition: "0.1s ease-in-out",
                            width: "200px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: `${
                                errors.cpassword ? "530" : "normal"
                              }`,
                              color: `${errors.cpassword ? "red" : "black"}`,
                            }}
                            className={`${
                              divClick ? "c_password_text" : "small_text_label"
                            }`}
                          >
                            confirm password
                          </div>
                        </div>
                        <div dir="ltr">
                          <div
                            className={`${
                              divClick ? "new_inpt_place" : "_js9i23"
                            }`}
                          >
                            <input
                              placeholder="confirm password"
                              name="user[cpassword]"
                              data-testid="cpassword-login-cpassword"
                              aria-required="true"
                              className={`${
                                divClick ? "click_input" : "_c5rhl5"
                              }`}
                              id="cpassword-login-cpassword"
                              autoComplete="off"
                              inputMode="cpassword"
                              disabled={
                                watch("password")?.length === 0 ||
                                watch("password")?.length === undefined
                                  ? true
                                  : false
                              }
                              type="cpassword"
                              error={
                                watch("password") === watch("cpassword")
                                  ? false
                                  : true
                              }
                              {...register("cpassword", {
                                required: true,
                                validate: (value, formValues) => {
                                  return value === formValues?.password;
                                },
                              })}
                              aria-describedby="cpassword-login-cpassword-InputField-help"
                            />
                          </div>
                        </div>{" "}
                      </label>
                    </div>
                    <div className="_klarpw"></div>
                  </div>
                </Col>
                <Col xs={12}>
                  {error ? (
                    <div className="error_message_login">
                      <ErrorOutlineOutlined style={{ color: "#c13515" }} />
                      <div>
                        <p className="motivational_text">
                          Let's Try That Again
                        </p>
                        <p className="reason_for_error">
                          The Credentials are Not matching with our Records.
                          Make sure you've entered the right Credentials and try
                          again.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col xs={12}>
                  {!loginStatus ? (
                    <CssVarsProvider>
                      <Button
                        className="login_btn"
                        variant="solid"
                        type="submit"
                      >
                        Sign In
                      </Button>
                    </CssVarsProvider>
                  ) : (
                    <CssVarsProvider>
                      <Button
                        className="login_btn"
                        variant="solid"
                        startDecorator={
                          <CircularProgress variant="solid" thickness={2} />
                        }
                      >
                        Loading…
                      </Button>
                    </CssVarsProvider>
                  )}
                </Col>
              </Row>

              <div className="d-flex justify-content-end">
                <p className="signup">
                  Go back To Login Page{" "}
                  <Button variant="plain" component={Link} to="../login">
                    Sign In
                  </Button>
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
