import React, { useState } from "react";
import Button from "@mui/joy/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import referDummy from "../../assets/images/referNowDummy.png";
import jobss from '../../assets/images/jobs2.png';

const styleCard = {
  background: "rgba(91, 101, 141, 0.16)",
  borderRadius: "16px",
  boxShadow: "0px !important",
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(91, 101, 141, 0.16) !important",
    borderRadius: "16px !important",
    // padding:"5%",
    boxShadow: "0px !important",
    minHeight: "225px",
    //   maxHeight:"226px",
  },
  //   header: {
  //     "& .css-1ssile9-MuiCardHeader-avatar": {
  //       marginRight: "0px !important",
  //     },
  //   },
});

function EmployerPage2() {
  const classes = useStyles();
  return (
    <>
      <section className="section-box" style={{ paddingLeft: "2%" }}>
        <div className="latest_jobs_cont_main">
          <div className="row home_category_head">
            <div className="col-lg-7">
              <h2 className="refer_2_zepul_txt">
                How <span>Zepul </span>works for Employers
              </h2>
              <p className="refer_2_zepulpara_txt">
                We offer a comprehensive solution to streamline your entire
                hiring process enabling you to effortlessly post jobs, receive
                referrals and hire top talent.
              </p>
            </div>
            <div className="col-lg-5 text-lg-end justify-content-end">
              <Button
                variant="outlined"
                className="apply-now-btn cursor-pointerf"
                onClick={() => navigate("/more-fn-areas")}
                style={{
                  background: "#ffffff",
                  color: "#012480",
                  fontFamily: "Plus Jakarta Display,sans-serif",
                  border: "1px solid rgba(25, 103, 210, 0.23)",
                  fontWeight: "400",
                  fontSize: "16px",
                  marginRight: "23px",
                  borderRadius: "24px",
                }}
              >
                Show All <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
              </Button>
            </div>
          </div>

          <div className="refer2_main_cont">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="refer2_inside_cont1">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "20px" }}
                    >
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                01
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec"
                          >
                            Post <span>a Job</span>
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            Effortlessly post jobs and reach a wide pool of the
                            right talent with{" "}
                            <span>with Zepul's user-friendly platform.</span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                02
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec2"
                          >
                            {/* We{" "}
                            <span className="refersec2_head_sec2_span">
                              advertise your position
                            </span>
                            to relevant networks */}
                            Get{" "}
                            <span className="refersec2_head_sec2_span">
                              Referrals
                            </span>
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            With the help of our referral partners, accelerate
                            your hiring process from months to days for finding
                            the ideal candidate.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "20px" }}
                    >
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                03
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec2"
                          >
                            Screening{" "}
                            <span className="refersec2_head_sec2_span">
                              and{" "}
                            </span>
                            Interviews
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            {/* We custom-build an audience on social media and
                            advertise your <span>job & the offered reward</span>{" "}
                            - not to candidates. */}
                            Our screening partners conduct a comprehensive
                            360-degree evaluation of candidates rating them
                            based on their actual skills and schedule
                            interviews.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                04
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec"
                          >
                            Hire & <span>Onboard</span>
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            {/* We custom-build an audience on social media and
                            advertise your <span>job & the offered reward</span>{" "}
                            - not to candidates. */}
                            Based on the curated scorecard, make an informed
                            decision and successfully complete your hiring.
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 refer2_container">
              <div className="refer2_inside_cont2">
                <div className="refer2_inside2_cont">

                  {/* <div style={{}}>
                    <div className="compan_inside_sec">
                      <h2 className="partners_txt1">For Partners</h2>
                      <h2 className="partners_txt2">Login</h2>
                      <Button
                        variant="outlined"
                        className="apply-now-btn cursor-pointerf"
                        onClick={() => navigate("/jobs-list")}
                        style={{
                          background: "#ffffff",
                          color: "#012480",
                          fontFamily: "Plus Jakarta Display,sans-serif",
                          fontWeight: "400",
                          fontSize: "16px",
                          marginTop: "10px",
                          marginRight: "23px",
                          borderColor: "#ffffff",
                          borderRadius: "24px",
                          marginBottom: "15px !important",
                        }}
                      >
                        Refer Now{" "}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </Button>
                    </div>
                  </div> */}

          <div style={{textAlign:"center",height:"100%"}}>
                  <img src={jobss} style={{height:"70%"}}/>
                  <h2 className="partners_txt1 mt-3">For Partners Login</h2>
                  <Button
                        variant="outlined"
                        className="apply-now-btn cursor-pointerf"
                        onClick={() =>
                          window.open(
                            "https://app.zepul.com/login?user_type=CA",
                            "_blank"
                          )
                        }
                        style={{
                          background: "#ffffff",
                          color: "#012480",
                          fontFamily: "Inter",
                          fontWeight: "400",
                          fontSize: "14px",
                          marginTop: "10px",
                          marginRight: "23px",
                          borderColor: "#ffffff",
                          borderRadius: "24px",
                          marginBottom: "15px !important",
                        }}
                      >
                        Refer Now{" "}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </Button>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EmployerPage2;
