import React, { useEffect, useState } from "react";
import { getAllKeySkills } from "../helpers/dataFetcher/jobAttributes/keyskills";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/joy/Chip";
import Close from "@mui/icons-material/Close";
import Button from "@mui/joy/Button";
import ReactSelect from "react-select";
import "../assets/css/Skills/skills.css";

function Skills(props) {
  const { userDetails } = props;
  const [inputFileds, setInputFields] = useState([]);
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  const check = ["seshu", "nerusu"];
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      test: inputFileds ? inputFileds : "",
    },
  });

  useEffect(() => {
    if (userDetails?.skill_details) {
      setInputFields([
        userDetails?.skill_details?.map(item => ({
          title: item.name,
        })),
      ]);
    }

    setSelected(
      userDetails?.skill_details?.map(item => ({
        label: item.name,
        value: item.name,
        id: item.id,
      }))
    );
  }, [userDetails?.skill_details]);

  const [options, setOptions] = useState();
  useEffect(() => {
    fetchAllSkills();
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    console.log(selected);
    const result = selected?.map(item => item.id);
    const finalResult = [...result];
    const { status } = await updateProfileNormal("skills", {
      update_type_to_send_email: "email",
      skills: finalResult,
      last_name: userDetails.last_name,
      first_name: userDetails.first_name,
      email: userDetails.email,
    });

    if (status === 200) {
      const names = selected?.map(item => {
        return { name: item.label, id: item.id };
      });

      const finalNames = [...names];

      props.setUserDetails({
        ...userDetails,
        is_active: true,
        skill_details: finalNames,
      });
      props.onHide();
    } else {
      setError(true);
    }
  };

  async function fetchAllSkills() {
    const { data: items } = await getAllKeySkills();
    const data = items.map(iterator => {
      return {
        id: iterator.id,
        label: iterator.name,
        value: iterator.name,
      };
    });
    setOptions(data);
  }
  async function closeModal() {
    props.onHide();
  }

  return (
    <Form onSubmit={e => onSubmit(e)}>
       <label htmlFor="languages">Skills</label>
      <div  className="mb-3 react_select_main_cont">
        <div className="react_select">
          <ReactSelect
            style={{ zIndex: "999"}}
            isMulti={true}
            options={options}
            value={selected}
            onChange={e => {
              setSelected(e);
              setError(false);
            }}
            classNamePrefix="select2-selection"
          />
        </div>
        {error ? (
          <span style={{ color: "red" }}>Please enter Skills</span>
        ) : null}
      </div>
      {/* <Button
        variant="outlined"
        color="primary"
        className=" mt-4"
        type="submit"
        style={{
          margin: "3px",
          background: "rgb(236, 236, 236)",
          color: "rgb(8, 23, 83)",
          border: 0,
        }}
      >
        Save
      </Button> */}

      <div className="d-flex justify-content-end submit-button mt-2">
        <div className="d-flex justify-content-end">
          <Button
            variant="outlined"
            color="primary"
            className=" mt-4"
            type="submit"
            onClick={() => addLanguages()}
            style={{
              margin: "3px",
              background: "rgb(236, 236, 236)",
              color: "rgb(8, 23, 83)",
              border: 0,
            }}
          >
            Save
          </Button>
        </div>
        <Button
          variant="outlined"
          color="primary"
          className=" mt-4"
          type="submit"
          onClick={() => closeModal()}
          style={{
            margin: "3px",
            background: "rgb(236, 236, 236)",
            color: "rgb(8, 23, 83)",
            border: 0,
          }}
        >
          Close
        </Button>
      </div>
    </Form>
  );
}

export default Skills;
