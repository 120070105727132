import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";
import moment from "moment";
import { imageURL } from "../../Constants/commonURLS";
import Stack from "@mui/material/Stack";
import EarnImage from "../../assets/images/earnImage.svg";
import getSymbolFromCurrency from "currency-symbol-map";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Card from "@mui/joy/Card";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import CardOverflow from "@mui/joy/CardOverflow";
// import Divider from "@mui/joy/Divider";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import JobInsights from "./JobInsights";
import SkillsSection from "./SkillsSection";
import JobInfoDescription from "./JobInfoDescription";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/joy/Chip";
import SkeletionChipsLoader from "../../Loader/SkeletionChipsLoader";

function JobDrawChatDescription(props) {

  const [ReferStatus, setReferStatus] = useState({
    modal: "",
    type: "",
    action: "",
    modalType: "",
  });

  const [referModal, setReferModal] = useState({
    modal: "",
    purpose: "",
    modalType: "",
  });
  const user = useSelector(state => state.User.user);

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
console.log(props?.jobDetails?.id,"hanshu")
  return (
    <>
      <section className="jobdraw_desc_section mt-2">
        <div className={`jobbox ${windowWidth <= 992 ? "hideJobBox" : ""}`}>
          <Box sx={{ flexGrow: 1, marginLeft: "20px" }}>
            <Grid container spacing={2}>
              <Grid xs={12} lg={11} md={11} sm={12} spacing={1}>
                <Box
                  sx={{
                    border: "0.5px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                  }}
                >
                  <div
                    className="profile_category_desc pb-0 gap-0"
                    id="job_category_header"
                  >
                     <KeyboardArrowLeftIcon  
                       className={`jobArrow ${windowWidth <= 992 ? 'hide' : ''}`}
                       id="jobchaticon"
                       style={{ display: windowWidth <= 992 ? 'block' : 'none' }}
                        onClick={e => {
                          e.preventDefault();
                          $(".jobbox").removeClass("showbox");
                          if (windowWidth <= 992) {
                            $(".jobbox").addClass("hideJobBox");
                          }
                        }}/>
                    <h2 className="job_desc_text_1 pt-2">
                     
                      Job Description
                    </h2>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "4px",
                        padding: "5px",
                        margin: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        <Grid lg={12}>
                          <Box
                            sx={{
                              // border: "0.5px solid rgba(0, 0, 0, 0.1)",
                              borderRadius: "4px",
                              marginLeft: "8px",
                              flexGrow: 1,
                              marginTop: "8px",
                              minHeight: "20px",
                            }}
                          >
                            {/* <div
                    className="profile_category_desc pb-0 gap-0"
                    id="job_category_header"
                  >
              
                    <h2 className="job_desc_text_1 pt-2">Job Role Insights</h2>
                  </div> */}
                            <p className="profile_summ_txt requirement_text">
                              Job Role Insights
                            </p>
                          {props?.jobDetails?.id !== undefined?
                            <div
                              className="jobs_title_card pb-3 pt-3"
                              style={{ paddingLeft: "12px" }}
                            >
                              <Chip
                                variant="solid"
                                color="neutral"
                                size="sm"
                                style={{
                                  color: "#777AD6",
                                  background: "#F5ECFF",
                                }}
                              >
                                {props?.jobDetails?.job_type?.replace("_", " ")}
                              </Chip>

                              <Chip
                                variant="solid"
                                color="neutral"
                                size="sm"
                                // icon={<LocationOnIcon />}
                                style={{
                                  color: "#081753",
                                  background: "rgba(8, 23, 83, 0.07)",
                                }}
                              >
                                {props?.jobDetails?.location?.length <= 17
                                  ? props?.jobDetails?.location
                                  : props?.jobDetails?.location?.slice(0, 35) +
                                    ".."}
                              </Chip>

                              <Chip
                                variant="solid"
                                color="neutral"
                                size="sm"
                                // icon={<LocationOnIcon />}
                                style={{
                                  color: "#081753",
                                  background: "rgba(8, 23, 83, 0.07)",
                                }}
                              >
                                {props?.jobDetails?.min_exp}-
                                {props?.jobDetails?.max_exp}
                                &nbsp; Yrs
                              </Chip>
                              <Chip
                                variant="soft"
                                color="neutral"
                                size="sm"
                                style={{
                                  background: "#F2F8F6",
                                  color: "#07A996",
                                }}
                              >
                                {getSymbolFromCurrency(
                                  props?.jobDetails?.currency
                                )}
                                {Math.round(
                                  props?.jobDetails?.min_sal / 1000,
                                  2
                                )}
                                k-
                                {Math.round(
                                  props?.jobDetails?.max_sal / 1000,
                                  2
                                )}
                                k
                              </Chip>
                              <Chip
                                variant="soft"
                                color="neutral"
                                size="sm"
                                style={{
                                  background: "#F2F8F6",
                                  color: "#07A996",
                                }}
                              >
                                {props?.jobDetails?.work_mode?.replace("_", " ")}
                              </Chip>
                            </div>
                            :<SkeletionChipsLoader />}
                          </Box>
                        </Grid>
                        <Divider
                          orientation="horizontal"
                          sx={{
                            height: "auto !important",
                            borderWidth: "0.1px",
                            width: "100%",
                          }}
                        />
                        <Grid lg={12}>
                          <Box
                            sx={{
                              //   border: "0.5px solid rgba(0, 0, 0, 0.1)",
                              borderRadius: "4px",
                              marginLeft: "8px",
                              marginTop: "6px",
                              flexGrow: 1,
                              margin: "5px",
                              minHeight: "20px",
                            }}
                          >
                            {/* <div
                          className="profile_category_desc pb-0 gap-0"
                          id="job_category_header"
                        >
                          <h2 className="job_desc_text_1 pt-2">Skills</h2>
                        </div> */}
                            <p className="profile_summ_txt requirement_text">
                              Skills
                            </p>

                            <div className="insights_sec pt-3">
                              {props?.jobDetails?.key_skills_details?.length >=
                              0 ? (
                                <div className="jobs_title_card pb-3">
                                  {props?.jobDetails?.key_skills_details?.map(
                                    each => (
                                      <Chip
                                        variant="solid"
                                        color="neutral"
                                        size="sm"
                                        // icon={<LocationOnIcon />}
                                        style={{
                                          color: "#081753",
                                          background: "rgba(8, 23, 83, 0.07)",
                                        }}
                                        key={each?.id}
                                      >
                                        {each?.name}
                                      </Chip>
                                    )
                                  )}
                                </div>
                              ) : (
                                "No data Found"
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Divider
                          orientation="horizontal"
                          sx={{
                            height: "auto !important",
                            borderWidth: "0.1px",
                            width: "100%",
                          }}
                        />
                      </div>

                      <p className="profile_summ_txt requirement_text">
                        Job brief
                      </p>
                      {/* <p className="job_desc_content">
                        We are seeking a Software Engineer to execute the full
                        lifecycle of the product development, by programming
                        well-designed, efficient, and testable code that meets
                        specifications. Responsibilities Develop new
                        capabilities and re-architect parts of an existing
                        system. Take part in test-driven, iterative development,
                        prototyping, and reviews with a team of developers.
                        Design and code middle and back-end in a clean and
                        efficient way. Implement scalable and robust services.
                      </p> */}
                      <div
                        style={{
                          overflowY: "scroll",
                          padding: "5px",
                          margin: "10px",
                          minHeight: "199px",
                          maxHeight: "200px",
                        }}
                        className="zepulScroll"
                      >
                        <div
                          // className="job_desc_content"
                          className="zepulScroll"
                          dangerouslySetInnerHTML={{
                            __html: props.jobDetails?.job_desc,
                          }}
                        ></div>
                      </div>

                      <p className="profile_summ_txt requirement_text zepulScroll">
                        Requirements
                      </p>
                      {/* <p className="job_desc_content">
                        Requirements Bachelors degree in Software Engineering,
                        Information Technology or related field Between 1 and 3
                        years of experience in software engineering Experience
                        developing server-side web applications in Java
                        Experience developing Java Web Services with public API
                        (e.g. REST, SOAP).
                      </p> */}
                      <div
                        style={{
                          overflow: "auto",
                          padding: "5px",
                          margin: "10px",
                          minHeight: "199px",
                          maxHeight: "200px",
                        }}
                        className="zepulScroll"
                      >
                        <div
                          // className="job_desc_content"
                          dangerouslySetInnerHTML={{
                            __html: props?.jobDetails?.requirements,
                          }}
                        ></div>
                      </div>
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      </section>
    </>
  );
}

export default JobDrawChatDescription;
