import React from "react";

function AboutUsResult() {
    return (
        <>
            <section className="section-box mt-90 mt-md-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                            <span className="text-lg text-brand wow animate__animated animate__fadeInUp">Online Marketing</span>
                            <h3 className="mt-20 mb-30 wow animate__animated animate__fadeInUp">Committed to top quality and
                                results</h3>
                            <p className="mb-20 wow animate__animated animate__fadeInUp">Proin ullamcorper pretium orci. Donec
                                necscele risque leo. Nam massa dolor imperdiet neccon sequata congue idsem. Maecenas
                                malesuada faucibus finibus. </p>
                            <p className="mb-30 wow animate__animated animate__fadeInUp">Proin ullamcorper pretium orci. Donec
                                necscele risque leo. Nam massa dolor imperdiet neccon sequata congue idsem. Maecenas
                                malesuada faucibus finibus. </p>
                            <div className="mt-10 wow animate__animated animate__fadeInUp">
                                <span className="btn btn-default">Learn more</span>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-12 pl-200 d-none d-lg-block">
                            <div className="banner-imgs banner-imgs-about">
                                <img alt="Zepul" src="assets/imgs/page/about/banner-online-marketing.png"
                                    className="img-responsive main-banner shape-3" />
                                <span className="banner-sm-4"><img alt="Zepul" src="assets/imgs/banner/congratulation.svg"
                                    className="img-responsive shape-2" /></span>
                                <span className="banner-sm-5"><img alt="Zepul" src="assets/imgs/banner/web-dev.svg"
                                    className="img-responsive shape-1" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsResult;