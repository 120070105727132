import React from "react";
import AreHiring from "../../Reusable/AreHiring";
import { useNavigate } from "react-router-dom";

function EmployerOverview() {
    const navigate = useNavigate()

    return (
        <>
            <div className="sidebar-shadow">
                <div className="sidebar-heading">
                    <div className="avatar-sidebar">
                        <figure><img alt="zepul" src="assets/imgs/page/job-single/avatar-job.png" /></figure>
                        <div className="sidebar-info">
                            <span className="sidebar-company">AliStudio, Inc</span>
                            <span className="sidebar-website-text">alithemes.com</span>
                            <div className="dropdowm">
                                <button className="btn btn-dots btn-dots-abs-right dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
                                <ul className="dropdown-menu dropdown-menu-light">
                                    <li><span className="dropdown-item">Contact</span></li>
                                    <li><span className="dropdown-item">Bookmark</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-description mt-15">
                    We're looking to add more product designers to our growing teams.
                </div>
                <div className="text-start mt-20">
                    <span onClick={() => navigate("/refernow")} className="btn btn-default mr-10">Refer now</span>
                </div>
                <div className="sidebar-list-job">
                    <ul>
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-briefcase"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Job Type</span>
                                <strong className="small-heading">Full-time / Remote</strong>
                            </div>
                        </li>
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-marker"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Location</span>
                                <strong className="small-heading">Dallas, Texas<br />Remote Friendly</strong>
                            </div>
                        </li>
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-dollar"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Salary</span>
                                <strong className="small-heading">$35k - $45k</strong>
                            </div>
                        </li>
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-clock"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Date posted</span>
                                <strong className="small-heading">1 hours ago</strong>
                            </div>
                        </li>
                        <li>
                            <div className="sidebar-icon-item"><i className="fi-rr-time-fast"></i></div>
                            <div className="sidebar-text-info">
                                <span className="text-description">Expiration date</span>
                                <strong className="small-heading">April 06, 2022</strong>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="sidebar-team-member pt-40">
                    <h6 className="small-heading">Team member</h6>
                    <div className="sidebar-list-member pb-0">
                        <ul>
                            <li>
                                <span>
                                    <figure>
                                        <img
                                            alt="jobhub"
                                            src="assets/imgs/page/job-single/avatar1.png"
                                            style={{ width: "50px" }}
                                        />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar2.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar3.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar4.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar5.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar6.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar7.png"
                                        style={{ width: "50px" }}

                                    />
                                    </figure>
                                </span>
                            </li>
                            <li>
                                <span>
                                    <figure><img
                                        alt="jobhub"
                                        src="assets/imgs/page/job-single/avatar8.png"
                                        style={{ width: "50px" }}
                                    />
                                    </figure>
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar-team-member pt-40">
                    <h6 className="small-heading">Contact Info</h6>
                    <div className="info-address">
                        <span><i className="fi-rr-marker"></i> <span>Campbell Ave undefined Kent, Utah 53127
                            United
                            States</span></span>
                        <span><i className="fi-rr-headset"></i> <span>(+91) - 540-025-124553 </span></span>
                        <span><i className="fi-rr-paper-plane"></i> <span>contact@nestmart.com</span></span>
                        <span><i className="fi-rr-time-fast"></i> <span>10:00 - 18:00, Mon - Sat </span></span>
                    </div>
                </div>
            </div>
            <AreHiring />
        </>
    )
}
export default EmployerOverview;