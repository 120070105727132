import React, { useState, useEffect, useRef } from "react";
// import Avatar from "@mui/material/Avatar";
import "../../assets/css/chat/chat.css";
import { Col, Form, Input } from "reactstrap";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import SendIcon from "@mui/icons-material/Send";
import Picker from "emoji-picker-react";
import {
  findChatBasedOnId,
  getAllChats,
  initializeChat,
  sendMessage,
} from "../../helpers/dataFetcher/chats/chats";
import Avatar from "@mui/joy/Avatar";
import linkSvg from "../../assets/images/link.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles, propsToClassKey } from "@mui/styles";
import Chip from "@mui/material/Chip";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { styled } from "@mui/joy/styles";
import Sheet from "@mui/joy/Sheet";
import Grid from "@mui/joy/Grid";
import AttachFileIcon from "@mui/icons-material/AttachFile";
// import Badge from "@mui/joy/Badge";
import Badge from "@mui/material/Badge";
import ownerPic from "../../assets/images/owner.jpg";
import { toast } from "react-hot-toast";
import { convertintoStringDates, hoursToStandard } from "../../functions/date";
// import { IconButton } from "@mui/material";
import checkMouseDown from "../../hooks/mouseDown";
import { imageURL } from "../../Constants/commonURLS";
import { getPreSignedURLForMultipleUpload } from "../../helpers/dataFetcher/pre_signed_urls/multipleUpload";
import SocketConnection from "../../hooks/socket";
import NewSocketConnection from "../../hooks/NewSocketConnection";
import { each } from "@react-spring/shared";
import { PdfButton } from "../../assets/MUI/globalStyles";
import { SmallAvatar } from "../../assets/MUI/globalStyles";
import moment from "moment";
import NoDataFoundComponent from "../../Nodatafound/NoDataFoundReusable";
import { Stack } from "@mui/material";

const useStyles = makeStyles({
  status: {
    "& .css-myurn6-JoyBadge-badge": {
      backgroundColor: "#219653",
    },
  },
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "rgba(192, 223, 250, 0.4) !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "rgba(192, 223, 250, 0.4) !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },

  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
      "@media (max-width:576px)": {
        display: "none",
      },
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    lineHeight: "21px",
    color: "#000000 !important",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.5)!important",
  },
  refer: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.5)!important",
    lineHeight: "16.8px",
    textTransform: "capitalize",
  },
  referName: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
    textTransform: "capitalize",
  },
  chatReceiver: {
    display: "flex !important",
    alignItems: "center",
    gap: "4px",
    "& .css-fjifyr-MuiAvatar-root": {
      alignSelf: "baseline",
    },
  },
});

const Item = styled(Sheet)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "start",
  color: theme.vars.palette.text.tertiary,
}));

const successNotification = {
  color: "#219653",
  background: "rgba(39,174,96,0.36)",
  borderRadius: "50%",
  width: "18px",
  display: "grid",
  placeItems: "center",
  height: "18px",
};

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

function DemoChatMainDummy({
  receiverId,
  senderId,
  setShowPicker,
  showPicker,
  setText,
  text,
  selectedChat,
  setSelectedChat,
  setChat,
  setJobPostId,
  userJobPostId,
  selectedChannelData,
  userId,
  sendUserMessage,
  setAttachment,
  selectedChannelDetails,
  activeSocketUsersList,
  userStatus,

}) {
  const classes = useStyles();
  const pickerRef = useRef(null);
  const messageRef = useRef(null);
  const messageListRef = useRef(null);
  /* Menu card section code start */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  /* Menu card section code End */

  const onEmojiClick = event => {
    if (messageRef.current) {
      messageRef.current.focus();
    }
    setText(prevInput => prevInput + event.emoji);
    setShowPicker(false);
  };

  checkMouseDown({ pickerRef, setShowPicker });
  function moveToTop() {
    window.scrollTo(0, 0);
  }

  /*Require state start here @seshu */
  // const { sendMessage: sendSocketMessage } = SocketConnection();
  // const [attachement, setAttahement] = useState([]);
  // const [selectedMember, setSelectedMember] = useState(null);
  // const [message, setMessage] = useState('');
  // const [chatMessages, setChatMessages] = useState([]);

  // useEffect(()=>{
  // socket.onmessage = (event) => {
  //   const receivedMessage = JSON.parse(event.data);
  //   setChatMessages(prevMessages => [...prevMessages, receivedMessage]);
  // };

  //   return () => {
  //     // Clean up the socket connection
  //     socket.disconnect();
  //   };
  // },[])
  //    const sendMessage = () => {
  //   if (message.trim() !== '') {
  //     const payload = {
  //       jobId: jobPostId,
  //       memberId: selectedMember,
  //       message: message
  //     };
  //     socket.send(JSON.stringify(payload));
  //     setMessage('');
  //   }
  // };

  // const handleSendMessage = async(type)=>{
  //   let status;
  //   if (attachement?.length > 0) {
  //     const { status: statusCode } = await sendMessage(
  //       receiverId.id,
  //       attachement[0],
  //       "ATTACHMENT"
  //     );
  //     status = statusCode;
  //   } else if (text !== "") {
  //     const { status: statusCode } = await sendMessage(
  //       receiverId.id,
  //       text,
  //       type
  //     );
  //     status = statusCode;
  //   }
  //   if (status === 200) {
  //     if (
  //       receiverId?.socketId?.connectionId !== undefined ||
  //       receiverId?.socketId?.connectionId !== null ||
  //       receiverId?.socketId?.connectionId !== ""
  //     )
  //       sendSocketMessage(
  //         JSON.stringify({
  //           type: "chats",
  //           data: {
  //             chat_id: receiverId?.chat_id,
  //           },
  //           reciever_connectionId: receiverId?.socketId?.connectionId,
  //         })
  //       );
  //     setText("");
  //     setAttahement([]);
  //     if (receiverId?.chat_id !== undefined) {
  //       const { data } = await findChatBasedOnId(receiverId?.chat_id);
  //       data?.data?.sort((a, b) => {
  //         return new Date(a.createdAt) - new Date(b.createdAt);
  //       });
  //       setSelectedChat(data?.data);
  //       const { data: chats } = await getAllChats();
  //       setChat(chats?.data);
  //     }
  // }
  /* Required start End  here @seshu */

  /* New websocket connection code testing start here @seshu */
  // const { connectionStatus, sendMessage, lastMessage, readyState } =
  //   NewSocketConnection();

  // const handleSendMessage = message => {
  //   // Send a message using the sendMessage function
  //   sendMessage(message);
  // };



  /* New websocket connection code testing End here @seshu */

  /* Send Message code start Here @seshu */
  const handleSendMessage = async text => {
    sendUserMessage();
  };

  /* Send Message code End Here @seshu */

  /* Attachments like images & pdf code start here @seshu */
  const imageTypes = [".png", ".jpg", ".jpeg", ".webp"];

  /* Attachments like images & pdf code End here @seshu */

  /* Scroll down code start here @seshu */
  useEffect(() => {
    scrollToBottom();
  }, []);
  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };
  /* Scroll down code End here @seshu */

  /* Display Message based on type code start here*/
  function displayMessage(msg) {
    if (msg.message.endsWith("pdf")) {
      return (
        <PdfButton
          variant=""
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.target = "_blank";
            link.click();
          }}
          startIcon={<DownloadIcon />}
        >
          {msg.message.slice(4)}
        </PdfButton>
      );
    } else if (msg.message.endsWith("png") || msg.message.endsWith("jpeg")) {
      return (
        <img
          src={`${imageURL}${msg.message}`}
          style={{ height: "150px", width: "150px" }}
        />
      );
    } else if (msg.message.endsWith("svg")) {
      return (
        <img
          src={`${imageURL}${msg.message}`}
          style={{ height: "150px", width: "150px" }}
        />
      );
    } else if (msg.message.endsWith("json")) {
      return (
        <PdfButton
          startIcon={<DownloadIcon />}
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.click();
          }}
        >
          {msg.message}
        </PdfButton>
      );
    } else if (msg.message.endsWith("docx")) {
      return (
        <PdfButton
          startIcon={<DownloadIcon />}
          onClick={() => {
            const link = document.createElement("a");
            link.download = `${imageURL}${msg.message}`;
            link.href = `${imageURL}${msg.message}`;
            link.click();
          }}
        >
          {msg.message}
        </PdfButton>
      );
    } else {
      // return <><p><p style={{display:"flex",flexDirection:"row",width:"100%",wordWrap:"break-word"}}>{msg.message}<span><SmallAvatar /></span></p></p></>
      return (
        <>
          <p>{msg.message}</p>
        </>
      );
      // if(msg?.receiver_id === userId){
      //    return <><span><SmallAvatar src={
      //     selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
      //       ? `${imageURL}${selectedChannelDetails?.ucc_receiver_id?.image}`
      //       : `${imageURL}${selectedChannelDetails?.ucc_sender_id?.image}`
      //   }>{
      //     selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
      //       ? selectedChannelDetails?.ucc_receiver_id.first_name.charAt(
      //           0,
      //           1
      //         ) +
      //         selectedChannelDetails?.ucc_receiver_id.last_name.charAt(
      //           0,
      //           1
      //         )
      //       : selectedChannelDetails?.ucc_sender_id.first_name.charAt(
      //           0,
      //           1
      //         ) +
      //         selectedChannelDetails?.ucc_sender_id.last_name.charAt(
      //           0,
      //           1
      //         )
      //   }</SmallAvatar></span><p><span style={{display:"flex",flexDirection:"row",width:"100%",wordWrap:"break-word"}}>{msg.message}</span></p></>

      // }else {
      //   return  <><p>{msg.message}</p></>;
      // }
    }
  }
  /* Display Message based on type code end here*/

  /* Scroll to bottom code start here */
  useEffect(() => {
    const scrollToBottom = () => {
      if (messageListRef.current) {
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    };

    scrollToBottom();
  }, [selectedChannelData]);
  /* Scroll to bottom code End here */

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="chatbox">
        {/* <div className={`chatbox ${windowWidth <= 992 ? 'hideJobBox' : ''}`}> */}
        <div className="chat_main_box_cont">
          <div className="row user_details">
            <ListItemButton alignItems="flex-start">
              <span
                className="chat-icon"
                style={{ paddingTop: "10px" }}
                onClick={e => {
                  e.preventDefault();
                  $(".chatbox").removeClass("showbox");
                  // if (windowWidth <= 992) {
                  //   $(".chatbox").addClass("hideJobBox");
                  // }
                }}
              >
                <KeyboardArrowLeftIcon />
              </span>
              <Badge
                badgeInset="14%"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                className={classes.status}
              >
                <Avatar
                  alt={
                    selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? selectedChannelDetails?.ucc_receiver_id.first_name.charAt(
                          0,
                          1
                        ) +
                        selectedChannelDetails?.ucc_receiver_id.last_name.charAt(
                          0,
                          1
                        )
                      : selectedChannelDetails?.ucc_sender_id.first_name.charAt(
                          0,
                          1
                        ) +
                        selectedChannelDetails?.ucc_sender_id.last_name.charAt(
                          0,
                          1
                        )
                  }
                  src={
                    selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? `${imageURL}${selectedChannelDetails?.ucc_receiver_id?.image}`
                      : `${imageURL}${selectedChannelDetails?.ucc_sender_id?.image}`
                  }
                  className={classes.avatar1}
                  sx={{
                    background: "rgba(192, 223, 250, 0.4) !important",
                    color: "rgba(0, 0, 0, 0.7) !important",
                  }}
                />
              </Badge>
              <div className="names_link_cont" style={{ paddingLeft: "10px" }}>
                <div className="d-flex flex-column">
                  <span className={classes.name}>
                    {selectedChannelDetails?.sender_type == "REFERAL_PARTNER"
                      ? selectedChannelDetails?.ucc_receiver_id.first_name +
                        selectedChannelDetails?.ucc_receiver_id.last_name
                      : selectedChannelDetails?.ucc_sender_id.first_name +
                        selectedChannelDetails?.ucc_sender_id.last_name}
                    <span>
                      <Chip
                        label={
                          selectedChannelDetails?.sender_type ==
                          "REFERAL_PARTNER"
                            ? `${selectedChannelDetails?.ucc_receiver_id?.usertype.replace(
                                "_",
                                " "
                              )}`
                            : `${selectedChannelDetails?.ucc_sender_id?.usertype.replace(
                                "_",
                                " "
                              )}`
                        }
                        size="small"
                        // className={classes.chip}
                        sx={{
                          color: "#006064",
                          backgroundColor: "rgba(77, 208, 225, 0.5) !important",
                          fontWeight: "500 !important",
                          fontSize: "11px !important",
                        }}
                      />
                    </span>
                  </span>
                  <Stack direction={'row'} gap={0.9} alignItems={'center'}>
                    <Box
                      sx={{
                        ...successNotification,
                        width: "5px",
                        height: "5px",
                        background: !userStatus.length
                          ? "rgb(255 0 0 / 35%)"
                          : "rgba(39,174,96,0.36)",
                      }}
                    />
                    <Typography className={classes.msg}>
                      {userStatus.length > 0 ? "Online" : "Offline"}
                    </Typography>
                  </Stack>
                </div>
              </div>
            </ListItemButton>
          </div>

          <div className="msgs_cont" ref={messageListRef}>
            <div className="msg-body">
              <ul>
                {selectedChannelData?.length > 0 ? (
                  selectedChannelData?.map((each, index) => (
                    <>
                      {convertintoStringDates(
                        selectedChannelData[index - 1]
                      ) !== convertintoStringDates(each) ? (
                        <Divider>
                          <Chip
                            label={
                              moment(each?.createdAt).calendar({
                                sameDay: "[Today]",
                                nextDay: "[Tomorrow]",
                                nextWeek: "dddd",
                                lastDay: "[Yesterday]",
                                lastWeek: "DD/MM/YYYY",
                                sameElse: "DD/MM/YYYY",
                              })
                            }
                            size="small"
                          />
                        </Divider>
                      ) : (
                        <></>
                      )}

                      <li
                        className={`${
                          // userId === each.ucc_receiver_id ? "sender" : "repaly"
                          userId === each.sender_id
                            ? "repaly"
                            : `sender  ${classes.chatReceiver}`
                        }`}
                      >
                        {/* {displayMessage(each.message)} */}
                        {/* {displayMessage(each)} */}
                        {userId !== each.sender_id && (
                          <SmallAvatar
                            src={
                              selectedChannelDetails?.sender_type ==
                              "REFERAL_PARTNER"
                                ? `${imageURL}${selectedChannelDetails?.ucc_receiver_id?.image}`
                                : `${imageURL}${selectedChannelDetails?.ucc_sender_id?.image}`
                            }
                            sx={{
                              background: "rgba(192, 223, 250, 0.4) !important",
                              color: "rgba(0, 0, 0, 0.7) !important",
                              alignSelf: "start",
                            }}
                          >
                            {selectedChannelDetails?.sender_type ==
                            "REFERAL_PARTNER"
                              ? selectedChannelDetails?.ucc_receiver_id.first_name.charAt(
                                  0,
                                  1
                                )
                              : selectedChannelDetails?.ucc_sender_id.first_name.charAt(
                                  0,
                                  1
                                )}
                          </SmallAvatar>
                        )}
                        <div>
                          <>{displayMessage(each)}</>
                          <span className="time">
                            {moment(each.createdAt).format("LT")}
                          </span>
                        </div>
                      </li>
                    </>
                  ))
                ) : (
                  <NoDataFoundComponent />
                )}

                {/* <li className="repaly">
                  <p> Hey, Are you there? </p>
                  <span className="time">10:16 am</span>
                </li>
                <Divider>
                  <Chip label="4/4/2023" />
                </Divider>

                <li className="repaly">
                  <p> yes, tell me</p>
                  <span className="time">10:36 am</span>
                </li>
                <li className="repaly">
                  <p>yes... on it</p>
                  <span className="time">Just now</span>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="emoji_picker">
            {showPicker && (
              <div ref={pickerRef}>
                <Picker
                  pickerStyle={{ width: "100%" }}
                  onEmojiClick={onEmojiClick}
                />
              </div>
            )}
          </div>

          <div className="msgs_sent_cont">
            <div
              className="message_action_container"
              style={{ marginLeft: "10px" }}
            >
              <div
                className="message_action_container input_div"
                style={{ marginLeft: "10px" }}
              >
                <input
                  type="text"
                  className="input_ele chat_input_ele"
                  style={{ fontSize: "14px", opacity: "none" }}
                  placeholder="Enter your message here..."
                  ref={messageRef}
                  // value={message}
                  // onChange={e => setMessage(e.target.value)}
                  value={text}
                  onChange={e => setText(e.target.value)}
                  onKeyDown={e => {
                    if (e.code === "Enter" && e.target.value !== "") {
                      (async () => {
                        handleSendMessage(text);
                      })();
                    }
                  }}
                />
              </div>

              <div style={{ display: "flex", gap: "15px" }}>
                <div
                  style={{ marginRight: "4%" }}
                  onClick={async () => {
                    const input = document.createElement("input");
                    input.type = "file";
                    // input.multiple = true;
                    input.accept =
                      ".jpeg, .jpg, .png, .gif, .pdf, .docx, .json";
                    input.click();
                    let url = [];
                    input.addEventListener("change", async () => {
                      let urlList = [];
                      const files = [...input.files];
                      files?.forEach(async item => {
                        const image = await getPreSignedURLForMultipleUpload(
                          item
                        );
                        url.push(image);
                        setAttachment(image);
                        sendUserMessage(image);
                      });
                      // sendUserMessage();
                    });
                  }}
                >
                  <i className="bx bx-file"></i>
                </div>
                <div>
                  <i
                    className="bx bxs-face"
                    onClick={() => setShowPicker(true)}
                  ></i>
                </div>
                <SendIcon
                  fontSize="small"
                  onClick={() => handleSendMessage()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoChatMainDummy;
