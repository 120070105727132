import React from 'react';
import './Loader.css';
import { Spin } from 'antd';

function ZepulLoader(){
    return (
        <>
         <div className="main_loader_cont">
        <Spin id="main_loader_cont_dot" size="large"></Spin>
      </div>
        </>
    )
}

export default ZepulLoader;