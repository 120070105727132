import EmployerRegistration from "../Authentication/EmployerLogins/EmployerLogin";
import ScreeningPartner from "../Authentication/EmployerLogins/SereeningPartner";
import ForgetPassword from "../Authentication/FogetPassword";
import ForgotPassword from "../Authentication/ForgotPassword";
import ChangePassword from "../Authentication/ChangePassword";
import ChangeEmail from "../Authentication/ChangeEmail";
import ChangePhoneNumber from "../Authentication/ChangePhoneNumber";
import Login from "../Authentication/Login";
import Logout from "../Authentication/Logout";
import Registration from "../Authentication/Registration";
import ResetPassword from "../Authentication/ResetPassword";
import AboutUs from "../pages/AboutUs/AboutUsIndex";
import ProfilePage from "../pages/Accounts/AccountsIndex";
import AllJobs from "../pages/Accounts/AllJobs/AllJobs";
import AppliedJobs from "../pages/Accounts/AppliedJobs/AppliedJobs";
import DashBoardIndex from "../pages/Accounts/Dashboard/DashboardIndex";
import MyReferals from "../pages/Accounts/MyReferals/myReferal";
import ApplicationStatus from "../pages/ApplicationStatus/ApplicationStatus";
import BlogsListIndex from "../pages/Blogs/BlogsListIndex";
import BlogsSingleIndex from "../pages/Blogs/BlogsSingle/BlogsSingle";
import BrowseJobsList from "../pages/BrowseJobsList/Index";
import CandidateIndex from "../pages/Candidates/Candidates";
import CandidateSingle from "../pages/Candidates/CandidateSingle";
import Chat from "../pages/Chat";
import ContactUsIndex from "../pages/Contact-Us/ContactUsIndex";
import Employer from "../pages/Employers/EmployerSingle/Index";
import MainEmployerList from "../pages/Employers/List/Index";
import Faq from "../pages/Faq/Faq";
import HomeUI from "../pages/Home/HomeUI";
import InterviewsList from "../pages/interviewlist/InterviewsList";
import MainJobsList from "../pages/JobsList/Index";
import LeadershipBoard from "../pages/Leadershipboard/LeadershipBoard";
import MoreJobs from "../pages/MoreJobs/MoreJobs";
import MyEarning from "../pages/MyEarning/Index";
import DemoChat from "../pages/DemoChat/index";
import MyMoney from "../pages/MyMoney/Index";
import NotFound from "../pages/NotFound";
import Notifications from "../pages/Notifications/Notifications";
import Pricing from "../pages/Pricing/Pricing";
import ReferNow from "../pages/Refernow/ReferNow";
import ReferNowIndex from "../pages/ReferNowPage/ReferNowIndex";
import EmployersIndex from '../pages/EmployersPage/EmployersIndex'
import Settings from "../pages/settings";
import Successfull from "../pages/Successful/Successfull";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import MySavedJobs from "../pages/Accounts/SavedJobs/MySavedJobs";
import DemoChatSidebar from "../pages/DemoChat/DemoChatSidebar";
import ChatChannelIndex from "../pages/DemoChat/DemoChatChannelIndex";

export const routes = [
  // { path: "/jobs-list", Component: <MainJobsList /> },
  // { path: "/job/:id", Component: <BrowseJobsList /> },
  { path: "/profile", Component: <ProfilePage /> },
  { path: "/candidate", Component: <CandidateIndex /> },
  { path: "/candidate-single", Component: <CandidateSingle /> },
  // { path: "/blogs", Component: <BlogsListIndex /> },
  { path: "/blogs/:id", Component: <BlogsSingleIndex /> },
  // { path: "/about-us", Component: <AboutUs /> },
  { path: "/dashboard", Component: <DashBoardIndex /> },
  { path: "/refered-jobs", Component: <AllJobs /> },
  { path: "/my-referals", Component: <MyReferals /> },
  { path: "/applied-jobs", Component: <AppliedJobs /> },
  { path: "/saved-jobs", Component: <AppliedJobs /> },
  { path: "/chat", Component: <Chat /> },
  { path: "/application-status", Component: <ApplicationStatus /> },
  // { path: "/refernow", Component: <ReferNow /> },
  // { path: "/refernow", Component: <ReferNowIndex /> },
  // { path: "/employerpage", Component: <EmployersIndex /> },
  { path: "/screening-partner", Component: <ScreeningPartner /> },
  { path: "/employer-registration", Component: <EmployerRegistration /> },
  // { path: "/more-fn-areas", Component: <MoreJobs /> },
  { path: "/notification", Component: <Notifications /> },
  { path: "/pricing", Component: <Pricing /> },
  { path: "/interviews", Component: <InterviewsList /> },
  { path: "/faq", Component: <Faq /> },
  { path: "/myearning", Component: <MyEarning /> },
  { path: "/settings", Component: <Settings /> },
  { path: "/mymoney", Component: <MyMoney /> },
  { path: "/leadership-board", Component: <LeadershipBoard /> },
  { path: "/success", Component: <Successfull /> },
  // { path: "/employer-single-list/:id", Component: <Employer /> },
  { path: "/mymoney", Component: <MyMoney /> },
  { path: "/demochat", Component: <DemoChat /> },
  // {path:"/jobchat/:id1/:id2",Component:<ChatChannelIndex />},
  {path:"/jobchat",Component:<ChatChannelIndex />},
  { path: "/my-savedjobs", Component: <MySavedJobs /> },
  { path: "*", Component: <NotFound /> },
];

export const publicRoutes = [
  { path: "/", Component: <HomeUI /> },
  { path: "/employer-list", Component: <MainEmployerList /> },
  { path: "/linkedin", Component: <LinkedInCallback /> },
  { path: "/jobs-list", Component: <MainJobsList /> },
  { path: "/login", Component: <Login /> },
  { path: "/forget-password", Component: <ForgetPassword /> },
  { path: "/change-password", Component: <ChangePassword /> },
  { path: "/change-email", Component: <ChangeEmail /> },
  { path: "/change-phonenumber", Component: <ChangePhoneNumber /> },
  { path: "/reset-password", Component: <ResetPassword /> },
  { path: "/logout", Component: <Logout /> },
  { path: "/registration", Component: <Registration /> },
  { path: "/contact-us", Component: <ContactUsIndex /> },
  { path: "/about-us", Component: <AboutUs /> },
  { path: "/refernow", Component: <ReferNowIndex /> },
  { path: "/employerpage", Component: <EmployersIndex /> },
  { path: "/blogs", Component: <BlogsListIndex /> },
  // { path: "/job/:id", Component: <BrowseJobsList /> },
  // { path: "/employer-single-list/:id", Component: <Employer /> },
  { path: "/job", Component: <BrowseJobsList /> },
  { path: "/employer-single-list", Component: <Employer /> },
  { path: "/more-fn-areas", Component: <MoreJobs /> },
];
