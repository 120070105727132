import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { imageURL } from "../../../Constants/commonURLS";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
// import Select, { selectClasses } from "@mui/joy/Select";
// import Option from "@mui/joy/Option";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {styleObj} from '../../../assets/MUI/useStyles';
import {DataGridStyles} from '../../../assets/MUI/useStyles';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Pagination from "@mui/material/Pagination";
import {globalTheme} from '../../../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

import "./table.css";



function ReferMeTable({ referData,page, handleChangePage,setSearch}) {
  const [rows, setRows] = useState(referData);
  const classes = DataGridStyles();
  const theme = useTheme();

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [searchText, setSearchText] = useState("");

  const [age, setAge] = useState("");

  const handleChange = event => {
    setAge(event.target.value);
  };

 

// const handleSearchTextChange = event => {
//   const value = event.target.value;
//   setSearchText(value);
  
//   if (value.trim() !== "") {
//     const filteredRows = referData.filter(
//       item =>
//         item?.company_details?.company?.company_name
//           .toLowerCase()
//           .includes(value.toLowerCase()) ||
//         item.job_post_in_user_job_post?.job_title
//           .toLowerCase()
//           .includes(value.toLowerCase()) ||
//         item.location.toLowerCase().includes(value.toLowerCase()) ||
//         item.job_applicant?.email
//           .toLowerCase()
//           .includes(value.toLowerCase())
//     );
//     setRows(filteredRows);
//   } else {
//     setRows(referData);
//   }
// };

const handleSearchTextChange = event => {
  const value = event.target.value;
  setSearchText(value);
  if (value.trim() !== "") {
    const filteredRows = referData?.filter(item => {
      const companyName =
        item?.company_details?.company?.company_name?.toLowerCase() || "";
      const jobTitle =
        item.job_post_in_user_job_post?.job_title?.toLowerCase() || "";
      const location = item.location?.toLowerCase() || "";
      const email = item.job_applicant?.email?.toLowerCase() || "";

      return (
        companyName.includes(value.toLowerCase()) ||
        jobTitle.includes(value.toLowerCase()) ||
        location.includes(value.toLowerCase()) ||
        email.includes(value.toLowerCase())
      );
    });

     /* search text checking code start here @seshu*/
    //  if(filteredRows.length < 1){
    //   setSearch(value)
    //  }
    //  else if(filteredRows.length > 0){
    //   setRows(filteredRows);
    //  }else {
    //   setRows(filteredRows);
    //  }
      /* search text checking code End here @seshu*/
      
    setRows(filteredRows);
  } else {
    setRows(referData);
  }
};


  const rowsData = rows.map((item, index) => {
    return {
      id: index,
      Image: `${imageURL}${item.company_details?.company?.company_image}`,
      companyName: `${item?.company_details?.company?.company_name}`,
      jobTitle: `${item.job_post_in_user_job_post?.job_title}`,
      location: `${item.location}`,
      Date: `${item?.createdAt}`,
      referedBy: `${item.job_applicant?.email}`,
      ctc: `${item.job_applicant.ctc?item.job_applicant.ctc:"--"}`,
      status: `${item?.job_post_pipeline_stage?.stage_name}`,
    };
  });

  const columns = [
    {
      field: "Image",
      headerName: "Image",
      // headerClassName: "column_styles",
      headerClassName: classes.headerCellStyles,
      flex: 1,
      minWidth: 100,
      renderCell: params => (
        <img
          src={params.value}
          alt="avatar"
          style={{ width: 30, borderRadius: "50%" }}
        />
      ),
    },

    {
      field: "companyName",
      headerClassName: classes.headerCellStyles,
      style: { color: "red" },
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "jobTitle",
      headerClassName: classes.headerCellStyles,
      headerName: "Job Title",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "location",
      headerClassName: classes.headerCellStyles,
      headerName: "Location",
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "Date",
      headerClassName: classes.headerCellStyles,
      headerName: "Date",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        <span style={styleObj}>{value.slice(0, 10)}</span>
      ),
    },
    {
      field: "referedBy",
      headerClassName: classes.headerCellStyles,
      headerName: "Referred By",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "ctc",
      headerClassName: classes.headerCellStyles,
      headerName: "CTC",
      minWidth: 100,
      flex: 1,
      renderCell: ({ value }) => <span style={styleObj}>{value}</span>,
    },
    {
      field: "status",
      headerClassName: classes.headerCellStyles,
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: ({ value }) => (
        // <div
        //   style={{
        //     display: "inline-block",
        //     padding: "5px 10px",
        //     borderRadius: "8px",
        //     backgroundColor:
        //       value === "Pending" ? "orange" : "rgba(8, 23, 83, 0.2)",
        //     color: "#081753",
        //     fontWeight: 500,
        //     fontSize: "12px",
        //     fontFamily: "Inter,sans-serif",
        //     textTransform: "capitalize",
        //   }}
        // >
        //   {value}
        // </div>

        <div
        style={{
          display: "inline-block",
          padding: "5px 10px",
          borderRadius: "8px",
          backgroundColor:
            value === "Rejected"
              ? "#ffe9e9"
              : value === "Applied"
              ? "rgba(8, 23, 83, 0.2)"
              : value === "On boarded"
              ? "#d7f6dd"
              : value === "Technical Evalutation(External)"
              ? "#ffc6f6"
              : "rgba(8, 23, 83, 0.2)",
          color:
            value === "Rejected"
              ? "#a10e25"
              : value === "Applied"
              ? "#081753"
              : value === "On boarded"
              ? "#0f5d27"
              : value === "Technical Evalutation(External)"
              ? "#44004e"
              : "",
          fontWeight: 500,
          fontSize: "12px",
          fontFamily: "Inter,sans-serif",
          textTransform: "capitalize",
        }}
      >
        {value}
      </div>

      ),
    },
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    console.log("Selected page:", newPage);
  };

  return (
    <ThemeProvider theme={globalTheme}>
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ paddingBottom: "20px" }}
        width="80%"
      >
        <TextField
          //   id="search-text-field"

          placeholder="Search Here..."
          // className={classes.root}
          //   className="search_input"
          value={searchText}
          onChange={handleSearchTextChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon  />
              </InputAdornment>
            ),
          }}
        />
        {/* <Box>
          <Select
            className={`${classes.dropdown} select_dropdown`}
            defaultValue="Latest"
            indicator={<KeyboardArrowDown />}
            sx={{width:"auto",height:48,borderRadius:"8px"}}
          >
          
            <MenuItem value={"Latest"}>Latest</MenuItem>
          <MenuItem value={"Past 1 Month"}>Past 1 Month</MenuItem>
          <MenuItem value={"Past 3 Month"}>Past 3 Month</MenuItem>

          </Select>
        </Box> */}
      </Box>
      <div style={{ height: "65vh", width:"98%" }}>
        <DataGrid
          rows={rowsData}
          columns={columns}
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          hideFooterPagination
          // disableColumnMenu
        
          components={{
            Pagination: props => (
              <Pagination
                {...props}
                // color="primary"
                // count={Math.ceil(rowsData.length / rowsPerPage)}
                count={10}
                page={page}
                onChange={(event, newPage) => handleChangePage(newPage)}
                showFirstButton
                showLastButton
              sx={{
                "& .MuiPaginationItem-root": {
                  "&:hover": {
                    backgroundColor: "",
                    color: "",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "rgba(8, 23, 83, 0.2)",
                    color: "",
                    "&:hover": {
                      backgroundColor: "rgba(8, 23, 83, 0.2)",
                      color: "",
                    },
                  },
                },
              }}
              />
            ),
          }}

        />
      </div>
    </div>
    </ThemeProvider>
  );
}

export default ReferMeTable;
