import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import contactUs from "../../assets/images/infoImages/contactUs.jpg";
import contactUs1 from "../../assets/images/contactus1.png";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Textarea from "@mui/joy/Textarea";
import FormControl from "@mui/joy/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { OutlinedInput } from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";


import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

const useStyles = makeStyles(theme => ({
  root: {
    // "& .MuiInputBase-input": {
    //   height: "30px",
    //   borderRadius: "8px !important",
    //   border: "1px solid rgba(0, 0, 0, 0.2) !important",
    // },
    // "& .MuiFormLabel-root": {
    //   fontFamily: "Inter, sans-serif",
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   color: "rgba(0, 0, 0, 0.75)",
    // },
   
  },
  button: {
    color: '#FFFFFF !important',
    background: '#081753 !important',
    height: '49px !important',
    width:"auto",
    borderRadius: '24px !important',
    width:"147px",
    textTransform: 'capitalize !important',
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
  marginTop:"10px"
  }
}));

function GetInTouch() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const classes = useStyles();

  return (
    <>
      {/* <div className="container mt-md-30">
        <div className="row">
          <div className="col-xl-10 col-lg-12 m-auto">
            <section className="mb-50">
              <div className="row">
                <div className="col-xl-9 col-md-12 mx-auto">
                  <div className="contact-from-area padding-20-row-col">
                    <form
                      className="contact-form-style mt-80"
                      id="contact-form"
                      action="#"
                      method="post"
                    >
                      <div
                        className="row wow animate__animated animate__fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <input
                              name="name"
                              placeholder="First Name"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <input
                              name="name"
                              placeholder="Last Name"
                              type="text"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <input
                              name="telephone"
                              placeholder="Your Phone"
                              type="tel"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="input-style mb-20">
                            <input
                              name="email"
                              placeholder="Your Email"
                              type="email"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 text-center">
                          <div className="textarea-style mb-30">
                            <textarea
                              name="message"
                              placeholder="Message"
                            ></textarea>
                          </div>
                          <button
                            className="submit submit-auto-width"
                            type="submit"
                          >
                            Send message
                          </button>
                        </div>
                      </div>
                    </form>


                    <h5
                      className="text-blue text-center wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      Send Message
                    </h5>


                    <h2
                      className="section-title mt-15 mb-10 text-center wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      Drop Us a Line
                    </h2>
                    <p
                      className="text-muted mb-30 font-md text-center wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <div className="row mt-50">
                      <div
                        className="col-md-4 text-center wow animate__animated animate__fadeInUp"
                        data-wow-delay=".1s"
                      >
                        <img
                          src="assets/imgs/theme/icons/headset-color.svg"
                          alt=""
                        />
                        <p className="text-muted font-xs mb-10">Phone</p>
                        <p className="mb-0 font-lg">
                          + 48 654-430-309 <br />+ 1 6532-430-309
                        </p>
                      </div>
                      <div
                        className="col-md-4 mt-sm-30 text-center wow animate__animated animate__fadeInUp"
                        data-wow-delay=".3s"
                      >
                        <img
                          src="assets/imgs/theme/icons/marker-color.svg"
                          alt=""
                        />
                        <p className="text-muted font-xs mb-10">Email</p>
                        <p className="mb-0 font-lg">
                          contact@Zepul.com <br />
                          sales@Zepul.com
                        </p>
                      </div>
                      <div
                        className="col-md-4 mt-sm-30 text-center wow animate__animated animate__fadeInUp"
                        data-wow-delay=".5s"
                      >
                        <img
                          src="assets/imgs/theme/icons/plane-color.svg"
                          alt=""
                        />
                        <p className="text-muted font-xs mb-10">Address</p>
                        <p className="mb-0 font-lg">
                          11567 E Broadview Dr <br />
                          Colorado(CO), 80117
                        </p>
                      </div>
                    </div>

                    <p className="form-messege"></p>
                  </div>
                </div>
              </div>
              <h5
                className="text-blue text-center wow animate__animated animate__fadeInUp mt-4"
                data-wow-delay=".1s"
              >
                Get in Touch
              </h5>
              <h3
                className="section-title w-75 w-md-100 mb-50 mt-15 text-center mx-auto wow animate__animated animate__fadeInUp"
                data-wow-delay=".1s"
              >
                You are always welcome to visit our little studio
              </h3>
              <div className="row mb-60">
                <div
                  className="col-md-4 mb-4 mb-md-0 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h5 className="mb-15">Office</h5>
                  205 North Michigan Avenue, Suite 810
                  <br />
                  Chicago, 60601, USA
                  <br />
                  <abbr title="Phone">Phone:</abbr> (123) 456-7890
                  <br />
                  <abbr title="Email">Email: </abbr>contact@jubhub.com
                  <br />
                  <span className="btn btn-default btn-small font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up">
                    <i className="fi-rs-marker mr-5"></i>View map
                  </span>
                </div>
                <div
                  className="col-md-4 mb-4 mb-md-0 mt-sm-30 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".3s"
                >
                  <h5 className="mb-15">Studio</h5>
                  205 North Michigan Avenue, Suite 810
                  <br />
                  Chicago, 60601, USA
                  <br />
                  <abbr title="Phone">Phone:</abbr> (123) 456-7890
                  <br />
                  <abbr title="Email">Email: </abbr>contact@jubhub.com
                  <br />
                  <span className="btn btn-default btn-small font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up">
                    <i className="fi-rs-marker mr-5"></i>View map
                  </span>
                </div>
                <div
                  className="col-md-4 mt-sm-30 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".5s"
                >
                  <h5 className="mb-15">Shop</h5>
                  205 North Michigan Avenue, Suite 810
                  <br />
                  Chicago, 60601, USA
                  <br />
                  <abbr title="Phone">Phone:</abbr> (123) 456-7890
                  <br />
                  <abbr title="Email">Email: </abbr>contact@jubhub.com
                  <br />
                  <span className="btn btn-default btn-small font-weight-bold text-white mt-20 border-radius-5 btn-shadow-brand hover-up">
                    <i className="fi-rs-marker mr-5"></i>View map
                  </span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div> */}

      <section className="vh-50 mt-5">
        <div className="container-fluid h-custom">
          <div className="row d-flex  align-items-start ">
            <div className="col-md-6 col-lg-6 mt-4 contact_img">
              <div className="">
              <img
                src={contactUs1}
                // className="img-fluid"
                className="contact_img"
                alt="Sample image"
              />
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="contact_cont">
                <div className="mb-3">
                  <h4 className="contact_txt">Contact Us</h4>
                </div>
                <Box
                  component="form"
                  // onSubmit={handleLogin}
                  // noValidate
                  sx={{ mt: 1 }}
                >
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <TextField
                        placeholder="First Name"
                        className={classes.root}
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        error={errors.email ? true : false}
                        {...register("email", { required: true })}
                       
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <TextField
                        placeholder="Last Name"
                        className={classes.root}
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        error={errors.email ? true : false}
                        {...register("email", { required: true })}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <TextField
                        placeholder="Phone Number"
                        className={classes.root}
                        fullWidth
                        type="Number"
                        // autoComplete="email"
                        autoFocus
                        error={errors.email ? true : false}
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="col-md-6 mb-4">
                      <TextField
                        placeholder="Email"
                        className={classes.root}
                        fullWidth
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        autoFocus
                        error={errors.email ? true : false}
                        {...register("email", { required: true })}
                      />
                    </div>
                  </div>

                  <div className="form-outline">
                    {/* <textarea
                      className="form-control"
                      style={{ height: "134px",fontFamily:"Inter,sans-serif",fontSize:"16px",fontWeight:"400",color: "rgba(0, 0, 0, 0.75)",borderRadius:"8px"}}
                      placeholder="Message here.."
                      id="textAreaExample1"
                      rows="10"
                    ></textarea> */}
                     <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Type in here…"
                    style={{minHeight:"100px",maxHeight:"101px",borderRadius:"8px", borderColor: errors.description ? "red" : "#ced4da",}}
                  />
                 
                  </div>

                <div className="submit_btn_cont">
                  <Button variant="contained" className={classes.button}>
                    Submit
                  </Button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default GetInTouch;
