import React, { Component } from "react";
import { TabsStyle } from "./styles/TabsStyle";
import TabsContent from "./TabsContent";
import TabsItem from "./TabsItem";
import TabsList from "./TabsList";

class EarningCandidateReport extends Component {
    state = {
        selectedTab: 0
    };

    handleClick = itemIndex => {
        this.setState({
            selectedTab: itemIndex
        });
    };

    render() {
        let data = [
            { id: 1, title: "Active" },
            { id: 2, title: "Expired" }
        ];
        return (
            <TabsStyle>
                <TabsList>
                    {data.map((item, index) => (
                        <TabsItem
                            handleClick={this.handleClick.bind(this, index)}
                            className={this.state.selectedTab === index ? "active" : ""}
                            key={item.id}
                        >
                            {item.title}
                        </TabsItem>
                    ))}
                </TabsList>
                <div className="tabnt-wrapper">
                    <TabsContent tabId="0" activeTab={this.state.selectedTab}>
                        <div className="card border-0 p-4 table-bg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Clients</th>
                                        <th scope="col">Signup Date</th>
                                        <th scope="col">Validity Upto</th>
                                        <th scope="col">Validity Upto</th>
                                        <th scope="col">Total Job Post</th>
                                        <th scope="col">Total Hired</th>
                                        <th scope="col">Your commission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="w-100">
                                        <p className="text-center">No data</p>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </TabsContent>
                    <TabsContent tabId="1" activeTab={this.state.selectedTab}>
                        <div className="card border-0 p-4 table-bg">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">S.No</th>
                                        <th scope="col">Candidates</th>
                                        <th scope="col">Signup Date</th>
                                        <th scope="col">Validity Upto</th>
                                        <th scope="col">Your commission</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="w-100">
                                        <p className="text-center">No data</p>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </TabsContent>
                </div>
            </TabsStyle>
        );
    }
}

export default EarningCandidateReport;
