export const covertProfile = data => {
  return {
    id: data.id,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    user_name: data.user_name,
    usertype: data.usertype,
    phone_number: data.phone_number,
    languages: data.languages,
    location: data.location,
    city: data.city,
    state: data.state,
    country: data.country,
    dob: data.dob,
    postal_code: data.postal_code,
    gender: data.gender,
    about: data.about,
    profile_image: data.image,
    resume_url: data.resume,
    skill_details: data.skill_details,
    work_exp_details: data.work_exp_details,
    education_training_details: data.education_training_details,
    // job_role:data?.job_role[0]?data.job_role[0]:"",
    job_role:data.job_role?data.job_role[0]:"",
    notice_period:data?.notice_period?data.notice_period:"",
    current_ctc:data?.current_ctc?data.current_ctc:"",
    expected_ctc:data?.expected_ctc?data.expected_ctc:"",
    work_mode:data?.work_mode?data.work_mode:""
  };
};
