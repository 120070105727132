import React, { useState } from "react";
import "../RecentJobs.css";
import { Link, useNavigate } from "react-router-dom";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import getSymbolFromCurrency from "currency-symbol-map";
import Tooltip from "@mui/material/Tooltip";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Chip from "@mui/joy/Chip";
import noJobs from "../../../assets/images/no_jobs.svg";
import { ReferNowModal } from "../../JobsList/Alljobs/ReferModal";
// import IconButton from "@mui/joy/IconButton";
import { BookmarkBorderOutlined } from "@mui/icons-material";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import AspectRatio from "@mui/joy/AspectRatio";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import { imageURL } from "../../../Constants/commonURLS";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Remote from "../../../assets/images/remote.jpg";
// import EarnImage from "../../../assets/images/EarnIcon.svg";
import EarnImage from "../../../assets/images/earnImage.svg";
import meta from "../../../assets/images/meta-01.svg";
import workIcon from "../../../assets/images/work 2.svg";
import LocationIcon from "../../../assets/images/location 2.svg";
import peopleIcon from "../../../assets/images/people.svg";
import heartIcon from "../../../assets/images/heart.svg";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export const Design = props => {
  const navigate = useNavigate();
  const [heartOpen, setHeartOpen] = useState(true);
  const [referModal, setReferModal] = useState({
    modal: false,
    purpose: "",
    type: "",
    action: "",
    modalType: "",
  });
  props.jobsList?.sort(function (a, b) {
    return (
      new Date(b.createdAt.slice(0, 10).toLocaleString()) -
      new Date(a.createdAt.slice(0, 10).toLocaleString())
    );
  });

  // const abbreviateNumber = number => {
  //   const SI_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];
  //   const tier = Math.floor(Math.log10(Math.abs(number)) / 3);
  //   const scaledNumber = number / Math.pow(10, tier * 3);
  //   const suffix = SI_SYMBOLS[tier];
  //   return `${scaledNumber.toFixed(0)}${suffix}`;
  // };
  const abbreviateNumber = (number) => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  /*"G" represents "giga" and denotes a factor of 10^9 (1,000,000,000).
"T" represents "tera" and denotes a factor of 10^12 (1,000,000,000,000).
"P" represents "peta" and denotes a factor of 10^15 (1,000,000,000,000,000).
"E" represents "exa" and denotes a factor of 10^18 (1,000,000,000,000,000,000).*/
let token = localStorage.getItem("token")
  return (
    <>
      {props?.recentJobs?.length > 0 ? (
        // <div className="home-list-recent-jobs">
        //   {props?.recentJobs?.map(item => (
        //     <div key={item.id} className="mt-2 job_cards">
        //       <Card variant="outlined" className="job_card_height">
        //         <div className="d-flex jobs_title_card">
        //           <div className="commission_card">
        //           <AspectRatio ratio="1" sx={{ width: 90 }}>
        //             <img
        //               src={`${imageURL}${item?.image}`}
        //               // srcSet="https://images.unsplash.com/photo-1507833423370-a126b89d394b?auto=format&fit=crop&w=90&dpr=2 2x"
        //               loading="lazy"
        //               alt=""
        //             />
        //           </AspectRatio></div>

        //           <CardContent
        //             sx={{ px: 2 }}
        //             className="d-flex flex-column justify-content-between"
        //           >
        //             <Typography
        //               fontWeight="md"
        //               textColor="primary.plainColor"
        //               mb={0.5}
        //               style={{
        //                 cursor: "pointer",
        //                 textTransform: "capitalize",
        //                 fontSize: "16px",
        //                 fontWeight: "800",
        //               }}
        //               onClick={() => navigate(`../job/${item.id}`)}
        //             >
        //               {item.job_title}
        //             </Typography>
        //             <Typography
        //               level="body2"
        //               style={{ cursor: "pointer" }}
        //               onClick={() =>
        //                 navigate(
        //                   `../employer-single-list/${item?.company_user_id}`
        //                 )
        //               }
        //             >
        //               {item.company_name}
        //             </Typography>

        //             {/* <LocationOnOutlinedIcon className="jobs_card_specifications_icons" /> */}
        //             <Typography level="body3">
        //               {item?.work_mode === "Fully_remote"
        //                 ? `${item?.time_zone[0]} + ${
        //                     item?.time_zone.length - 1
        //                   } more`
        //                 : item?.location}
        //             </Typography>
        //             <Typography level="body3">
        //               Posted{" "}
        //               {Math.floor(
        //                 Math.abs(
        //                   new Date() -
        //                     new Date(
        //                       item.createdAt
        //                         .slice(0, 10)
        //                         .toLocaleString()
        //                         .replace(/-/g, "/")
        //                     )
        //                 ) /
        //                   (1000 * 3600 * 24)
        //               )}{" "}
        //               days ago
        //             </Typography>
        //           </CardContent>
        //           <Tooltip title={`${item.commission_rate}%`} placement="top">
        //             <div className="d-flex align-items-start commission_card">
        //               <span className="header_2">Commission: </span>
        //               <span className="jobs_specifications_items_curr pt-1">
        //                 {getSymbolFromCurrency(item.currency)}
        //               </span>
        //               <span className="pt-1">
        //                 {Math.round(
        //                   (item.max_sal * item.commission_rate) / 100 / 1000,
        //                   2
        //                 )}
        //                 k
        //               </span>
        //             </div>
        //           </Tooltip>
        //         </div>

        //         <div className="job_card_top_details_info_cont"></div>

        //         <div className="job_card_top_details_commision_cont">
        //           <div className="job_details"></div>
        //           <span className="mx-2"></span>
        //           <span className="card-job-top--price">
        //             {item.time}
        //             <span></span>
        //           </span>
        //         </div>
        //         <div className="d-flex align-items-center gap-2 jobs_title_card">
        //           <Chip variant="soft" color="neutral" size="sm">
        //             {item.job_type.replace("_", " ")}
        //           </Chip>

        //           <Chip variant="soft" color="neutral" size="sm">
        //             {item.work_mode.replace("_", " ")}
        //           </Chip>
        //           <Chip variant="soft" color="neutral" size="sm">
        //             {getSymbolFromCurrency(item.currency)}
        //             {Math.round(item.min_sal / 1000, 2)}k-
        //             {Math.round(item.max_sal / 1000, 2)}k
        //           </Chip>
        //           <Chip variant="soft" color="neutral" size="sm">
        //             {item.min_exp}-{item.max_exp}
        //             &nbsp; Yrs
        //           </Chip>
        //         </div>

        //         <div className="d-flex align-items-center gap-2 mt-2 jobs_title_card">
        //           {item.key_skill_details.slice(0, 2).map(each => (
        //             <Chip variant="soft" color="neutral" size="sm">
        //               {each.name}
        //             </Chip>
        //           ))}
        //           {item.key_skill_details.length > 2 ? (
        //             <Chip
        //               variant="soft"
        //               size="sm"
        //               onClick={() => navigate(`/job/${item.id}`)}
        //             >
        //               {" "}
        //               +{item.key_skill_details.length - 2} more
        //             </Chip>
        //           ) : (
        //             <></>
        //           )}
        //         </div>

        //         <div className="d-flex justify-content-between mt-4">
        //           <div className="d-flex gap-2 align-items-center">
        //             <Button
        //               variant="outlined"
        //               className="apply-now-btn cursor-pointerf"
        //               onClick={() =>
        //                 setReferModal({
        //                   modal: "center",
        //                   details: item,
        //                   purpose: "self",
        //                 })
        //               }
        //             >
        //               Apply Self
        //             </Button>
        //             <CssVarsProvider>
        //               <Button
        //                 variant="solid"
        //                 className="refer-now-btn cursor-pointer "
        //                 startDecorator={
        //                   <GroupAddIcon className="refer_btn_icon" />
        //                 }
        //                 onClick={() =>
        //                   setReferModal({
        //                     modal: "center",
        //                     details: item,
        //                     purpose: "refer",
        //                   })
        //                 }
        //               >
        //                 Refer Now
        //               </Button>
        //             </CssVarsProvider>
        //           </div>
        //           <IconButton
        //             color="primary"
        //             aria-label="save to bookmarks"
        //             component="label"
        //             className="apply-now-btn cursor-pointer mx-2"
        //           >
        //             <BookmarkBorderOutlined
        //               size="small"
        //               style={{ fontSize: "14px" }}
        //             />
        //           </IconButton>
        //         </div>
        //       </Card>
        //     </div>
        //   ))}
        // </div>
        <>
          <div className="latest_jobs_layout_card_section">
            {props?.recentJobs?.slice(0, 6).map((item, index) => {
              return (
                <div key={index}>
                  <div className="latest_jobs_main_card_section">
                    <div key={item.id} className="latest_jobs_inside_section">
                      <div className="title_section_1">
                        <div className="title_name_logo_sec">
                          <div
                            style={{
                              height: "44px",
                              width: "44px",
                              borderRadius: "50%",
                              border: "0.5px solid rgba(0, 0, 0, 0.25)",
                            }}
                          >
                            {/* <img
                              src={`${imageURL}${item?.image}`}
                              style={{ borderRadius: "50%" }}
                            /> */}

                            <Avatar
                              src={`${imageURL}${item?.image}`}
                              style={{ borderRadius: "50%",borderColor:"rgba(0, 0, 0, 0.25)",height:"100%",width:"100%"}}
                            >
                              {item?.company_name?.charAt(0)}
                            </Avatar>
                          </div>
                          <div className="title_sec_divider">
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "15px",
                                fontWeight: "600",
                                color: "#121212",
                                fontFamily: "Inter,sans-serif",
                              }}
                              onClick={() => navigate(`../job/${item.id}`)}
                            >
                              {/* {item.job_title} */}
                              {item?.job_title?.length <= 20 ? item.job_title : item?.job_title.slice(0, 18) + ".."}
                            </Typography>
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "#121212",
                                opacity: "50%",
                                fontFamily: "Inter,sans-serif",
                              }}
                              onClick={() =>
                                navigate(
                                  `../employer-single-list?id=${item?.company_user_id}`
                                )
                              }
                            >
                              {/* {item.company_name} */}
                              {item?.company_name?.length <= 20 ? item?.company_name : item?.company_name.slice(0, 18) + ".."}
                            </Typography>
                          </div>
                        </div>
                        <div className="commission_sec">
                          <Stack direction="row" spacing={2}>
                            <Avatar alt="Remy Sharp" src={EarnImage} />
                          </Stack>
                          <div className="title_sec_divider">
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              sx={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13px",
                                fontWeight: "500",
                                color: "#121212",
                                fontFamily: "Inter,sans-serif",
                              }}
                            >
                              Earn Upto
                            </Typography>
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              sx={{cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "Bold",
                              color: "#081753 !important",
                              // opacity: "50%",
                              fontFamily: "Inter,sans-serif",}}
                            >
                             {getSymbolFromCurrency(item.currency)}{" "}
                            
                              {abbreviateNumber(
                                (item.max_sal * item.commission_rate) / 100
                              )}
                            </Typography>
                          </div>
                        </div>
                      </div>

                      {/* <div className="keypoints_section_2">
                        <div className="keypoints_inside_section">
                          <div className="basic_details_section">
                            <img src={workIcon} className="icon_size" />
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "#121212",
                                opacity: "50%",
                                fontFamily: "Inter,sans-serif",
                                paddingLeft: "5px",
                              }}
                            >
                              {item.min_exp}-{item.max_exp}Yrs
                            </Typography>
                          </div>

                          <div
                            style={{
                              borderLeft: "1px",
                              borderStyle: "solid",
                              height: "22px",
                              top: "10",
                              fontWeight: "400",
                              color: "#000000",
                              opacity: "26%",
                              fontFamily: "Inter,sans-serif",
                              marginLeft:"12px"  
                            }}
                            className="hr_line"
                          ></div>

                          <div className="basic_details_section">
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "#121212",
                                opacity: "50%",
                                fontFamily: "Inter,sans-serif",
                                paddingLeft:"5px"
                              }}
                            >
                              {getSymbolFromCurrency(item.currency)}
                              {"  "}
                              {abbreviateNumber(item.min_sal)}-{" "}
                              {abbreviateNumber(item.max_sal)}
                            </Typography>
                          </div>

                          <div
                            style={{
                              borderLeft: "1px",
                              borderStyle: "solid",
                              height: "22px",
                              top: "10",
                              fontWeight: "400",
                              color: "#000000",
                              opacity: "26%",
                              fontFamily: "Inter,sans-serif",
                              marginLeft:"12px"  
                            }}
                            className="hr_line"
                          ></div>

                          <div className="basic_details_section">
                            <img src={LocationIcon} className="icon_size" />
                            <Typography
                              fontWeight="md"
                              textColor="primary.plainColor"
                              mb={0.5}
                              style={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                                fontSize: "13px",
                                fontWeight: "400",
                                color: "#121212",
                                opacity: "50%",
                                fontFamily: "Inter,sans-serif",
                                paddingLeft: "5px",
                              }}
                            >
                               {item?.location?.length <= 15 ? item?.location : item?.location.slice(0, 19) + ".."}
                            </Typography>
                          </div>
                        </div>
                      </div> */}


                    <div className="main_skills_sec">
                      <div className="job_card_top_details_commision_cont">
                        <div className="job_details"></div>
                        <span className="mx-2"></span>
                        <span className="card-job-top--price">
                          {item.time}
                          <span></span>
                        </span>
                      </div>
                      <div className="" style={{minHeight:"55px"}}>
                        <div  className="jobs_title_card"> 
                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          style={{ color: "#777AD6", background: "#F5ECFF" }}
                        >
                          {item.job_type.replace("_", " ")}
                        </Chip>

                        <Chip
                          variant="solid"
                          color="neutral"
                          size="sm"
                          // icon={<LocationOnIcon />}
                          style={{
                            color: "#081753",
                            background: "rgba(8, 23, 83, 0.07)",
                          }}
                        >
                          {item?.location?.length <= 17
                            ? item?.location
                            : item?.location.slice(0, 35) + ".."}
                        </Chip>
                        <Chip variant="soft" color="neutral" size="sm">
                          {item.min_exp}-{item.max_exp}Yrs
                          &nbsp; Yrs
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996" }}
                        >
                          {getSymbolFromCurrency(item.currency)}
                          {Math.round(item.min_sal / 1000, 2)}k-
                          {Math.round(item.max_sal / 1000, 2)}k
                        </Chip>
                        <Chip
                          variant="soft"
                          color="neutral"
                          size="sm"
                          style={{ background: "#F2F8F6", color: "#07A996" }}
                        >
                          {item.work_mode.replace("_", " ")}
                        </Chip>
                        </div>
                      </div>
                      
                    </div>
                      <div
                        className="skills_section"
                        style={{ paddingLeft: "10px",marginTop:"8px"}}
                      >
                        <ul
                          className="skill_main_section"
                          style={{
                            cursor: "pointer",
                            textTransform: "capitalize",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#121212",
                            opacity: "50%",
                            fontFamily: "Inter,sans-serif",
                          }}
                        >
                          {item.key_skill_details.slice(0, 3).map(each => (
                            <li
                              key={each.id}
                              className="typography-field"
                              style={{ paddingRight: "10px" }}
                            >
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>

                      

                      <div className="apply_section">
                        <div className="d-flex gap-2 align-items-center">
                          <CssVarsProvider>
                            <Button
                              style={{ backgroundColor: "#081753" }}
                              variant="solid"
                              className="refer-now-btn cursor-pointer "
                              startDecorator={
                                <GroupAddIcon className="refer_btn_icon" />
                              }
                              onClick={() =>
                                setReferModal({
                                  modal: "center",
                                  details: item,
                                  purpose: "refer",
                                })
                              }
                            >
                              Refer Now
                            </Button>
                          </CssVarsProvider>
                          <Button
                            variant="outlined"
                            className="apply-now-btn cursor-pointerf"
                            startDecorator={
                              <GroupAddIcon className="refer_btn_icon" />
                            }
                            style={{
                              color: "#000000",
                              border: "1px solid rgba(8, 23, 83, 0.1)",
                            }}
                            onClick={() =>
                              setReferModal({
                                modal: "center",
                                details: item,
                                purpose: "self",
                              })
                            }
                          >
                            Apply Self
                            {/* {token == null
                            ? "Apply Self"
                            : item?.is_candidate_applied_to_job_post == 0
                            ? "Apply Self"
                            : "Applied"} */}
                          </Button>
                          {/* <Button
                        variant="outlined"
                        className="apply-now-btn cursor-pointerf"
                        
                        style={{
                          color: "#000000",
                          border: "1px solid rgba(8, 23, 83, 0.1)",
                        }}
                     
                      >
                        {heartOpen ? (
                          <img src={heartIcon} style={{ height: "20px" }} />
                        ) : (
                          <FavoriteIcon />
                        )}
                      </Button> */}
                        </div>
                      </div>

                      <div style={{ paddingTop: "10px", padding: "10px" }}>
                        <div className="time_section">
                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingTop: "5px",
                            }}
                            className="date_posts"
                          >
                            Posted{" "}
                            <span
                              style={{
                                color: "black",
                                fontWeight: "700",
                                fontSize: "12px",
                              }}
                            >
                              {Math.floor(
                                Math.abs(
                                  new Date() -
                                    new Date(
                                      item.createdAt
                                        .slice(0, 10)
                                        .toLocaleString()
                                        .replace(/-/g, "/")
                                    )
                                ) /
                                  (1000 * 3600 * 24)
                              )}{" "}
                              days
                            </span>{" "}
                            ago
                          </Typography>

                          <Typography
                            fontWeight="md"
                            textColor="primary.plainColor"
                            mb={0.5}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                              fontSize: "13px",
                              fontWeight: "400",
                              color: "#121212",
                              opacity: "50%",
                              fontFamily: "Inter,sans-serif",
                              paddingTop: "5px",
                            }}
                          >
                            <span
                              style={{
                                color: "black",
                                fontWeight: "700",
                                fontSize: "12px",
                              }}
                            >
                              {Math.ceil(Math.abs(new Date(item.application_deadline) - new Date(item.createdAt))/(1000 * 60 * 60 * 24))} days
                            </span>{" "}
                            left to apply
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="view_more_btn_home"
            onClick={() => navigate("../jobs-list")}
          >
            <Button
              variant="outlined"
              className="apply-now-btn cursor-pointerf"
              style={{
                color: "#081753",
                fontFamily: "Inter,sans-serif",
                border: "1px solid #081753",
                border: "1px solid rgba(8, 23, 83, 0.1)",
              }}
            >
              View More Jobs <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <div className="d-flex align-items-center flex-column">
            <img
              src={noJobs}
              style={{ width: "300px", height: "300px" }}
              alt="no jobs"
            />
            <p>No Jobs Found</p>
          </div>
        </div>
      )}
      <ReferNowModal
        show={referModal.modal}
        purpose={referModal.purpose}
        onHide={modal =>
          setReferModal({
            modal: modal,
            modalType: "",
            type: "",
            details: {},
          })
        }
        params={referModal}
      />
    </>
  );
};
