import React, { useEffect, useState } from "react";
import Profile from "./Profiles/Profile";
import Sidebar from "./Sidebar/Sidebar";

function AccountsIndex(props) {
  return (
    <div className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"58px"}}>
      <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky zepulScroll">
        <Sidebar />
      </div>
      <div className="col-lg-10">
        <Profile />
      </div>
    </div>
  );
}

export default AccountsIndex;
