import React, { useEffect, useState } from "react";
import ReactSelect from "react-select";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Button from "@mui/joy/Button";

function UserLanguages(props) {
  const options = [
    { value: "Telugu", label: "Telugu" },
    { value: "Hindi", label: "Hindi" },
    { value: "English", label: "English" },
    { value: "French", label: "French" },
  ];
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(false);
  async function addLanguages(data) {
    const result = selected.map(item => {
      return item.value;
    });
    const obj = {
      update_type_to_send_email: "email",
      languages: [...result],
      last_name: props.userDetails.last_name,
      first_name: props.userDetails.first_name,
      email: props.userDetails.email,
    };
    let o = Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v != null)
    );
    if (o.languages.length > 0) {
      await updateProfileNormal("languages", o);
      const { data } = await getProfile();
      props.setUserDetails(data);
      props.onHide();
    } else {
      setError(true);
    }
  }
  useEffect(() => {
    setSelected(
      props?.userDetails?.languages?.map(item => ({
        label: item,
        value: item,
      }))
    );
  }, [props?.userDetails?.languages]);
  async function closeModal() {
    props.onHide();
  }
  return (
    <>
      <div className="box">
        <label htmlFor="languages">Languages</label>
        <div className="mb-3 react_select_main_cont" >
          <ReactSelect
            style={{ zIndex: "999" }}
            isMulti={true}
            options={options}
            value={selected}
            onChange={e => {
              setSelected(e);
              setError(false)
            }}
            classNamePrefix="select2-selection"
          />
          {error ? (
            <span style={{ color: "red" }}>Please enter Languages</span>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-end submit-button mt-2">
        <div className="d-flex justify-content-end">
          <Button
        variant="outlined"
        color="primary"
        className=" mt-4"
        type="submit"
        onClick={() => addLanguages()}
        style={{
          margin: "3px",
          background: "rgb(236, 236, 236)",
          color: "rgb(8, 23, 83)",
          border:0
        }}
      >
        Save
      </Button>
        </div>
        <Button
        variant="outlined"
        color="primary"
        className=" mt-4"
        type="submit"
        onClick={() => closeModal()}
        style={{
          margin: "3px",
          background: "rgb(236, 236, 236)",
          color: "rgb(8, 23, 83)",
          border:0
        }}
      >
        Close
      </Button>
      </div>
    </>
  );
}

export default UserLanguages;
