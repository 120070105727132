import React, { Component } from "react";
import { imageURL } from "../Constants/commonURLS";
import { getPreSignedURL } from "../helpers/dataFetcher/pre_signed_urls/upload";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import "./forms.css";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

class FileUploadButton extends Component {
  handleFileUpload = async event => {
    const url = await getPreSignedURL(event.target.files);

    await updateProfileNormal("resume", {
      resume_file: url.replace(imageURL, ""),
    });
  };

  render() {
    const resumeurl = this.props.resume;

    return (
      <React.Fragment>
        {/* <label className="mb-3">Upload CV</label> */}
        {/* <p className="small mb-2 cv-text">Add CV/Resume to Receive Bounty for job</p> */}
        <input
          ref="fileInput"
          onChange={this.handleFileUpload}
          type="file"
          style={{ display: "none" }}
          // multiple={false}
        />
        <div className="d-flex align-items-center">
          <button
            className="upload-file"
            onClick={() => this.refs.fileInput.click()}
          >
            <NoteAddIcon />{" "}
            <span className="p-1">
              {" "}
              {resumeurl === "" ? "Add" : "Edit"} resume
            </span>
          </button>
          {/* <span className="text-white ms-2">resume.pdf</span> */}
        </div>
      </React.Fragment>
    );
  }
}

export default FileUploadButton;
