import React, { useState, Fragment, useEffect } from "react";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
// import { AvForm, AvField ,AvInput} from 'availity-reactstrap-validation';
import ReactSelect from "react-select";
import { PlacesAutocomplete } from "../data/locationSearch";
import { Form, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
// import Button from "@mui/joy/Button";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import Button from "@mui/material/Button";
import Textarea from "@mui/joy/Textarea";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import {globalTheme} from '../assets/MUI/globalStyles';
import { ThemeProvider } from "@mui/material/styles";

const useStyles = makeStyles({
  textarea: {
    "& .css-1onucoh-JoyTextarea-textarea": {
      height: "0px !important",
    },

    "& textarea": {
      minHeight: "48px !important",
      overflow: "auto !important",
      fontFamily: "Inter,sans-serif",
    },
    "& textarea:focus": {
      border: "none !important",
    },
  },
});

function TrainingAndEducation({ setUserDetails, onHide, userDetails }) {
  const [maxDate, setMaxDate] = useState("");
  const classes = useStyles();
  const [data, setData] = useState([
    {
      institute_name: "",
      certificate_name: "",
      start_date: "",
      //specialization: "",
      end_date: "",
      // score: "",
      // grade: "",
      description: "",
    },
  ]);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  /*Date changes functonality start */
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const minEndDate = startDate ? new Date(startDate + "-01T00:00:00") : null;

  useEffect(() => {
    const today = new Date();
    setMaxDate(today);
    setValue("start_date", "");
  }, [setValue]);

  const handleStartDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    if (endDate && selectedDate > new Date(endDate + "-01T00:00:00")) {
      setValue("end_date", ""); // Clear the end date
    }
    setValue("start_date", selectedDate.toISOString().slice(0, 7));
  };

  const handleEndDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    setValue("end_date", selectedDate.toISOString().slice(0, 7));
  };

  /*Date changes functonality End */

  // const startDate = watch("start_date");
  const [error, setError] = useState(false);
  const handleInputChange = (index, event) => {
    const values = [...data];
    if (event.target.name === "institute_name") {
      values[index].institute_name = event.target.value;
    } else if (event.target.name === "certificate_name") {
      values[index].certificate_name = event.target.value;
    } else if (event.target.name === "grade") {
      values[index].grade = event.target.value;
    } else if (event.target.name === "description") {
      values[index].description = event.target.value;
    } else if (event.target.name === "score") {
      values[index].score = event.target.value;
    } else if (event.target.name === "start_date") {
      values[index].start_date = event.target.value;
    } else if (event.target.name === "end_date") {
      values[index].end_date = event.target.value;
    }
    setData(values);
  };

  async function onSubmit(educationData) {
    const newEduData = data?.map(item => {
      return {
        institute_name: educationData.institute_name,
        certificate_name: educationData.certificate_name,
        start_date: educationData.start_date,
        /*end_date:
          item.end_date === ""
            ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
        : item.end_date,*/
        end_date: educationData.end_date,
        description: educationData.description,
      };
    });

    const oldEduDataFilter = userDetails?.education_training_details?.filter(
      each => {
        if (each.is_active) {
          return { ...each };
        }
      }
    );

    const oldEduData = oldEduDataFilter?.map(item => {
      return {
        institute_name: item.institute_name,
        certificate_name: item.certificate_name,
        start_date: item.start_date,
        end_date:
          item.end_date === ""
            ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
            : item.end_date,
        description: item.description,
      };
    });

    const { status } = await updateProfileNormal("education_training", {
      update_type_to_send_email: "email",
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      email: userDetails.email,
      education_training: [...oldEduData, ...newEduData],
    });
    if (status !== 200) {
      setError(true);
      return;
    } else {
      setError(false);
    }
    const { data: response } = await getProfile();
    setUserDetails(response);
    onHide();
  }

  async function closeModal() {
    onHide();
  }
  //  const minEndDate = startDate ? startDate : null;

  return (
    <>
    <ThemeProvider theme={globalTheme}>
      <Form onSubmit={handleSubmit(data => onSubmit(data))}>
        <div className="row form-row">
          {data.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              {/* <div className="form-group col-6">
                <label htmlFor="institite_name">
                  Institute name <span className="red">*</span>
                </label>

                <Input
                  type="text"
                  placeholder="Institute name"
                  className="form-control"
                  //value={data.institute_name}
                  invalid={
                    inputField.institute_name == ""
                      ? inputField.institute_name == ""
                        ? error
                        : true
                      : null
                  }
                  //invalid={data.institute_name === ?true:null}
                  name="institute_name"
                  id="institute_name"
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

              <Col xs={12} md={6} className="mt-4">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Institute Name"
                  name="title"
                  error={errors.institute_name ? true : false}
                  id="first_name"
                  type="text"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  className="form-control"
                  {...register("institute_name", { required: true })}
                />
              </Col>

              {/* <div className="form-group col-6">
                <label className="form-lables">
                  Degree <span className="red">*</span>{" "}
                </label>
                <Input
                  className="form-control"
                  placeholder="Degree"
                  name="certificate_name"
                  id="certificate_name"
                  //value={data.certificate_name}
                  invalid={
                    inputField.certificate_name == ""
                      ? inputField.certificate_name == ""
                        ? error
                        : true
                      : null
                  }
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

              <Col xs={12} md={6} className="mt-4">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Degree"
                  name="title"
                  error={errors.certificate_name ? true : false}
                  id="first_name"
                  type="text"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  className="form-control"
                  {...register("certificate_name", { required: true })}
                />
              </Col>

              {/* <div className="form-group col-12">
                <label htmlFor="location">Specialization</label>
                <Input
                  type="text"
                  placeholder="specialization"
                  className="form-control"
                  id="specialization"
                  name="specialization"
                  //value={data.specialization}
                  //onChange={(event)=> handleInputChange(index, event)}
                />
              </div> */}
              {/* <Col xs={12} md={12} className="mt-4">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Specialization"
                  name="title"
                  error={errors.specialization ? true : false}
                  id="first_name"
                  type="text"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  className="form-control"
                  {...register("specialization", { required: true })}
                />
              </Col> */}
              {/*<div className="form-group col-12">
            <label htmlFor="location">Location</label>
            <PlacesAutocomplete
              location={data.location}
              setLocation={e => {
                setData({ ...data, location: e });
              }}
            />
          </div>*/}
              {/* <div className="form-group col-6">
                <label htmlFor="start_date">Start Date</label>
                <Input
                  type="date"
                  className="form-control"
                  name="start_date"
                  id="start_date"
                  invalid={
                    inputField.start_date == ""
                      ? inputField.start_date == ""
                        ? error
                        : true
                      : null
                  }
                  //value={data.start_date}
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

              <Col xs={12} md={6} className="mt-2 ">
                <label>Start Date</label>
                <br />
                {/* <TextField
                  variant="outlined"
                  size="small"
                  // label="Start Date"
                  name="start_date"
                  error={errors.start_date ? true : false}
                  id="first_name"
                  type="month"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  className="form-control"
                  {...register("start_date", { required: true })}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    value={startDate ? startDate : null}
                    views={["year", "month"]}
                    onChange={value => handleStartDateChange(value)}
                    renderInput={props => (
                      <TextField
                        {...props}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={errors.start_date ? true : false}
                        // onChange={event => handleInputChange(index, event)}
                        // InputProps={{
                        //   ...props.InputProps,
                        //   max: maxDate,
                        // }}
                        {...register("start_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Col>

              {/* <div className="form-group col-6">
                <label htmlFor="end_date">End Date</label>
                <Input
                  type="date"
                  className="form-control"
                  name="end_date"
                  id="end_date"
                  //value={data.end_date}
                  invalid={
                    inputField.end_date == ""
                      ? inputField.end_date == ""
                        ? error
                        : true
                      : null
                  }
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

              <Col xs={12} md={6} className="mt-2 ">
                <label>End Date</label>
                <br />
                {/* <TextField
                  variant="outlined"
                  size="small"
                  // label="Start Date"
                  name="start_date"
                  error={errors.end_date ? true : false}
                  id="first_name"
                  type="month"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  inputProps={{
                    min: minEndDate
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className="form-control"
                  {...register("end_date", { required: true })}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    value={endDate ? endDate : null}
                    minDate={minEndDate}
                    views={["year", "month"]}
                    onChange={value => handleEndDateChange(value)}
                    renderInput={props => (
                      <TextField
                        {...props}
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={errors.end_date ? true : false}
                        {...register("end_date", { required: true })}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Col>

              {/* <div className="form-group col-6">
                <label htmlFor="end_date">Final Grade</label>
                <Input
                  type="text"
                  placeholder="Final grade"
                  className="form-control"
                  name="grade"
                  id="grade"
                  //value={data.final_grade}
                  onChange={event => handleInputChange(index, event)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="end_date">Score</label>
                <Input
                  type="text"
                  placeholder="Score type EG: GPA, % etc"
                  className="form-control"
                  name="score"
                  id="score"
                  //value={data.score}
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}
              {/* <div className="form-group col-12">
                <label htmlFor="description">Description</label>
                <Input
                  rows="10"
                  placeholder="Description"
                  type="text"
                  name="description"
                  className="form-control text_area_input"
                  id="description"
                  style={{ height: 73 }}
                  invalid={
                    inputField.description == ""
                      ? inputField.description == ""
                        ? error
                        : true
                      : null
                  }
                  //value={data.description}
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

              <Col
                xs={12}
                className="mt-4"
                // style={{ height: "10vh", overflowY: "scroll" }}
              >
                {/* <TextField
                  style={{ width: "100%" }}
                  // id="outlined-multiline-static"
                  label="Description"
                  error={errors.description ? true : false}
                  
                  row={5}
                  {...register("description", { required: true })}
                /> */}

                <Box>
                  {/* <Textarea
                    placeholder="Type in here…"
                    minRows={2}
                    maxRows={4}
                    // error={errors.description ? true : false}
                    error={Boolean(errors.description)}
                    {...register("description", { required: true })}
                    className={classes.textarea}
                    sx={{
                      borderRadius: "4px !important",
                      borderColor: errors.description ? "red" : "#ced4da",
                    }}
                  /> */}
                    <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Type in here…"
                    error={Boolean(errors.description)}
                    {...register("description", { required: true })}
                    style={{minHeight:"60px",maxHeight:"60px",borderRadius:"8px", borderColor: errors.description ? "red" : "#ced4da",}}
                  />
                </Box>
              </Col>
            </Fragment>
          ))}
        </div>
        <div className="d-flex justify-content-end submit-button mt-2">
          {/* <button
            className="btn btn-primary rounded_btn"
            style={{ margin: "3px" }}
            //type="submit"
            onClick={e => onSubmit(e)}
          >
            Save
          </button>
          <button
            className="btn btn-secondary rounded_btn "
            style={{ margin: "3px" }}
            type="submit"
            onClick={() => closeModal()}
          >
            Close
          </button> */}
          <Button
            style={{
              margin: "3px",
              background: "rgb(236, 236, 236)",
              color: "rgb(8, 23, 83)",
            }}
            type="submit"
          >
            Save
          </Button>
          <Button
            onClick={() => closeModal()}
            style={{
              margin: "3px",
              background: "rgb(236, 236, 236)",
              color: "rgb(8, 23, 83)",
            }}
          >
            Close
          </Button>
        </div>
      </Form>
      </ThemeProvider>
    </>
  );
}
export default TrainingAndEducation;
