import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/joy/Button";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Chip from "@mui/joy/Chip";
import { Col, Row } from "reactstrap";
import website from "../../assets/images/website.png";
import websiteLogo from "../../assets/images/websiteLogo.png";
import GoogleReviews from "../../assets/images/GoogleReviews.png";
import GlassdoorReview from "../../assets/images/GlassdoorReview.png";
import AmbitionLogo from "../../assets/images/AmbitionLogo.png";
import ComparablyLogo from "../../assets/images/ComparablyLogo.png";
import PayscaleLogo from "../../assets/images/PayscaleLogo.png";
import CarrerLogo from "../../assets/images/CarrerLogo.png";
import reviews from "../../assets/images/reviews.png";
import Comparably from "../../assets/images/comparably.svg";
import carrerBliss from "../../assets/images/carrerBliss.svg";
import glassDoor from "../../assets/images/Glassdoor.svg";
import payScale from "../../assets/images/Payscale.svg";
import googleReview from "../../assets/images/reviews2.svg";
import { Google } from "@mui/icons-material";

function CompanyJobDrawDescription(props) {
  console.log(props?.company,"companytabsss")
  const perksList = [
    {
      name: "Meals",

      selected: false,

      image: "https://img.icons8.com/fluency/48/000000/buffet-breakfast.png",
    },

    {
      name: "Health Care",

      selected: false,

      image: "https://img.icons8.com/doodle/48/000000/nature-care.png",
    },

    {
      name: "Retirement",

      selected: false,

      image:
        "https://img.icons8.com/external-smashingstocks-thin-outline-color-smashing-stocks/67/000000/external-benefits-finance-smashingstocks-thin-outline-color-smashing-stocks.png",
    },

    {
      name: "Parental Leave",

      selected: false,

      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-parental-parenthood-flaticons-flat-flat-icons.png",
    },

    {
      name: "Equity Benefits",

      selected: false,

      image:
        "https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-equity-accounting-flaticons-flat-flat-icons.png",
    },

    {
      name: "Remote",

      selected: false,

      image: "https://img.icons8.com/doodle/48/000000/home-office.png",
    },

    {
      name: "Vaccation",

      selected: false,

      image:
        "https://img.icons8.com/external-gradient-design-circle/66/000000/external-vacation-hotel-and-services-gradient-design-circle.png",
    },

    {
      name: "Wellness",

      selected: false,

      image:
        "https://img.icons8.com/external-bearicons-detailed-outline-bearicons/64/000000/external-Wellness-human-mind-bearicons-detailed-outline-bearicons.png",
    },

    {
      name: "Pet Freindly",

      selected: false,

      image: "https://img.icons8.com/officel/80/000000/dog-park.png",
    },

    {
      name: "Others",

      selected: false,

      image: "https://img.icons8.com/ios/50/000000/gift--v1.png",
    },
  ];

  const matchedPerks = perksList.filter((perk) => props?.company?.company?.perks?.includes(perk.name));

  const urlList = {
    website:props?.company?.company?.website?props?.company?.company?.website:"",
    google:props?.company?.company?.google_reviews?props?.company?.company.google_reviews:"",
    glassdoor:props?.company?.company?.glassdoors_url?props?.company?.company.glassdoors_url:"",
    ambition:props?.company?.company?.ambition_box_url?props?.company?.company.ambition_box_url:"",
    compare:props?.company?.company?.comparably_url?props?.company?.company.comparably_url:"",
    carrer:props?.company?.company?.careerbliss_url?props?.company?.company.careerbliss_url:"",
  }
  const hasData = Object.values(urlList).some((value) => value !== "");

  return (
    <>
      <section className="jobdraw_desc_section mt-2">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={12} lg={7} spacing={1}>
              <Box
                sx={{
                  border: "0.5px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                }}
              >
                <div
                  className="profile_category_desc pb-0 gap-0"
                  id="job_category_header"
                >
                  {/* <div
                    style={{ borderWidth: "2px", borderRadius: "18%" }}
                    className="profile_divider"
                  ></div> */}
                  <h2 className="job_desc_text_1 pt-2">Company Overview</h2>
                </div>

                <Box
                  sx={{
                    // border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    padding: "5px",
                    margin: "10px",
                  }}
                >
                  <p className="profile_summ_txt requirement_text">
                    {props?.company?.company?.company_name} brief
                  </p>
                  <Box
                    sx={{
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      marginTop: "5px",
                    }}
                  >
                    <Row
                      className="user-desc"
                      md="2"
                      sm="2"
                      xs="1"
                      // sx={{ padding: "40px",}}
                    >
                      <Col xs={6} className="about_comp_details">
                        <p>Company Size</p>
                        <p>
                          {props?.company?.company?.size_of_company
                            ? props?.company?.company?.size_of_company
                            : "NA"}
                  
                        </p>
                      </Col>
                      <Col xs={6} className="about_comp_details">
                        <p>Founded</p>
                        <p>2016</p>
                      </Col>
                      <Col xs={6} className="about_comp_details">
                        <p>Headquarters</p>
                        <p>
                          {props?.company?.company?.city
                            ?props?.company?.company?.city
                            : "NA"}
                 
                        </p>
                      </Col>
                    </Row>
                  </Box>

                  <p className="profile_summ_txt requirement_text">
                    Benefits reported by employees
                  </p>
                  <Box
                    sx={{
                      // border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      marginTop: "5px",
                    }}
                  >
                    {/* <div className="benefits_sec"> */}
                    <div className="benefits_draw_sec">
                      {matchedPerks ? (
                        matchedPerks.map((each, index) => (
                          <div className="card_section" key={index}>
                            <div className="company_inside_sect">
                              <div
                                style={{
                                  // height: "44px",
                                  // width: "44px",
                                  borderRadius: "50%",
                                  border: "1px",
                                }}
                              >
                                <img
                                  src={each.image}
                                  style={{ height: "30px", width: "30px" }}
                                />
                              </div>
                              <p className="benefits_heading">{each.name}</p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex align-items-center flex-column">
                          <img
                            src={noJobs}
                            style={{ width: "300px", height: "300px" }}
                            alt="no jobs"
                          />
                          <p>No Benefits Found</p>
                        </div>
                      )}
                    </div>
                  </Box>
                </Box>
              </Box>
            </Grid>

            <Grid  xs={12} sm={12} md={12} lg={5} sx={{ flexDirection: "column" }}>
              <Grid>
                <Box
                  sx={{
                    border: "0.5px solid rgba(0, 0, 0, 0.1)",
                    borderRadius: "4px",
                    marginLeft: "8px",
                  }}
                >
                  <div
                    className="profile_category_desc pb-0 gap-0"
                    id="job_category_header"
                  >
                    {/* <div
                      style={{ borderWidth: "2px", borderRadius: "18%" }}
                      className="profile_divider"
                    ></div> */}
                    <h2 className="job_desc_text_1 pt-2">{props?.company?.company?.company_name} Links</h2>
                  </div>

                  <Box
                    sx={{
                      // border: "1px solid rgba(0, 0, 0, 0.1)",
                      borderRadius: "4px",
                      padding: "5px",
                      margin: "10px",
                    }}
                  >
                    <p className="profile_summ_txt requirement_text">{props?.company?.company?.company_name}</p>
                    <Box
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRadius: "4px",
                        marginTop: "5px",
                      }}
                    >
                      <Row
                        className="user-desc"
                        xl="2"
                        lg="2"
                        md="3"
                        sm="2"
                        xs="2"
                        sx={{ padding: "40px" }}
                      >
                        
                        {urlList.google ? (
                          <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a
                                href={`https://${urlList.google}`}
                                target="_blank"
                              >
                                <img
                                  src={GoogleReviews}
                                  className="website_img"
                                />
                              </a>
                            </div>
                          </Col>
                         ) : (
                          ""
                        )} 
                        {urlList.glassdoor ? (
                          <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a href={`https://${urlList.glassdoor}`}>
                                <img
                                  src={GlassdoorReview}
                                  className="website_img"
                                />
                              </a>
                            </div>
                          </Col>
                         ) : (
                          ""
                        )} 
                        {urlList.ambition ? (
                          <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a href={`https://${urlList.ambition}`}>
                                <img
                                  src={AmbitionLogo}
                                  className="website_img"
                                />
                              </a>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {urlList.compare ? (
                          <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a href={`https://${urlList.compare}`}>
                                <img
                                  src={ComparablyLogo}
                                  className="website_img"
                                />
                              </a>
                            </div>
                          </Col>
                         ) : (
                          ""
                        )} 
                        {urlList.carrer ? (
                          <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a href={`https://${urlList.carrer}`}>
                                <img src={CarrerLogo} className="website_img" />
                              </a>
                            </div>
                          </Col>
                         ) : (
                          ""
                        )}

                        {urlList.website ? (
                        <Col xs={4} md={4} className="about_comp_details">
                            <div className="web_cont">
                              <a
                                href={`https://${urlList.website}`}
                                target="_blank"
                              >
                                <img
                                  src={websiteLogo}
                                  className="website_img"
                                />
                              </a>
                            </div>
                          </Col>
                         ) : (
                          ""
                        )} 

           
                      </Row>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
}

export default CompanyJobDrawDescription;
