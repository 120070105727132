import { makeStyles } from "@mui/styles";

export const DataGridStyles = makeStyles({
    headerCellStyles: {
      fontWeight: "700",
      background:"rgba(8, 23, 83, 0.2)",
      fontFamily: "Inter,sans-serif",
      fontSize: "13.213px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.5)",
      "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
        fontWeight: "700",
        fontFamily: "Inter,sans-serif",
        fontSize: "13.213px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
  });

  export const styleObj = {
    fontFamily: "Inter,sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "11.5614px",
    color: "rgba(0, 0, 0, 0.8)",
  };
  export const paginateStyles = {
    "& .MuiPaginationItem-root": {
      "&:hover": {
        backgroundColor: "",
        color: "",
      },
      "&.Mui-selected": {
        backgroundColor: "rgba(8, 23, 83, 0.2)",
        color: "",
        "&:hover": {
          backgroundColor: "rgba(8, 23, 83, 0.2)",
          color: "",
        },
      },
    },
  }