import React, { useEffect, useState } from "react";
import Button from "@mui/joy/Button";
import { Col, Form, Row } from "reactstrap";
import Modal from "@mui/material/Modal";
import { useSelector } from "react-redux";
import { getAllKeySkills } from "../../../helpers/dataFetcher/jobAttributes/keyskills";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import parse from "autosuggest-highlight/parse";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "../../../assets/css/applyForJob/index.css";
import { debounce } from "@mui/material/utils";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { applyForJob } from "../../../helpers/dataFetcher/jobs/jobs";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import ModalClose from "@mui/joy/ModalClose";
import { ModalDialog } from "@mui/joy";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import {useNavigate} from "react-router-dom"
import { getUserInfo } from "../../../helpers/dataFetcher/profile/profile";
import SocketConnection from "../../../hooks/socket";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function CandidateDetails({
  show,
  onHide,
  setStep,
  details,
  resumeDetails,
  purpose,
  screeningQuestions,
  setScreeningQuestions
}) {
  // const {sendMessage:emitSocket} = SocketConnection()
  const { lastMessage, sendMessage: sendSocketMessage,connectionStatus } = SocketConnection();
  const [applyStatus, setApplyStatus] = useState(false);
  const [location, setLocation] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [userInfo,setUserInfo] = useState(null)
  const loaded = React.useRef(false);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  useEffect(()=>{
    (async()=>{
      const res = await getUserInfo()
      const {data} = res?.data
      setUserInfo(data)
    })()
  },[])

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);

  const [about, setAbout] = useState("");
  const user = useSelector(state => state.User.user);

  useEffect(() => {
    (async()=>{

      const res = await getUserInfo()

      const {data} = res?.data

      setUserInfo(data)

    })()
    handleSkills();
  }, []);

  const [options, setOptions] = useState([]);
  const [skillOptions, setSkillOptions] = useState([]);
  async function handleSkills() {
    const { data } = await getAllKeySkills();
    const skills = data?.map(item => ({ id: item.id, title: item.name }));
    setSkillOptions(skills);
  }
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = yup
    .object({
      phone_number: yup.string().min(8).max(14),
      email: yup.string().email().required(),
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      // dob: yup.date().required(),
      // location: yup.object().required(),
      skills: yup.array().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      work_exp: [
        { designation: "", employer: "", start_date: "", end_date: "" },
      ],
      courses: [
        {
          certificate: "",
          institute: "",
          Specialization: "",
          start_date: "",
          end_date: "",
        },
      ],
    },
  });
  console.log(watch("phone_number")); // watch input value by passing the name of it
  console.log(errors);
  const { fields, append, remove, replace, update } = useFieldArray({
    control,
    name: "work_exp",
  });
  const {
    replace: courseReplace,
    update: courseUpdate,
    fields: courseFields,
    append: courseAppend,
    remove: courseRemove,
  } = useFieldArray({
    control,
    name: "courses",
  });

  // let normalObj = {
  //   courses: [
  //     {
  //       Specialization: "",
  //       certificate: "",
  //       institute: "",
  //       start_date: "",
  //       end_date: "",
  //     },
  //   ],
  //   dob: "25/12/1994",
  //   email: "nainiaditi@gmail.com",
  //   first_name: "aditi",
  //   last_name: "Nasri",
  //   location: "google",
  //   phone_number: "+918848083317",
  //   skills: [],
  //   work_exp: [{ employer: "", title: "", start_date: "", end_date: "" }],
  // };
  useEffect(() => {
    if (resumeDetails) {
      if (resumeDetails?.work_exp?.length > 0) {
        remove(0);
      }
      if (resumeDetails?.courses?.length > 0) {
        courseRemove(0);
      }
      setValue("first_name", resumeDetails.first_name);
      setValue("skills", resumeDetails.skills);
      setValue("last_name", resumeDetails.last_name);
      setValue("email", resumeDetails.email);
      setValue("phone_number", resumeDetails.phone_number);
      // setValue("location", resumeDetails.location);
      // setValue("dob", resumeDetails.dob);
      resumeDetails?.work_exp?.map(item => {
        append(item);
      });
      resumeDetails?.courses?.map(item => {
        courseAppend(item);
      });
    }
  }, [resumeDetails]);
  const onSubmit = async data => {
    if (!data?.phone_number?.startsWith("+")) {
      data.phone_number = `+${data?.phone_number}`;
    }
    // const { status, data: messageData, } = await applyForJob(
    //   {
    //     ...data,
    //     phone_number: data?.phone_number,
    //     about_this_job: "about",
    //     location: data?.location?.description,
    //     job_apply_type: purpose,
    //     skills: data?.skills.map(item => item.id),
    //     // screeningQuestions:screeningQuestions
    //   },
    //   details?.id
    // );
    const result = await applyForJob(
      
      {
        ...data,
        phone_number: data?.phone_number,
        about_this_job: "about",
        location: data?.location?.description,
        job_apply_type: purpose,
        skills: data?.skills.map(item => item.id),
        resume:[userInfo?.resume||userInfo?.resume_url]
        // screeningQuestions:screeningQuestions
      },
      details?.id
    );

  setScreeningQuestions(result?.data)
    setApplyStatus(true);
    // setStep(1);

    // if (status === 201) {
      if (result.status === 201) {
      setApplyStatus(false);
      // onHide(false);
      toast.success("applied for job");
      sendSocketMessage(JSON.stringify({
        type:'job_apply_success',
        data:{
          data:'job_apply_success'
        },
        reciever_connectionId:''
      }))
      setTimeout(()=>{
        // setStep(4)
        
        if(purpose === "refer"){
          setStep(1)
          onHide()
        }else {
          setStep(4)
        }
      },1000)
    } else {
      setApplyStatus(false);
      onHide(false);
      if (typeof result.data.message === "string") toast.error(result.data.message);
      else {
        toast.error(result.data.message);
      }
      // if (typeof messageData === "string") toast.error(messageData);
      // else {
      //   toast.error(messageData?.message);
      // }
    }
  };
  return (
    <>
      <Modal
        open={!!show}
        onClose={() => {
          onHide(false);
          setStep(1);
        }}
        backdrop="static"
        size="lg"
        // className="apply_modal"
        keyboard={false}
      >
        {/* {status === 200 ? ( */}
        <ModalDialog
          className="apply_modal"
          size="lg"
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={"center" || undefined}
        >
          <Typography
            id="layout-modal-title"
            component={Link}
            level="inherit"
            to={`../job/${details?.id}`}
            className="text_headling_p d-flex align-items-center gap-2 justify-content-start"
            fontSize="1.25em"
            mb="0.25em"
          >
            <FeatherIcon icon="briefcase" size="14" />
            {details?.job_title}
          </Typography>
          <ModalClose
            onClick={() => {
              onHide(false);
              setStep(1);
            }}
            variant="outlined"
            sx={{
              top: "calc(-1/4 * var(--IconButton-size))",
              right: "calc(-1/4 * var(--IconButton-size))",
              boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
              borderRadius: "50%",
              bgcolor: "background.body",
            }}
          />
          <Form
            style={{
              overflowY: "scroll",
              height: "40vh",
            }}
            onSubmit={handleSubmit(onSubmit)}
            className="candidate_details_fields"
          >
            <Row
              style={{
                width: "100%",
                height: "25vh",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <Col className="mt-4" xs={12} md={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  className="form-control"
                  label="first Name"
                  type="text"
                  {...register("first_name", { required: true })}
                ></TextField>
              </Col>
              <Col className="mt-4" xs={12} md={6}>
                <TextField
                  variant="outlined"
                  size="small"
                  label="last Name"
                  className="form-control"
                  type="text"
                  {...register("last_name", { required: true })}
                />
              </Col>
              <Col xs={12} md={6} className="mt-4">
                <TextField
                  variant="outlined"
                  size="small"
                  label="Email"
                  className="form-control"
                  disabled={purpose === "self" ? true : false}
                  error={errors.email ? true : false}
                  {...register("email", { required: true })}
                  type="email"
                  placeholder="Email"
                />
              </Col>
              <Col className="mt-4" xs={12} md={6}>
                {/* <Controller
                  name="phone-number"
                  control={control}
                  rules={{
                    validate: value => {
                      value.match(phoneRegExp);
                    },
                  }}
                  render={({ field: { onChange, value } }) => ( */}
                <PhoneInput
                  value={getValues("phone_number")}
                  onChange={e => {
                    setValue("phone_number", e);
                  }}
                  style={{height:58}}
                  // {...register("phone_number", { required: true })}
                  country={"in"}
                  countryCodeEditable={true}
                  inputStyle={{
                    borderColor: errors.phone_number && "red",
                  }}
                  error={errors.phone_number ? true : false}
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return "Invalid value: " + value + ", " + country.name;
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                />

                {errors.phone_number && (
                  <p
                    style={{ color: "red" }}
                    className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense"
                  >
                    phone number must be valid
                  </p>
                )}
              </Col>
              {/* <Col className="" xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    {" "}
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <MobileDatePicker
                          // className="date-input-fields"
                          disableFuture
                          label="Date of Birth"
                          inputFormat="MM/DD/YYYY"
                          value={dayjs(getValues("dob"))}
                          onChange={newValue => {
                            setValue("dob", newValue);
                          }}
                          renderInput={params => (
                            <TextField {...params} size="small" />
                          )}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Col> */}
              {/* <Col className="" xs={12} md={6}>
                <Autocomplete
                  size="small"
                  id="google-map-demo"
                  getOptionLabel={option =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={x => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  noOptionsText="No locations"
                  {...register("location", { required: false })}
                  onChange={(event, newValue) => {
                    setValue("location", newValue);
                    setOptions(newValue ? [newValue, ...options] : options);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={errors?.location ? true : false}
                      label="Add a location"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting
                        .main_text_matched_substrings || [];

                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map(match => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {parts.map((part, index) => (
                              <Box
                                key={index}
                                component="span"
                                sx={{
                                  fontWeight: part.highlight
                                    ? "bold"
                                    : "regular",
                                }}
                              >
                                {part.text}
                              </Box>
                            ))}
                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              </Col> */}
              {/* <Col className="mt-4" xs={12}>
                <Autocomplete
                  multiple
                  size="small"
                  value={getValues("skills")}
                  // {...register("skills", { required: false })}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setTimeout(() => {
                        // toggleOpen(true);
                        setDialogValue({
                          title: newValue,
                          year: "",
                        });
                      });
                    } else if (newValue && newValue.inputValue) {
                      // toggleOpen(true);
                      setDialogValue({
                        title: newValue.inputValue,
                        year: "",
                      });
                    } else {
                      setValue("skills", newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        title: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  id="free-solo-dialog-demo"
                  options={skillOptions}
                  getOptionLabel={option => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={(props, option) => (
                    <li {...props}>{option.title}</li>
                  )}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      error={errors?.skills ? true : false}
                      {...params}
                      label="Add Your Skills"
                    />
                  )}
                />
              </Col> */}
              <Row>
                {/* <Col xs={6}>
                  <p className="mt-2 font-weight-bold">Work Experience</p>
                </Col>{" "}
                <Col xs={6} className="float-right">
                  <button
                    className="theme_new_btn_small_icons "
                    type="button"
                    onClick={() => {
                      append();
                    }}
                  >
                    <i class="bx bx-plus"></i>
                  </button>
                </Col> */}
              </Row>

              {/* {fields?.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Typography fontSize="lg" fontWeight="lg" className="mt-2">
                    Work Experience {index + 1}
                  </Typography>
                  <Divider inset="none" sx={{ my: 1.5 }} />
                  <Col xs={6} className="mt-4">
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Designation"
                      className="form-control"
                      error={errors.work_exp?.[index]?.title && true}
                      {...register(`work_exp[${index}].title`, {
                        required: true,
                      })}
                      type="text"
                    />
                  </Col>
                  <Col xs={6} className="mt-4">
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Employer"
                      className="form-control"
                      error={errors.work_exp?.[index]?.employer && true}
                      {...register(`work_exp[${index}].employer`, {
                        required: true,
                      })}
                      type="text"
                    />
                  </Col>
                  <Col xs={6} className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="start date"
                          disableFuture
                          inputFormat="MM/DD/YYYY"
                          onChange={e => {
                            update(index, {
                              start_date: e,
                              employer: getValues(
                                `work_exp[${index}].employer`
                              ),
                              end_date: getValues(
                                `work_exp[${index}].end_date`
                              ),
                              title: getValues(`work_exp[${index}].title`),
                            });
                          }}
                          error={errors.work_exp?.[index]?.start_date && true}
                          value={getValues(`work_exp[${index}].start_date`)}
                          renderInput={params => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Col>
                  <Col xs={6} className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/DD/YYYY"
                          onChange={e => {
                            update(index, {
                              start_date: getValues(
                                `work_exp[${index}].start_date`
                              ),
                              employer: getValues(
                                `work_exp[${index}].employer`
                              ),
                              end_date: e,
                              title: getValues(`work_exp[${index}].title`),
                            });
                          }}
                          error={errors.work_exp?.[index]?.end_date && true}
                          value={getValues(`work_exp[${index}].end_date`)}
                          renderInput={params => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Col>
                  
                </React.Fragment>
              ))} */}
              <Row>
                <Col xs={6}></Col>{" "}
              </Row>
              {/* {courseFields?.map((item, index) => (
                <React.Fragment key={item.id}>
                  <Typography fontSize="lg" fontWeight="lg" className="mt-2">
                    Course {index + 1}
                  </Typography>
                  <Divider inset="none" sx={{ my: 1.5 }} />
                  <Col xs={6} className="mt-4">
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Certificate"
                      className="form-control"
                      type="text"
                      error={errors.courses?.[index]?.certificate && true}
                      {...register(`courses[${index}].certificate`, {
                        required: true,
                      })}
                      control={control}
                    />
                  </Col>
                  <Col xs={6} className="mt-4">
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Institute"
                      className="form-control"
                      type="text"
                      error={errors.courses?.[index]?.institute && true}
                      {...register(`courses[${index}].institute`, {
                        required: true,
                      })}
                      control={control}
                    />
                  </Col>
                  <Col xs={6} className="mt-4">
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Specialization"
                      className="form-control"
                      error={errors.courses?.[index]?.Specialization && true}
                      {...register(`courses[${index}].Specialization`, {
                        required: true,
                      })}
                      control={control}
                    />
                  </Col>
                  <Col xs={6} className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="Start Date"
                          disableFuture
                          inputFormat="MM/DD/YYYY"
                          error={errors.courses?.[index]?.start_date && true}
                          value={getValues(`courses[${index}].start_date`)}
                          control={control}
                          onChange={e => {
                            courseUpdate(index, {
                              start_date: e,
                              Specialization: getValues(
                                `courses[${index}].Specialization`
                              ),
                              end_date: `courses[${index}].start_date`,
                              institute: getValues(
                                `courses[${index}].institute`
                              ),
                              certificate: getValues(
                                `courses[${index}].certificate`
                              ),
                            });
                          }}
                          renderInput={params => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Col>
                  <Col xs={6} className="mt-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          label="End Date"
                          inputFormat="MM/DD/YYYY"
                          onChange={e => {
                            courseUpdate(index, {
                              start_date: getValues(
                                `courses[${index}].start_date`
                              ),
                              Specialization: getValues(
                                `courses[${index}].Specialization`
                              ),
                              end_date: e,
                              institute: getValues(
                                `courses[${index}].institute`
                              ),
                              certificate: getValues(
                                `courses[${index}].certificate`
                              ),
                            });
                          }}
                          error={errors.courses?.[index]?.end_date && true}
                          value={getValues(`courses[${index}].end_date`)}
                          control={control}
                          renderInput={params => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Col>
                  <Col xs={6}></Col>
                 
                </React.Fragment>
              ))} */}
            </Row>

            {applyStatus ? (
              <Button loading>Loading</Button>
            ) : (
              <Button type="submit" className="mt-2">
                Submit
              </Button>
            )}
          </Form>
        </ModalDialog>
      </Modal>
    </>
  );
}
