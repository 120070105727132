import React from "react";
import "../../../../src/assets/css/AboutUs/About.css";
import contactUs1 from "../../../assets/images/contactus1.png";
import styled from "styled-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import ZepulLogo9 from '../../../assets/images/ZepulLogo9.png';

const BackGroundImageDiv = styled.div`
  background-image: url(${contactUs1});
  background-size: cover;
  min-height: 100%;
  @media (max-width: 1000px) {
    background-image: none;
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    // "& .MuiInputBase-input": {
    //   height: "30px",
    //   borderRadius: "8px !important",
    //   border: "1px solid rgba(0, 0, 0, 0.2) !important",
    // },
    // "& .MuiFormLabel-root": {
    //   fontFamily: "Inter, sans-serif",
    //   fontSize: "16px",
    //   fontWeight: "400",
    //   color: "rgba(0, 0, 0, 0.75)",
    // },
  },
  button: {
    color: "#FFFFFF !important",
    background: "#081753 !important",
    height: "49px !important",
    borderRadius: "24px !important",
    textTransform: "capitalize !important",
    fontFamily: "Inter, sans-serif",
    fontSize: "16px",
    fontWeight: "500",
    marginTop: "10px",
  },
}));

function AboutUsJoBoard() {
  const classes = useStyles();

  return (
    <>
      <section className="About_section1 mt-3">
        <BackGroundImageDiv className="about_inside_first_sec1">
          <div className="about_sec_main1">
            <img
              src={ZepulLogo9}
              className="zepul_logo"
            />
            <h2 className="about_head_txt">On a Mission to Revolutionise Recruitment</h2>
            {/* <h2 className="about_head_txt">people realize their potential</h2> */}
            <p className="about_para_txt">
              {/* We're a remote first team united around our goal of creating a
              <br />
              world where all people have access to carrer services -- early in{" "}
              <br />
              their lives and throughout their carrers */}
              We are a people centric team working around a common goal, to revolutionise Tech Driven Hiring.

            </p>
            <Button
              variant="contained"
              className={classes.button}
              style={{ marginBottom: "18px", marginTop: "8px" }}
              onClick={() => {
                window.open("https://zepul.com/registration", "_blank");
              }}
            >
              Join Our Team
            </Button>
          </div>
        </BackGroundImageDiv>
      </section>
    </>
  );
}

export default AboutUsJoBoard;
