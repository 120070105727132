import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Lists from "../JobsList/Alljobs/Lists";
import NotificationDrawerMessages from "./NotificationDrawerMessages";

const useStyles = makeStyles({
  root: {
    "& .MuiDrawer-paper": {
      height: "100vh !important",
    },
    ".MuiDrawer-root>.MuiPaper-root": {
      height: "100vh !important",
    },
  },
  paper: {
    height: "100vh",
    ".MuiDrawer-root>.MuiPaper-root": {
      height: "100vh !important",
    },
  },
});

export default function NotificationDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const navigate = useNavigate();

  const notificationsData = [
    {
      id: 1,
      title: "Syoft",
      role: "Python Developer",
      image:
        "https://media.licdn.com/dms/image/C560BAQEbAYjuSygMKg/company-logo_200_200/0/1660810909959?e=2147483647&v=beta&t=Bhh0lgAd_vwQlag3vizAsO1iVINEhmIq-OEM67L5Rkk",
      time: "Thu,23 2023",
      message: "Your profile was",
      key: "Submitted",
      type: "msg",
    },
    {
      id: 2,
      title: "Tata Consultancy Services",
      role: "NodeJs Developer",
      image:
        "https://cabinetm-beta.s3.amazonaws.com/0000017a-d348-a35a-a0e3-37aaa6dbde4b.jpg",
      time: "Mon,12 2022",
      message: "Your profile was",
      key: "Selected",
      type: "msg",
    },
    {
      id: 3,
      title: "Wipro Limited",
      role: " AWS Cloud Architect",
      image:
        "https://upload.wikimedia.org/wikipedia/commons/a/a0/Wipro_Primary_Logo_Color_RGB.svg",
      time: "Fri,16 2021",
      message: "Your profile was",
      key: "Rejected",
      type: "msg",
    },
    {
      id: 4,
      title: "Virtusa",
      role: "Angular Developer",
      image:
        "https://content.jdmagicbox.com/comp/pune/l5/020pxx20.xx20.180926190951.p1l5/catalogue/virtusa-pune-pune-corporate-companies-lk0bik5jhd-250.jpg",
      time: "Tue,28 2023",
      message: "Your have received a new message from",
      key: "Dileep",
      type: "chat",
    },
  ];

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 500 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className={classes.root}
    >
      <List style={{ background: "#EFF7FF" }}>
        {["All Notifications"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <NotificationDrawerMessages messages={notificationsData} />
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map(anchor => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}

          <Badge
            badgeContent={4}
            color="success"
            onClick={toggleDrawer(anchor, true)}
          >
            <NotificationsNoneIcon color="#081753" />
          </Badge>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            className={classes.root}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
