import React, { useState, useEffect, Fragment } from "react";
import { updateProfileNormal } from "../helpers/dataFetcher/profile/update";
import { getProfile } from "../helpers/dataFetcher/profile/profile";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { PlacesAutocomplete } from "../data/locationSearch";
import { Form, Input } from "reactstrap";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
// import Button from "@mui/joy/Button";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
// import Box from "@mui/joy/Box";
import Box from "@mui/material/Box";
import Textarea from "@mui/joy/Textarea";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { PlacessAutocomplete } from "../data/locationsSearch3";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import {useNavigate} from "react-router-dom";
import { debounce } from "@mui/material/utils";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const GOOGLE_MAPS_API_KEY = "AIzaSyDk52HPcdIZWt2glyeWGUyn9hiqAsGMJvk";
import {
  LocationCityOutlined,
  LocationOnOutlined,
  Map,
} from "@mui/icons-material";
import {
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { getValue } from "@mui/system";
import { globalTheme } from "../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
// import DatePicker from "react-date-picker";

const useStyles = makeStyles({
  textarea: {
    "& .css-1onucoh-JoyTextarea-textarea": {
      height: "0px !important",
    },

    "& textarea": {
      minHeight: "48px !important",
      overflow: "auto !important",
      fontFamily: "Inter,sans-serif",
    },
    "& textarea:focus": {
      border: "none !important",
    },
  },
  textareaMui: {},
});

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  // const script = document.createElement("script");
  // script.setAttribute("async", "");
  // script.setAttribute("id", id);
  // script.src = src;
  // position.appendChild(script);
}

const autocompleteService = { current: null };


function WorkExperience(props) {
  const { onHide, setUserDetails } = props;
  const [error, setError] = useState(false);
  const [noticePerErr, setNoticePerErr] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [location, setLocation] = useState("");
  const [options, setOptions] = useState([]);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  /*location search  start*/
  const [inputValue, setInputValue] = React.useState("");
  const loaded = React.useRef(false);
  const filter = createFilterOptions();
  const navigate = useNavigate();
  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(location ? [location] : []);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (location) {
          newOptions = [location];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [location, inputValue, fetch]);
  /*Location search end *.

  /*Date changes functonality start */
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const minEndDate = startDate ? new Date(startDate + "-01T00:00:00") : null;

  useEffect(() => {
    const today = new Date();
    setMaxDate(today);
    setValue("start_date", "");
  }, [setValue]);

  const handleStartDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    if (endDate && selectedDate > new Date(endDate + "-01T00:00:00")) {
      setValue("end_date", ""); // Clear the end date
    }
    setValue("start_date", selectedDate.toISOString().slice(0, 7));
  };

  const handleEndDateChange = value => {
    const selectedDate = new Date(value);
    selectedDate.setMonth(selectedDate.getMonth() + 1);
    setValue("end_date", selectedDate.toISOString().slice(0, 7));
  };

  /*Date changes functonality End */

  const [inputFields, setInputFields] = useState([
    {
      title: "",
      company: "",
      description: "",
      location: "",
      noticeperiod: "",
      start_date: "",
      end_date: "",
    },
  ]);
  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      title: "",
      company: "",
      description: "",
      location: "",
      noticeperiod: "",
      start_date: "",
      end_date: "",
    });
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === "title") {
      values[index].title = event.target.value;
    } else if (event.target.name === "currently_working") {
      values[index].is_currently_working = !values[index].is_currently_working;
    } else if (event.target.name === "company") {
      values[index].company = event.target.value;
    } else if (event.target.name === "description") {
      values[index].description = event.target.value;
    } else if (event.target.name === "location") {
      values[index].location = event.target.value;
    } else if (event.target.name === "noticeperiod") {
      let num = Number(event.target.value);
      if (num >= 0) {
        values[index].noticeperiod = num;
        setNoticePerErr("");
      } else {
        values[index].noticeperiod = "";
        setNoticePerErr("Not a negative number");
      }
    } else if (event.target.name === "start_date") {
      values[index].start_date = event.target.value;
    } else if (event.target.name === "end_date") {
      values[index].end_date = event.target.value;
    }
    setInputFields(values);
  };

  const resetForm = e =>
    setInputFields([
      {
        title: "",
        company: "",
        description: "",
        location: "",
        noticeperiod: "",
        start_date: "",
        end_date: "",
      },
    ]);

  const onSubmit = async data => {
console.log(data,"nnppp")
    const updatedOne = inputFields.map(item => {
      return {
        title: data.title,
        company: data.company,
        description: data.description,
        // location: data.location.description,
        location:data.location,
        notice_period:
          data?.noticeperiod === null ? 0 : Number(item.noticeperiod),
        is_currently_working: item.is_currently_working,
        start_date: data.start_date,
        end_date:
          data.end_date === ""
            ? new Date()
            : // ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
              data.end_date,
      };
    });
    let o = Object.fromEntries(
      Object.entries(updatedOne).filter(([_, v]) => v != "")
    );

    const oldWorkExpFilter = props?.userDetails?.work_exp_details?.filter(
      each => {
        if (each.is_active) {
          return { ...each };
        }
      }
    );

    const oldWorkExp = oldWorkExpFilter?.map(item => {
      return {
        title: item.title,
        company: item.company,
        description: item.description,
        location: item.location,
        notice_period:
          item?.notice_period === null ? 0 : Number(item.notice_period),
        is_currently_working: item.is_currently_working,
        start_date: item.start_date,
        end_date: item.end_date,
      };
    });
    const { status } = await updateProfileNormal("work_experience", {
      update_type_to_send_email: "email",
      email: props?.userDetails.email,
      first_name: props?.userDetails.first_name,
      last_name: props?.userDetails.last_name,
      work_exp: [...oldWorkExp, ...updatedOne],
    });

    if (status !== 200) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    const { data: result } = await getProfile();
    setUserDetails(result);
    onHide();
  };

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];
  async function closeModal() {
    onHide();
  }
  // const minEndDate = startDate ? startDate : null;
  return (
    <>
      <ThemeProvider theme={globalTheme}>
        <Form
          onSubmit={handleSubmit(data => onSubmit(data))}
          className="about_us_form zepulScroll"
        >
          <div className="row form-row">
            {inputFields.map((inputField, index) => (
              <Fragment key={`${inputField}~${index}`}>
                {/* <div className="form-group col-6">
                <label className="form-lables">Title</label>
                <Input
                  type="text"
                  placeholder="Title"
                  className="form-control"
                  id="title"
                  name="title"
                  //value={inputField.title}
                  invalid={
                    inputField.title == ""
                      ? inputField.title == ""
                        ? error
                        : true
                      : null
                  }
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

                <Col xs={12} md={6} className="mt-4">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Role"
                    name="title"
                    error={errors.title ? true : false}
                    id="first_name"
                    type="text"
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    {...register("title", { required: true })}
                    // InputLabelProps={{style:{color:"#081753"}}}
                  />
                </Col>

                {/* <div className="form-group col-6">
                <label htmlFor="company">Company</label>
                <Input
                  type="text"
                  placeholder="Company"
                  className="form-control"
                  id="company"
                  name="company"
                  invalid={
                    inputField.company == ""
                      ? inputField.company == ""
                        ? error
                        : true
                      : null
                  }
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

                <Col xs={12} md={6} className="mt-4">
                  <TextField
                    variant="outlined"
                    size="small"
                    label="Company"
                    name="company"
                    error={errors.company ? true : false}
                    id="first_name"
                    type="text"
                    // defaultValue={userDetails?.first_name}
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    {...register("company", { required: true })}
                  />
                </Col>

                <div className="form-group col-12">
                  {/* <label htmlFor="location">Location</label> */}

                  {/* <Input location={inputField.location}
              placeholder="location"
              className="form-control"
              id="location"
              name="location"
              type="text"
              invalid={inputField.location == ""?error:true}
              setLocation={e => {
                handleInputChange(index, {
                  target: { value: e, name: "location" },
                });
              }}
                /> */}

                  {/* <PlacessAutocomplete
                  location={inputField.location}
                  //invalid={inputField.location !== ""?error:true}
                  invalid={
                    inputField.location == ""
                      ? inputField.location == ""
                        ? error
                        : true
                      : null
                  }
                  setLocation={e => {
                    handleInputChange(index, {
                      target: { value: e, name: "location" },
                    });
                  }}
                /> */}

                  <Col xs={12} md={6} className="mt-4 w-100">
                    {/* <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnOutlined
                              // color={errors.location ? "danger" : "primary"}
                              sx={{
                                color: errors.location ? "red" : "#081753",
                                "&:hover": {
                                  color: errors.location ? "red" : "#081753",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      size="small"
                      label="Enter Your Location"
                      name="location"
                      error={errors.location ? true : false}
                      id="first_name"
                      type="text"
                      // defaultValue={userDetails?.first_name}
                      onChange={event => handleInputChange(index, event)}
                      className="form-control"
                      {...register("location", { required: true })}
                    /> */}

                    {/* <PlacessAutocomplete
                      location={inputField.location}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LocationOnOutlined
                              // color={errors.location ? "danger" : "primary"}
                              sx={{
                                color: errors.location ? "red" : "#081753",
                                "&:hover": {
                                  color: errors.location ? "red" : "#081753",
                                },
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      invalid={
                        inputField.location == ""
                          ? inputField.location == ""
                            ? error
                            : true
                          : null
                      }
                      setLocation={e => {
                        handleInputChange(index, {
                          target: { value: e, name: "location" },
                        });
                      }}
                    /> */}
                  </Col>


                  <Col className="mt-4" xs={12}>
                <Autocomplete
                  size="small"
                  id="google-map-demo"
                  getOptionLabel={option =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={x => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  noOptionsText="No locations"
                  {...register("location", { required: true })}
                  onChange={(event, newValue) => {
                    setValue("location", newValue);
                    setOptions(newValue ? [newValue, ...options] : options);
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={errors?.location ? true : false}
                      {...register("location", { required: true })}
                      label="Add a location"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting
                        .main_text_matched_substrings || [];

                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map(match => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );

                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {parts.map((part, index) => (
                              <Box
                                key={index}
                                component="span"
                                sx={{
                                  fontWeight: part.highlight
                                    ? "bold"
                                    : "regular",
                                }}
                              >
                                {part.text}
                              </Box>
                            ))}
                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
              </Col> 

                </div>

                <div
                  className="mb-3 col-12 form-check pt-4"
                  style={{ marginLeft: "10px" }}
                >
                  <Input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="currently_working"
                    //invalid={inputField.currently_working == ""?error:true}
                    invalid={
                      inputField.currently_working == ""
                        ? inputField.currently_working == ""
                          ? error
                          : true
                        : null
                    }
                    checked={inputField?.is_currently_working}
                    onChange={event => handleInputChange(index, event)}
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Currently Working
                  </label>
                </div>
                {/* <div className="form-group col-12">
                <label htmlFor="start_date">Start Date</label>
                <Input
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  invalid={
                    inputField.start_date == ""
                      ? inputField.start_date == ""
                        ? error
                        : true
                      : null
                  }
                  onChange={event => handleInputChange(index, event)}
                />
              </div> */}

                <Col xs={12} md={6} className="mt-2 ">
                  <label>Start Date</label>
                  <br />
                  {/* <TextField
                  variant="outlined"
                  size="small"
                  // label="Start Date"
                  name="start_date"
                  error={errors.start_date ? true : false}
                  id="first_name"
                  type="month"
                  // defaultValue={userDetails?.first_name}
                  onChange={event => handleInputChange(index, event)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className="form-control"
                  {...register("start_date", { required: true })}
                /> */}

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disableFuture
                      value={startDate ? startDate : null}
                      views={["year", "month"]}
                      onChange={value => handleStartDateChange(value)}
                      renderInput={props => (
                        <TextField
                          {...props}
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={errors.start_date ? true : false}
                          // onChange={event => handleInputChange(index, event)}
                          // InputProps={{
                          //   ...props.InputProps,
                          //   max: maxDate,
                          // }}
                          {...register("start_date", { required: true })}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Col>

                {!inputField?.is_currently_working ? (
                  // <div className="form-group col-12">
                  //   <label htmlFor="end_date">End Date</label>
                  //   <Input
                  //     type="date"
                  //     className="form-control"
                  //     id="end_date"
                  //     name="end_date"
                  //     //invalid={inputField.end_date == ""?error:true}
                  //     invalid={
                  //       inputField.end_date == ""
                  //         ? inputField.end_date == ""
                  //           ? error
                  //           : true
                  //         : null
                  //     }
                  //     onChange={event => handleInputChange(index, event)}
                  //   />
                  // </div>
                  <Col xs={12} md={6} className="mt-2">
                    <label>End Date</label>
                    <br />
                    {/* <TextField
                    variant="outlined"
                    size="small"
                    // label="Start Date"
                    name="end_date"
                    error={errors.end_date ? true : false}
                    type="month"
                    onChange={event => handleInputChange(index, event)}
                    className="form-control"
                    max={maxDate}
                    inputProps={{
                      min: minEndDate,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("end_date", { required: true })}
                  /> */}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        value={endDate ? endDate : null}
                        minDate={minEndDate}
                        views={["year", "month"]}
                        onChange={value => handleEndDateChange(value)}
                        renderInput={props => (
                          <TextField
                            {...props}
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={errors.end_date ? true : false}
                            {...register("end_date", { required: true })}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Col>
                ) : null}
                {inputField?.is_currently_working ? (
                  // <div className="form-group col-12">
                  //   <label htmlFor="noticeperiod">Notice Period</label>
                  //   <Input
                  //     type="number"
                  //     placeholder="Notice Period"
                  //     className="form-control"
                  //     id="noticeperiod"
                  //     name="noticeperiod"
                  //     //invalid={inputField.noticeperiod == ""?error:true}
                  //     //value={inputField.noticeperiod}
                  //     invalid={
                  //       inputField.noticeperiod == ""
                  //         ? inputField.noticeperiod == ""
                  //           ? error
                  //           : true
                  //         : null
                  //     }
                  //     onChange={event => handleInputChange(index, event)}
                  //   />
                  //   <Col xs={12} md={6} className="mt-4">
                  //     <TextField
                  //       variant="outlined"
                  //       size="small"
                  //       label="Notice Period"
                  //       // name="Start_Date"
                  //       error={errors.Notice_Period ? true : false}
                  //       id="first_name"
                  //       type="number"
                  //       onChange={event => handleInputChange(index, event)}
                  //       className="form-control"
                  //       {...register("Notice_Period", { required: true })}
                  //     />
                  //   </Col>
                  //   {noticePerErr !== "" ? (
                  //     <span style={{ color: "red" }}>{noticePerErr}</span>
                  //   ) : null}
                  // </div>
                  <Col xs={12} md={6} className="mt-2">
                    <label>Notice Period</label>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Notice Period in days.."
                      // name="Start_Date"
                      error={errors.notice_period ? true : false}
                      id="first_name"
                      type="number"
                      onChange={event => handleInputChange(index, event)}
                      className="form-control"
                      {...register("notice_period", {
                        required: true,
                        validate: value => value > 0,
                      })}
                    />
                    {noticePerErr !== "" ? (
                      <span style={{ color: "red" }}>{noticePerErr}</span>
                    ) : null}
                  </Col>
                ) : (
                  ""
                )}

                <Col
                  xs={12}
                  className="mt-4"
                  // style={{ height: "10vh", overflowY: "scroll" }}
                >
                  {/* <TextField
                  style={{ width: "100%" }}
                  // id="outlined-multiline-static"
                  label="Description"
                  error={errors.description ? true : false}
                  row={5}
                  {...register("description", { required: true })}
                /> */}

                  <Box>
                    {/* <Textarea
                    placeholder="Type in here…"
                    minRows={2}
                    maxRows={4}
                    // error={errors.description ? true : false}
                    error={Boolean(errors.description)}
                    {...register("description", { required: true })}
                    className={classes.textarea}
                    sx={{
                      borderRadius:"4px !important",
                      borderColor: errors.description ? "red" : "#ced4da",
                    }}
                  /> */}
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Description"
                      error={Boolean(errors.description)}
                      {...register("description", { required: true })}
                      style={{
                        minHeight: "60px",
                        maxHeight: "60px",
                        borderRadius: "8px",
                        borderColor: errors.description ? "red" : "#ced4da",
                      }}
                    />
                  </Box>
                </Col>
              </Fragment>
            ))}
          </div>
          <div
            className="d-flex justify-content-end submit-button mt-4"
            style={{ paddingTop: "20px" }}
          >
            {/* <Button
              type="reset"
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
              onClick={resetForm}
            >
              Reset
            </Button> */}

            <Button
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
              type="submit"
            >
              Save
            </Button>
            <Button
              onClick={() => closeModal()}
              style={{
                margin: "3px",
                background: "rgb(236, 236, 236)",
                color: "rgb(8, 23, 83)",
              }}
            >
              Close
            </Button>
          </div>
        </Form>
      </ThemeProvider>
    </>
  );
}

export default WorkExperience;
