import React ,{useState} from 'react';
import OverViewPage1 from './OverViewPage1';
import OverViewPage2 from './OverViewPage2';
import OverViewPage3 from './OverViewPage3';
import OverViewPage4 from './OverViewPage4';
import CompaniesViews from './CompaniesViews';
import CompanyJobsList from './OverViewPage5';

function CompanyOverview({companyData}){
    return (
        <>
        <section className='overview_section'>
            <div className='over_main_sec'>
              <div className='col-sm-12 col-md-8 col-lg-8 over'>
              <div className='over_inside_sec1'>
                <OverViewPage1  cProfile={companyData}/>
                <OverViewPage2 cProfile={companyData}/>
                <OverViewPage3 cProfile={companyData}/>
                <OverViewPage4 cProfile={companyData}/>
                {/* <CompanyJobsList /> */}
              </div>
              </div> 
    
              <div className='col-sm-12 col-md-4 col-lg-4'>
              <div className='over_inside_sec2' style={{marginLeft:"20px"}}>
                <CompaniesViews />
              </div>
              </div> 

            </div>

        </section>
        </>
    )
}

export default CompanyOverview;