import React, { useEffect,useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import "./Dashboard.css";
import UpcomingInterviews from "./UpcomingInterviews";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { Col, Container, Row } from "reactstrap";
import {getEarning,getEarningTableData,getWithAmount} from "../../../helpers/dataFetcher/profile/update";
import DashBoardMain from "./DashboardMain";
import SocketConnection from "../../../hooks/socket";
import ComingSoon from '../../../Reusable/comingSoon';
function DashBoardIndex() {

  const [moneyData, setMoneyData] = useState()
  const [tableData,setTableData] = useState()
  const [withDrawData, setWithDrawData] = useState()
  const { lastMessage } = SocketConnection();
  const dashboardData = [
    {
      id: 1,
      icon: "fas fa-venus-mars",
      bgcolor: "#ffeed9",
      color: "#ec8505",
      name: "Applied Jobs",
      count: tableData?tableData.length:"0",
    },
    {
      id: 2,
      icon: "fas fa-venus-mars",
      bgcolor: "#cef8da",
      color: "#07e045",
      name: "Referred Jobs",
      count: 80,
    },
    {
      id: 3,
      icon: "fas fa-venus-mars",
      bgcolor: "#ede4fe",
      color: "#5204e5",
      name: "Total Profile Views",
      count: 180,
    },
    {
      id: 4,
      icon: "fas fa-venus-mars",
      color: "rgb(210 1 187)",
      bgcolor: "rgb(255 219 251)",
      name: "interviews Attended",
      count: 280,
    },
    {
      id: 5,
      icon: "fas fa-venus-mars",
      color: "rgb(210 1 187)",
      bgcolor: "rgb(255 219 251)",
      name: "Offers",
      count: 280,
    },
  ];

  useEffect(()=>{
    fetchData()
  },[])

  /*socket integration for dashboard page start */
  
  useEffect(() => {
    if (
      lastMessage?.data !== undefined ||
      lastMessage?.data !== null ||
      lastMessage?.data !== ""
    ) {
      const resultant = lastMessage?.data;
      if (resultant !== undefined) {
        const data = JSON.parse(resultant);
        if (data?.type === "dashboard") {
          fetchData()
        }
      }
    }
  }, [lastMessage]);

  /*socket integration for dashboard page End */

async function getTableData(){
  const {data} = await getEarningTableData()
    console.log("ttt",data.data)
    const finalTableData = data.data?.map(item =>{
      return {
        firstName: item.earnings_user_job_post.first_name,
        lastName: item.earnings_user_job_post.last_name,
        email: item.earnings_user_job_post.email,
        finalCommission: item.final_commisson_received_value
      }
    })
    setTableData(finalTableData)

}
  async function fetchData(){
    getTableData()
    
  }

  return (
    <div className="d-flex align-items-start gap-2 justify-content-center" style={{marginTop:"30px",marginLeft:"58px"}}>
      <div className="profile_left_sidebar sticky_sidebar profile_left_sidebar_sticky zepulScroll">
        <Sidebar />
      </div>
      <div className="col-lg-10 col-md-10 col-sm-11 col-10" style={{marginTop:"10px"}}>
        {/* <ComingSoon /> */}
        <DashBoardMain />
        {/* <Row className="col-sm-12 col-md-10 col-lg-10">
          {dashboardData?.map(item => (
            <Col
              xs={12}
              key={item.id}
              className="col-sm-12 col-md-3 col-lg-3 mb-3"
            >
              <div className="dash-recent-activities p-4">
                <div className="d-flex align-items-center mb-2">
                  <span
                    className="icon-bg-color"
                    style={{
                      backgroundColor: item.bgcolor,
                    }}
                  >
                    <DoneAllIcon />
                  </span>
                  <div
                    className="jobs-count mb-2"
                    style={{ marginLeft: "10px" }}
                  >
                    {item.count}
                  </div>
                </div>
                <div className="dashoard-card-title text-muted text-end">
                  {item.name}
                </div>
              </div>
            </Col>
          ))}
          
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <UpcomingInterviews />
            </div>
          </div>
        </Row>{" "} */}
      </div>
    </div>
  );
}

export default DashBoardIndex;
