import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReferNowModal } from "../JobsList/Alljobs/ReferModal";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy";
import moment from "moment";
import { imageURL } from "../../Constants/commonURLS";
import { Avatar,IconButton } from "@mui/material";

function JobDetails({ jobDetails }) {
  // console.log(jobDetails.createdAt.slice(0, 10), "jobb");
  // const a = new Date(jobDetails?.createdAt?.slice(0, 10));
  // const b = new Date(jobDetails?.application_deadline?.slice(0, 10));

  // const differenceInTime = b.getTime() - a.getTime();
  // const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert milliseconds to days

  // console.log('Difference in days:', differenceInDays);
  const navigate = useNavigate();
  const [ReferStatus, setReferStatus] = useState({
    modal: "",
    type: "",
    action: "",
    modalType: "",
  });
  //TODO I AM NOT GETTING COMPANY ID IN THIS PAGE FIX IT NOT IN API ALSO
  //URL https://backend.zepul.com/api/company/job_post/bd04816e-5176-4782-9cfe-e334fcd71d47

  const abbreviateNumber = number => {
    const scaledNumber = number / 1000;
    return `${scaledNumber.toFixed(0)}k`;
  };

  return (
    <>
      <div className="content-single">
        <div className="d-flex align-items-center gap-4  justify-content-between job_header_border">
          <header className="d-flex gap-4 align-items-center">
            <div>
              <h1 className="job_title_name">{jobDetails?.job_title}</h1>
              <Link
                to={`../employer-single-list/${jobDetails?.company_details?.id}`}
                className="company_name_detail_page mt-2"
              >
                <i className="bx bx-buildings"></i>
                {jobDetails?.company_details?.company_name}
              </Link>
            </div>
          </header>
          <div>
            <p className="job_secondary_details">
              <span className="commission_label">Commission: </span>

              {/* <span>{jobDetails?.commission_rate}</span> */}
              {/* <span style={{ paddingLeft: "13px", fontWeight: 600 }}>
                Upto{" "}
                {jobDetails?.commission_rate_type === "Percentage"
                  ? `${jobDetails?.currency} ${
                      (Number(jobDetails?.max_sal) /
                        Number(jobDetails?.commission_rate)) *
                      100
                    } `
                  : `${jobDetails?.commission_rate} ${jobDetails?.currency}`}
              </span> */}
              <span style={{ paddingLeft: "13px", fontWeight: 600 }}>
                Upto{" "}
                {jobDetails?.commission_rate_type === "Percentage"
                  ? `${jobDetails?.currency} ${abbreviateNumber(
                      (Number(jobDetails?.max_sal) /
                        Number(jobDetails?.commission_rate)) *
                        100
                    )} `
                  : `${abbreviateNumber(jobDetails?.commission_rate)} ${
                      jobDetails?.currency
                    }`}
              </span>
            </p>

            <p className="job_secondary_details">
              {/* <span
                className="commission_label"
                style={{ paddingLeft: "13px" }}
              >
                Created At:{" "}
              </span> */}
              {/* <span>Posted {jobDetails?.createdAt?jobDetails.createdAt:"6"} days ago</span> */}
              {/* {formatDistance(new Date(jobDetails?.createdAt), new Date(), {
                addSuffix: true,
              })} */}
              {/* <span>{jobDetails?.createdAt?jobDetails.createdAt.slice(0,10):""}</span> */}
              {/* <span>
                {jobDetails?.createdAt
                  ? moment(jobDetails.createdAt).format("DD-MM-YYYY")
                  : ""}
              </span> */}
            </p>
            <div className="d-flex align-items-center gap-4 mt-3">
              <CssVarsProvider>
                <Button
                  variant="outlined"
                  style={{
                    backgroundColor: "#081753",
                    color: "#ffffff",
                    borderColor: "#081753",
                  }}
                  onClick={() => {
                    setReferStatus({
                      modal: "center",
                      details: {
                        ...jobDetails,
                        id: jobDetails?.id,
                        company_name: jobDetails?.company_details?.company_name,
                      },
                      from: "details",
                      purpose: "refer",
                    });
                  }}
                >
                  Refer Now
                </Button>
              </CssVarsProvider>
              <CssVarsProvider>
                <Button
                  variant="solid"
                  style={{
                    background: "rgb(236, 236, 236)",
                    color: "rgb(8, 23, 83)",
                    border: "1px solid rgba(8, 23, 83, 0.1)",
                  }}
                  onClick={() => {
                    setReferStatus({
                      modal: "center",
                      details: {
                        ...jobDetails,
                        id: jobDetails?.id,
                        company_name: jobDetails?.company_details?.company_name,
                        company_user_id: jobDetails?.company_details?.id,
                      },
                      from: "details",
                      purpose: "self",
                    });
                  }}
                >
                  Apply Self
                </Button>
              </CssVarsProvider>
            </div>
          </div>
        </div>
        <h1 className="label_container">Employement Information</h1>
        <div className="job_skill_details job_additonal_detail_page">
          <div>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-briefcase-alt"></i>
                <span>Experience</span>
              </span>

              <span className="exp_details">
                {jobDetails?.min_exp}-{jobDetails?.max_exp} Years
              </span>
            </section>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-wallet"></i>
                <span>Salary</span>
              </span>
              <span className="exp_details">
              {jobDetails?.currency}{" "}{jobDetails?.min_sal}-{jobDetails?.max_sal}{" "}
               
              </span>
            </section>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-target-lock"></i> <span>Location</span>
              </span>
              <span className="exp_details">
                {jobDetails?.job_type === "Fully_Remote"
                  ? jobDetails?.time_zone.join(",")
                  : jobDetails?.location}
              </span>
            </section>
          </div>

          <div>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-user-voice"></i> <span>JobType</span>
              </span>
              <span className="exp_details">
                {jobDetails?.job_type?.replace("_", " ")}
              </span>
            </section>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-time-five"></i>
                <span>Deadline</span>
              </span>
              {/* <span className="exp_details">
                {jobDetails?.application_deadline
                  ? jobDetails.application_deadline.slice(0, 10)
                  : ""}
                {
                  (formatDistance(new Date(new Date()), new Date()),
                  { addSuffix: true })
                }
                {Math.floor(
                              Math.abs(
                                new Date() -
                                  new Date(
                                    jobDetails?.createdAt
                                      .slice(0, 10)
                                      .toLocaleString()
                                      .replace(/-/g, "/")
                                  )
                              ) /
                                (1000 * 3600 * 24)
                            )}
              </span> */}
              <span className="exp_details">
                {jobDetails?.application_deadline
                  ? moment(jobDetails.application_deadline).format("DD-MM-YYYY")
                  : ""}
              </span>
            </section>
            <section className="descritption_addional_main">
              <span>
                <i className="bx bx-laptop"></i>
                <span>Work Mode</span>
              </span>
              <span className="exp_details">
                {jobDetails?.work_mode?.replace("_", " ")}
              </span>
            </section>
            {/* <section className="descritption_addional_main">
              <span>
                <i className="bx bx-file"></i>
              </span>
              <span className="exp_details">view more</span>
            </section> */}
          </div>
        </div>
        <section className="d-flex gap-2 mb-4 align-items-center">
          <span className="">
            <i className="bx bxs-pen"></i>
            <span>Skills</span>
          </span>
          <br />
          {jobDetails?.key_skills_details?.map(item => {
            return (
              <span className="chip_perk" key={item.id}>
                {item?.name}
              </span>
            );
          })}
        </section>
        <h1 className="label_container">Full Job Description</h1>
        <div
          className="job_description_contenet"
          dangerouslySetInnerHTML={{ __html: jobDetails?.job_desc }}
        ></div>
      </div>

      <ReferNowModal
        show_desc={false}
        purpose={ReferStatus.purpose}
        show={ReferStatus.modal}
        onHide={modal =>
          setReferStatus({
            modal: modal,
            modalType: "",
            type: "",
            from: "",
            details: {},
          })
        }
        params={ReferStatus}
      />
    </>
  );
}

export default JobDetails;
