import React, { useState } from "react";
import Dropzone from "react-dropzone";
import CropModal from "./cropModel";
import { Col, Row, Card, Form } from "reactstrap";
import Modal from "@mui/material/Modal";
import "./styles.css";
import { Button, CssVarsProvider, ModalDialog } from "@mui/joy";
import FeatherIcon from "feather-icons-react";

function UploadFileModal(props) {
  const { isOpen, toggle, handleImage } = props;

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [crop, setCrop] = useState(false);

  const handleAcceptedFiles = files => {
    if (files.length === 1) {
    }
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        crop: false,
      })
    );

    setSelectedFiles(files);
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  function handleCrop() {
    setCrop(false);
  }

  return (
    <Modal
      sx={{
        "& .css-1htn9tb-JoySheet-root-JoyModalDialog-root": {
          background: "white",
          height: "400px",
          // width: "500px",
        },
      }}
      open={!!isOpen}
    >
      <ModalDialog
        size="lg"
        aria-labelledby="layout-modal-title"
        aria-describedby="layout-modal-description"
        layout={"center" || undefined}
      >
        {selectedFiles.length > 0 ? (
          <CropModal
            isOpen={crop}
            toggle={() => {
              setSelectedFiles([]);
              toggle(!isOpen);
            }}
            imageSrc={selectedFiles[0].preview}
            type={selectedFiles[0].type}
            handleImage={handleImage}
          />
        ) : (
          <>
            <div className="modal-header upload_model_header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Image Upload
              </h5>
              <button
                type="button"
                onClick={() => toggle(!isOpen)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form>
                <Dropzone
                  onDrop={acceptFiles => handleAcceptedFiles(acceptFiles)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <FeatherIcon icon="upload" size="14" />

                        <p className="drop_text">
                          Drop files here or click to upload.
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="dropzone-previews mt-3" id="file-previews">
                  {selectedFiles.map((f, i) => {
                    return (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              {selectedFiles[0].type.startsWith("image/") ? (
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-sm rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col>
                              <p className="text-muted font-weight-bold">
                                {f.name}
                              </p>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    );
                  })}
                </div>
              </Form>
            </div>
            <div className="modal-footer upload_model_header gap-2">
              <CssVarsProvider>
                <Button
                  variant="solid"
                  type="button"
                  onClick={() => {
                    toggle(!isOpen);
                  }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{
                    margin: "3px",
                    background: "rgb(236, 236, 236)",
                    color: "rgb(8, 23, 83)",
                  }}
                >
                  Close
                </Button>
              </CssVarsProvider>
              <CssVarsProvider>
                <Button
                  variant="solid"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    if (selectedFiles[0].type.startsWith("image/")) {
                      setCrop(!crop);
                      return;
                    }
                    handleImage(selectedFiles);
                  }}
                  style={{
                    margin: "3px",
                    background: "rgb(236, 236, 236)",
                    color: "rgb(8, 23, 83)",
                  }}
                >
                  Save changes
                </Button>
              </CssVarsProvider>
            </div>
          </>
        )}
      </ModalDialog>
    </Modal>
  );
}

export default UploadFileModal;
