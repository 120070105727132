import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";
import { Constants } from "../../Constants/Constants";
import "./RecentJobs.css";
import EastIcon from "@mui/icons-material/East";
import { Design } from "./LatestJobs/Design";
import Button from "@mui/joy/Button";
import { CssVarsProvider } from "@mui/joy/styles";
import RemoteJob from "../../assets/images/remoteJob.jpg";
import Remote from "../../assets/images/remote.jpg";
import SkeletonRecentJobs from "./LatestJobs/SkeletonRecentJobs";
import { Link, useSearchParams } from "react-router-dom";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

function RecentJobs({ jobs, loading, functionalArea }) {
  const navigate = useNavigate()
  const [jobsList, setJobsList] = useState();
  const [modal, setModal] = useState(false);
  const handleClose = () => setModal(!modal);
  const [selectedButton, setSelectedButton] = useState(null);

  const handleButtonClick = buttonValue => {
    setSelectedButton(buttonValue === selectedButton ? null : buttonValue);
  };

  useEffect(() => {
    setJobsList(jobs);
  }, [jobs]);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [right, setRight] = useState(0);
  // useEffect(() => {
  //   async function scrollData() {
  //     const data = document.querySelector(".scrollable_fun_areas");
  //     setRight(data.scrollWidth - data.clientWidth);

  //     setScrollLeft(data.scrollLeft);
  //   }
  //   scrollData();
  //   const data = document.querySelector(".scrollable_fun_areas");
  //   data.addEventListener("scroll", scrollData);
  // });
  const [searchParams, _] = useSearchParams();

  return (
    <>
      <section className="section-box">
        <div className="latest_jobs_cont_main">
          {/* <div className="row home_category_head">
            <div className="col-lg-7">
              <h2 className="latest_jobs_head">
                Latest <span>Jobs</span>
              </h2>
            </div>
            <div
              className="col-lg-5 text-lg-end justify-content-end text-start wow animate__animated animate__fadeInUp"
              data-wow-delay=".2s"
            >
              <Button className="">
                  <Link
                    to="/jobs-list"
                    color="primary"
                    style={{ color: "white" }}
                  >
                    Show all <EastIcon />
                  </Link>
                </Button>
            </div>
          </div> */}

          <h2 className="latest_jobs_head">
            Filter Jobs by <span>Various Trending Jobs</span>
          </h2>
          {/* <div className="row func_list_li">
            <div className="blog_category">
              {scrollLeft > 0 && (
                <KeyboardArrowLeft
                  className="left"
                  onClick={() => {
                    const data = document.querySelector(
                      ".scrollable_fun_areas"
                    );

                    data.scrollLeft = scrollLeft - 200;
                    // console.log(data.scrollLeft);
                  }}
                />
              )}
              <ul className="scrollable_fun_areas" role="tablist">
                {functionalArea.map(each => {
                  return (
                    <li className="fun_area_container">
                      <Link
                        to={`/?q=${each.id}`}
                        className={`fun_areas ${
                          searchParams.get("q") === each.id ? "fun_active" : ""
                        }`}
                      >
                        <span className="fun_area_names">{each.name}</span>
                        {each.count > 0 ? (
                          <span className="job_counts">{each?.count}</span>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                  );
                })}
                <span
                  onClick={() => {
                    setModal(!modal);
                  }}
                  style={{
                    color: "grey",
                    fontWeight: "bold",
                    fontSize: "25px",
                  }}
                ></span>
              </ul>
              {right - scrollLeft > 10 && (
                <KeyboardArrowRight
                  className="right"
                  onClick={() => {
                    const data = document.querySelector(
                      ".scrollable_fun_areas"
                    );

                    data.scrollLeft = scrollLeft + 200;
                    // console.log(data.scrollLeft);
                  }}
                />
              )}
            </div>
            <Col xs={9} />
            <Col xs={3} className="mt-4 justify-self-end showAllbtn">
              <CssVarsProvider>
                <Button className="">
                  <Link
                    to="/jobs-list"
                    color="primary"
                    style={{ color: "white" }}
                  >
                    Show all <EastIcon />
                  </Link>
                </Button>
              </CssVarsProvider>
            </Col>
          </div> */}

          <div className="func_areas_btns_section">
            <div className="func_areas_inside_sec">
            <div className="func_areas_main_section">
              {functionalArea.slice(0,5).map((each,index) => {
                return (
                
                  <Link
                  variant="solid"
                  key={index}
                  to={`/?q=${each.id}`}
                 
                  className={`fun_areas ${
                    searchParams.get("q") === each.id ? "fun_active" : ""
                  }`}
                  onClick={() => handleButtonClick(each.id)}
                  style={{
                    backgroundColor:
                      selectedButton === each.id ? "#081753" : "#FFFFFF",
                    color: selectedButton !== each.id ? "#081753" : "#FFFFFF",
                    border: "1px solid rgba(8, 23, 83, 0.15)",
                    borderRadius:"8px",
                    fontFamily:"Inter, sans-serif"
                  }}
                >
                {each.name}
                </Link>
                )
              })}
              {functionalArea.length >5 ? <span className="more_func_count"  onClick={()=>navigate("../jobs-list")}>+{functionalArea.length -6}More..</span>:null}
            </div>
            </div>
          </div>

          <div className="mt-20">
            <div className="tab-content" id="myTabContent-1">
              <div
                className="tab-pane fade show active"
                id="tab-one-1"
                role="tabpanel"
                aria-labelledby="tab-one-1"
              >
                {!loading ? (
                  <Design recentJobs={jobsList} />
                ) : (
                  <SkeletonRecentJobs />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default RecentJobs;
