import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import OwlCarousel from "react-owl-carousel3";
import Button from "@mui/joy/Button";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import culture from "../../../assets/images/culture.png";

function OverViewPage4({ cProfile }) {
  let array1 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      gap: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="overView_sec2">
        <h2 className="benefits_txt">Cultural Images</h2>
        {cProfile?.culture_media_images ? (
          <div className="company_benefits_carousal">
            <Carousel responsive={responsive}>
              {cProfile?.culture_media_images.map((each, index) => (
                <div className="" key={index}>
                  <div className="company_inside_sect">
                    <img src={each} />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : (
          <div className="overView_sec2">
            <div className="comp_about_card">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: "15px",
                }}
              >
                No data found
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OverViewPage4;
