import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/joy/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

function ZepulTrustDesc() {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <div className="trust_main_section" style={{padding:0}}>
        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="refer_partition_sec" style={{backgroundColor:"#081753",borderRadius:"8px",margin:"10px"}}>
                <div className="refer_sec1">
                  <h2 className="refer_text_content_head">
                    Refer- <span className="refer_text_content">Earn</span><span className="">-Relax</span>
                  </h2>
                  {/* <h2 className="refer_text_content_head">Relax</h2> */}
                  <p className="refer_para_content">
                    {/* We pay you for referring colleagues, friends and peers to
                    jobs.
                    <br /> Refer anyone you know and as many people as you want
                    and get rewarded for every successull referral */}
                    Refer suitable candidates to active jobs on the portal and earn rewards on successful hire.
                  </p>
                </div>
               
              </div>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-6">
          <div className="trust_part2">
            <Box sx={{ width: "100%" }}>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <div className="btn_container1">
                    <div className="btn_cont">
                      <h2 className="trust_txt1" style={{fontSize:"25px",fontWeight:"bold"}}>2800+<br/><span style={{fontSize:"16px"}}>Job Offers</span></h2>
                      {/* <p className="jobs_posted_txt">An account manager is tagged to handle your queries</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="btn_container2">
                    <div className="btn_cont">
                      {/* <h2 className="trust_txt1">150+ Companies</h2> */}
                      <h2 className="trust_txt1" style={{fontSize:"25px",fontWeight:"bold"}}>150+<br/><span style={{fontSize:"16px"}}>Companies</span></h2>
                      {/* <p className="jobs_posted_txt">Post limitless job on the platform absolutely free</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6} style={{ paddingTop: "20px" }}>
                  <div className="btn_container3">
                    <div className="btn_cont">
                      {/* <h2 className="trust_txt1">$6.8 Mn Savings</h2> */}
                      <h2 className="trust_txt1" style={{fontSize:"25px",fontWeight:"bold"}}>$6.8 Mn<br/><span style={{fontSize:"16px"}}>Savings</span></h2>
                      {/* <p className="jobs_posted_txt">Zepul maintains end-to-end transparency throughout the hiring process</p> */}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="btn_container4">
                    <div className="btn_cont">
                      {/* <h2 className="trust_txt1">92% Acceptance</h2> */}
                      <h2 className="trust_txt1" style={{fontSize:"25px",fontWeight:"bold"}}>92% <br/><span style={{fontSize:"16px"}}>Acceptance</span></h2>
                      {/* <p className="jobs_posted_txt">Companies are obligated to pay after the end of probation period</p> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}

export default ZepulTrustDesc;
