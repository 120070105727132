export const findAllJobsModel = item => {
  return item.map(data => {
    return {
      id: data.id,
      image: data.company_details?.company_image,
      job_title: data.job_title,
      job_type: data.job_type,
      job_desc: data.job_desc,
      application_deadline: data.application_deadline,
      company_name: data.company_details?.company_name,
      company_id:data.company_details?.id,
      commission_rate_type: data.commission_rate_type,
      commission_rate: data.commission_rate,
      company_user_id: data?.company_user_id,
      min_sal: data.min_sal,
      max_sal: data.max_sal,
      location: data.location,
      currency: data.currency,
      work_mode: data.work_mode,
      time_zone: data.time_zone,
      min_exp: data.min_exp,
      max_exp: data.max_exp,
      key_skill_details: data.key_skill_details,
      createdAt: data.createdAt,
      no_of_vacancies: data.no_of_vacancies,
      is_candidate_applied_to_job_post:data.is_candidate_applied_to_job_post,
      is_candidate_saved_the_job_post:data.is_candidate_saved_the_job_post
    };
  });
};

export const findAllFunctionalAreas = item => {
  return item.map(each => {
    return {
      id: each.id,
      name: each.name,
      countOfJobs: each.job_posts_count,
      isChecked: false,
    };
  });
};

export const findAllJobTypes = item => {
  return item.map((each, i) => {
    return {
      id: i,
      jobType: each.job_type,
      jobPostsCount: each.job_posts_count,
      isChecked: false,
    };
  });
};
export const findSalaryRanageModel = item => {
  return item.map((each, i) => {
    return {
      id: i,
      salaryRange: each.sal,
      salaryValue: each.sal_value,
      jobsCountOnSalary: each.job_posts_count,
      isChecked: false,
    };
  });
};

export const findAllKeySkills = item => {
  return item.map(each => {
    return {
      id: each.id,
      skill: each.name,
      jobsCount: each.job_posts_count,
      isChecked: false,
    };
  });
};

export const findAllWorkModes = item => {
  return item.map(each => {
    return {

      workModeValue: each.dbValue,
      workModeShown:each.shownValue,
      workModeCount: each.work_mode_count,
      isChecked: false,
    };
  });
};

export const findBlogsModel = item => {
  return item.map(each => {
    return {
      id: each.id,
      img: each.image,
      name: each.title,
      desc: each.description,
    };
  });
};
