import React, { useEffect, useState } from "react";
import {
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { getAllCompanyMenList } from "../../helpers/dataFetcher/chats/chats";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import "../../assets/css/chat/chat.css";
import startChat from "../../assets/images/start_chat.svg";
import { formatDistance } from "date-fns";
import { findChatBasedOnId } from "../../helpers/dataFetcher/chats/chats";
import { get_chat_active_user_list } from "../../helpers/sockets";
import { imageURL } from "../../Constants/commonURLS";
import { blue } from "@mui/material/colors";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { globalTheme } from "../../assets/MUI/globalStyles";
import { ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import JobDrawer from "../Notifications/JobDrawer";
import EyeJobDrawer from "../Notifications/EyeJobDrawer";
import { StyledInputBase } from "../../assets/MUI/globalStyles";
import { SearchContainer } from "../../assets/MUI/globalStyles";
import { SearchIconWrapper } from "../../assets/MUI/globalStyles";
import chatImg from "../../assets/images/chat.svg";
import SocketConnection from "../../hooks/socket";
import SkeletionLoader from "../../Loader/SkeletionLoader";
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    height: 48,
    width: "100% ",
    background: "#ffffff",
    border: "1px solid rgba(0, 0, 0, 0.1) !important",
    borderRadius: "12px !important",
    transitionDelay: "0.5ms",
    color: "rgba(0, 0, 0, 0.5)",
    fontFamily: "Inter , sans-serif !important",
    "& input::placeholder": {
      fontFamily: "Inter , sans-serif !important",
      fontWeight: 500,
      fontSize: 14,
      fontStyle: "normal",
      color: "rgba(0, 0, 0, 0.5)",
    },
    //   "& input": {
    //       padding: "12px 14px",
    //       borderRadius: 8,
    //     },
    "& .PrivateNotchedOutline-root-1": {
      display: "none !important",
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  fieldSet: {
    width: "88%",
    "&.MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.PrivateNotchedOutline-root-5": {
      borderWidth: 0,
    },
    "& fieldset": {
      borderWidth: 0,
    },
  },
  avatar: {
    background: "rgba(192, 223, 250, 0.4) !important",
    "& .css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)  !important",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  avatar1: {
    background: "rgba(192, 223, 250, 0.4) !important",
    borderRadius: "36px",
    "&.css-2s90m6-MuiAvatar-root": {
      color: "rgba(0, 0, 0, 0.7)",
      fontFamily: "Inter , sans-serif !important",
      fontSize: "20px !important",
    },
  },
  chip: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#006064",
      backgroundColor: "rgba(77, 208, 225, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
      textTransform: "capitalize",
    },
  },
  chip2: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#B84040",
      backgroundColor: "rgba(204, 51, 51, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  chip3: {
    "&.css-w66kx-MuiChip-root": {
      height: "auto !important",
      marginLeft: "4px",
      color: "#827717",
      backgroundColor: "rgba(220, 231, 117, 0.5) !important",
      fontFamily: "Inter , sans-serif !important",
      fontStyle: "normal",
      fontWeight: "500 !important",
      fontSize: "11px !important",
    },
  },
  address: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
  name: {
    display: "inline-block",
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "700 !important",
    fontSize: "14px !important",
    color: "#000000 !important",
  },
  msg: {
    fontFamily: "Inter , sans-serif !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "13px !important",
    color: "rgba(0, 0, 0, 0.7) !important",
  },
});

const shapeStyles = {
  background: "rgba(39, 174, 96, 0.31)",
  width: 25,
  height: 25,
  marginLeft: "5px",
};
const shapeCircleStyles = { borderRadius: "50%" };
const rectangle = <Box component="span" sx={shapeStyles} />;
const circle = (
  <Box component="span" sx={{ ...shapeStyles, ...shapeCircleStyles }} />
);

function DemoChatSidebarDummy({
  setReceiverId,
  receiverId,
  setSelectedChat,
  setSenderId,
  chat,
  setJobList,
  //   userJobDetails,
  setSeshuReceiverId,
  setHideJobSec,
  jobDetails,
  setJobPostId,
  userJobPostId,
  setChannelId,
  setUserJobPostId,
  setSelectedChannelDetails,
  setChannelsPage,
  channelsPage,
  playMessageSentSound,
  selectedChannelData
}) {
  const classes = useStyles();
  const [activeUsers, setActiveUsers] = useState([]);
  const navigate = useNavigate();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [searchTxt, setSearchTxt] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [loading,setLoading] = useState(true)
  const [read,setRead] = useState({id:''})
  // const { id1, id2 } = useParams();
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const searchParams = new URLSearchParams(window.location.search);
  const id1 = searchParams.get("id1");
  const id2 = searchParams.get("id2");

  const userJobDetails = {
    user_job_post_id: id1,
    job_post_id: id2,
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const inputWidth = isFocused ? "80%" : "45%";

  async function getActiveUsers() {
    const data = await get_chat_active_user_list();
    setActiveUsers(data?.data);
  }

  /* Required state for company members page start here @seshu */
  const [companyMembers, setCompanyMembers] = useState([]);
  const [companyMemList, setCompanyMemList] = useState([]);

  /* Required state for company members page End here @seshu */

  /* Company Menbers list display start Here@seshu */

  useEffect(() => {
    setLoading(true)
    getCompanyMembers(userJobDetails);
  }, []);

  useEffect(() => {
    setLoading(true)
    getCompanyMembers(userJobDetails);
  }, [read]);

  async function getCompanyMembers(userJobDetails) {
    const getCompanyMemList = await getAllCompanyMenList(userJobDetails);
    // const sortedCompanyList = getCompanyMemList?.data?.data?.sort((a,b)=>new Date(b.updatedAt)- new Date(a.updatedAt))

    const fetchData = getCompanyMemList?.data?.data?.map(each => {
      return {
        ...each,
        time: new Date(each.updatedAt).toLocaleString(),
      };
    });
 
    const sortedCompanyList = fetchData?.sort((a, b) => {
      const dateA = moment(a.time, 'DD/MM/YYYY, HH:mm:ss')
      const dateB = moment(b.time, 'DD/MM/YYYY, HH:mm:ss')
      return dateB - dateA
    })
    setLoading(!loading)
    console.log(sortedCompanyList,"sortedCompanyList")
    setCompanyMembers(sortedCompanyList);
    setCompanyMemList(sortedCompanyList);
    // setCompanyMembers(getCompanyMemList?.data?.data);
    // setCompanyMemList(getCompanyMemList?.data?.data);
    setChannelsPage(false);
   
  }

  const sendReceiverId = async id => {
    setSeshuReceiverId(id);
  };

  const sendRequiredIds = async each => {
    setJobPostId(each.job_post_id);
    userJobPostId(each.user_job_post_id);
    setReceiverId(each.receiver_id);
  };
  /* Company Menbers display End Here@seshu */

  /* Search By company member Chat list code start here @seshu */
  const handleSearchTextChange = event => {
    const value = event.target.value;
    // setSearchTxt(value);
    if (value.trim() !== "") {
      const filteredRows = companyMembers?.filter(each => {
        const firstName =
          each?.ucc_sender_id?.first_name?.toLowerCase() || "";
        const lastName = each?.ucc_sender_id?.last_name.toLowerCase() || "";
        const fullName = `${firstName} ${lastName}`.toLowerCase();

        return (
          firstName.includes(value.toLowerCase()) ||
          lastName.includes(value.toLowerCase()) ||
          fullName.includes(value.toLowerCase()) ||
          fullName.replace(" ", "").includes(value.toLowerCase())
        );
      });
      setCompanyMembers(filteredRows);
    } else {
      setCompanyMembers(companyMemList);
    }
  };

  /* Search By Company member Chat list code End here @seshu */

  useEffect(() => {
    const data = companyMemList[0];
    const sortedData = companyMemList?.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.updatedAt);
    });

    handleSendChannelId(sortedData[0]);
  }, []);

  /* send channel ID start here @seshu */
  const handleSendChannelId = async each => {
    setRead({ id: each.id })
    setSelectedChannelDetails(each);
    setChannelId({ id: each.id });
    // setReceiverId({ id: each.ucc_receiver_id?.id });
    if (each.sender_type === "REFERAL_PARTNER") {
      setReceiverId({ id: each.ucc_receiver_id?.id });
    } else {
      setReceiverId({ id: each.ucc_sender_id?.id });
    }

    setJobPostId({ id: each.job_post });
    setUserJobPostId({ id: each.user_job_post });
  };

  /* Send channel ID End here @seshu */

  /* Socket Last message code */
  useEffect(() => {}, [connectionStatus]);
  useEffect(() => {
    // playMessageSentSound()
    if (lastMessage) {
      getCompanyMembers(userJobDetails);
    }
    
  }, [lastMessage]);
  useEffect(() => {
    getCompanyMembers(userJobDetails);
  }, [channelsPage]);

  /* Socket Last message code */

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="demochat_side_main">
        <div className="demochat_side_inside_cont">
          <div style={{ position: "sticky" }}>
            <div
              className="selected_job"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <KeyboardArrowLeftIcon
                onClick={() => {
                  navigate("../demochat");
                }}
                style={{ marginTop: "10px" }}
              />
              <ListItemButton
                alignItems="flex-start"
                sx={{
                  background: "#E6EFF7 !important",
                  marginTop: "15px !important",
                  borderRadius: "8px !important",
                  width: "100%",
                  padding: "8px !important",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt="M"
                    src={`${imageURL}${jobDetails?.company_details?.company_image}`}
                    className={classes.avatar1}
                    loading="lazy"
                    sx={{
                      background: "rgba(192, 223, 250, 0.4) !important",
                      color: "rgba(0, 0, 0, 0.7) !important",
                    }}
                  />
                </ListItemAvatar>
                <div className="names_link_cont">
                  <div className="d-flex flex-column">
                    <Typography className={classes.name}>
                      {jobDetails?.job_title}
                    </Typography>
                    <Typography className={classes.msg}>
                      {jobDetails?.company_details?.company_name}
                    </Typography>
                  </div>
                  <div className="d-flex flex-column align-items-end">
                    <EyeJobDrawer
                      isDrawerOpen={isDrawerOpen}
                      setDrawerOpen={setDrawerOpen}
                      jobDetailsData={jobDetails}
                    />
                  </div>
                </div>
              </ListItemButton>
            </div>

            <div className="chats_name_count">
              <div
                style={{ gap: "16px", alignItems: "center" }}
                className="d-flex flex-row pt-1 pl-3"
              >
                <h2 className="chats_name1">Chats</h2>
                <Badge
                  badgeContent={companyMembers?.length}
                  slotProps={{
                    badge: {
                      style: {
                        backgroundColor: "#64db97f2",
                        width: "20px",
                        height: "20px",
                        color: "#081753",
                        fontSize: "10px",
                        fontWeight: 500,
                      },
                    },
                  }}
                />
              </div>

              <ThemeProvider theme={globalTheme}>
                <TextField
                  placeholder="Search..."
                  // style={{ width: "90%", marginLeft: "20px" }}
                  style={{
                    width: inputWidth,
                    marginLeft: "20px",
                    marginTop: "5px",
                  }}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleSearchTextChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </ThemeProvider>
            </div>
          </div>

          <div className="list_scroll2">
            {/* {loading?<SkeletionLoader />: */}
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {companyMembers?.length > 0 ? (
                companyMembers?.map(each => (
                  <>
                    <div
                      className="company_chat_list"
                      key={each.id}
                      //   onClick={() => handleSendChannelId(each)}
                    >
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          sendReceiverId(each.id);
                          handleSendChannelId(each);
                          $(".chatbox").addClass("showbox");
                          if (windowWidth <= 992) {
                            $(".jobbox").removeClass("hideJobBox");
                          }
                        }}
                      >
                        <ListItemButton
                          alignItems="flex-start"
                          style={{ display: "flex", alignItems: "center" }}
                          sx={{ width: "100%", padding: "10px !important" }}
                          // onClick={() => {
                          //   sendReceiverId(each.id), handleSendChannelId(each);
                          // }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              // alt={`${each?.ucc_sender_id?.first_name.charAt(
                              //   0
                              // )}${each?.ucc_sender_id?.last_name.charAt(0)}`}
                              alt={`${
                                each?.sender_type == "REFERAL_PARTNER"
                                  ? each?.ucc_receiver_id.first_name.charAt(0)
                                  : each?.ucc_sender_id.first_name.charAt(0)
                              }`}
                              src={
                                each?.sender_type == "REFERAL_PARTNER"
                                  ? `${imageURL}${each?.ucc_receiver_id?.image}`
                                  : `${imageURL}${each?.ucc_sender_id?.image}`
                              }
                              className={classes.avatar1}
                              sx={{
                                background:
                                  "rgba(192, 223, 250, 0.4) !important",
                                color: "rgba(0, 0, 0, 0.7) !important",
                              }}
                            />
                          </ListItemAvatar>
                          <div
                            className="names_link_cont"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div className="d-flex flex-column">
                              <span className={classes.name}>
                                {/* {each?.ucc_receiver_id?.first_name}{" "}
                                {each?.ucc_receiver_id?.last_name} */}
                                {each.sender_type == "REFERAL_PARTNER"
                                  ? each?.ucc_receiver_id?.first_name +
                                    each?.ucc_receiver_id?.last_name
                                  : each?.ucc_sender_id?.first_name +
                                    each?.ucc_sender_id?.last_name}
                                {/* <span>
                                  <Chip
                                    label={`${each?.ucc_receiver_id?.usertype.replace(
                                      "_",
                                      " "
                                    )}`}
                     
                                    className={`${
                                      each?.ucc_receiver_id?.usertype.replace(
                                        "_",
                                        " "
                                      ) == "ACCOUNT MANAGER"
                                        ? classes.chip
                                        : each?.ucc_receiver_id?.usertype.replace(
                                            "_",
                                            " "
                                          ) == "COMPANY"
                                        ? classes.chip2
                                        : each?.ucc_receiver_id?.usertype.replace(
                                            "_",
                                            " "
                                          ) == "RECRUITING COMPANY"
                                        ? classes.chip3
                                        : ""
                                    }`}
                                  />
                                </span> */}
                              </span>
                              <Typography className={classes.msg}>
                                {each?.last_message?.last_message
                                  ? each.last_message.last_message.slice(
                                      0,
                                      15
                                    ) + "..."
                                  : ""}
                              </Typography>
                            </div>
                            <div className="d-flex flex-column align-items-end">
                              {each?.last_message?.sender_details?.usertype !== "REFERAL_PARTNER" ?
                              <Badge
                                sx={{ mr: 1 }}
                                badgeContent={each?.unread_messages_count || 0}
                                slotProps={{
                                  badge: {
                                    style: {
                                      backgroundColor: "#64db97f2",
                                      width: "20px",
                                      height: "20px",
                                      color: "#081753",
                                      fontSize: "10px",
                                      fontWeight: 600,
                                      // backdropFilter:"opacity(0.2)"
                                    },
                                  },
                                }}
                              />
                              :""}
                            </div>
                          </div>
                        </ListItemButton>
                      </a>
                    </div>

                    <Divider variant="inset" component="li" />
                  </>
                ))
              ) : (
                <div className="">
                  <div
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center gap-2 pt-4 mt-3">
                      <div className="contact_no_show">
                        <img src={chatImg} alt="chat" />
                        <p>No Chats Found</p>
                        {/* <span className="pat-20 end_to_end">
                          End to End Encrypted
                        </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </List>
             {/* } */}

          </div>
        </div>
      </div>
    </>
  );
}

export default DemoChatSidebarDummy;
