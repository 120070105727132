import React, { useState } from "react";
import Navbar from "./Navabar/index";
import Footer from "./footer/Footer";
import SignUpForJobs from "../pages/JobsList/SignUpForJobs/SignUpForJobs";

export default function LayoutUI({ children }) {
  return (
    <>
      <Navbar />
      <main className="">{children}</main>
      {/* <SignUpForJobs /> */}
      {/* <Footer /> */}
    </>
  );
}
