import React, { useState,useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import OwlCarousel from "react-owl-carousel3";
import zIndex from "@mui/material/styles/zIndex";
import Avatar from "@mui/joy/Avatar";
import FormLabel from "@mui/joy/FormLabel";
import Radio, { radioClasses } from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { KeyboardArrowRight, KeyboardArrowLeft } from "@mui/icons-material";
import RightArrow from "../../../Reusable/Arrows/RightArrow";
import { ArrowProps } from 'react-multi-carousel/lib/types'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'


const useStyles = makeStyles({
  arrow: {
    zIndex: 0,
    "& .react-multiple-carousel__arrow--left": {
      left: "-1px", // Adjust the value as needed
      // Add any other necessary styles for the left arrow
    }
  },
 
});
function CompanyIndustyrCardsCarousal(props) {
  const classes = useStyles();
  const [selectedIndustry, setSelectedIndustry] = useState("IT Industry");
 const [defaultCheck,setDefaultCheck] = useState("")

 useEffect(()=>{
  setDefaultCheck("")
 },[props?.industryLists])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3001 },
      items: 6,
      gap: 1,
    },
    desktop2: {
      breakpoint: { max: 3000, min: 1601 },
      items: 5,
      gap: 1,
    },
    desktop1: {
      breakpoint: { max: 1600, min: 1024 },
      items: 5,
      gap: 1,
    },
    tablet2: {
      breakpoint: { max: 1024, min: 773 },
      items: 3,
      gap: 1,
    },
    tablet1: {
      breakpoint: { max: 772, min: 464 },
      items: 2,
      gap: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      gap: 1,
    },
  };


  const handleIndustryChange = (value) => {
    setDefaultCheck(value.id)
    props.setShownFilterBtn(true)
    props.selectedIndustryById(value.id)
  };

  return (
    <>
      <div className="company_trustlist_main_sec1">
        <Carousel
          responsive={responsive}
          className={classes.arrow}
          centerMode
          // className={`${classes.arrow} align-left`}
          // arrows
          focusOnSelect={true}
          swipeable
          infinite
          // customLeftArrow={<CustomLeftArrow />}
          // customRightArrow={<CustomRightArrow />}
        
        >
          {props?.industryLists?.map(value => (
            <RadioGroup
              aria-label="platform"
              defaultValue="IT Industry"
              overlay
              name="platform"
              sx={{
                flexDirection: "row",
                marginTop: "5px",
                marginLeft:"30px",
                gap: 2,
                [`& .${radioClasses.checked}`]: {
                  [`& .${radioClasses.action}`]: {
                    inset: -1,
                    border: "0.5px solid",
                    borderColor: "primary.500",
                  },
                },
                [`& .${radioClasses.radio}`]: {
                  display: "contents",
                  "& > svg": {
                    zIndex: 2,
                    position: "absolute",
                    top: "-8px",
                    right: "-8px",
                    bgcolor: "background.body",
                    borderRadius: "50%",
                  },
                },
              }}
            >
              <Sheet
                key={value.id}
                variant="outlined"
                sx={{
                  borderRadius: "md",
                  bgcolor: "background.body",
                  boxShadow: "sm",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 1.5,
                  //   p: 2,
                  paddingLeft: "12px",
                  minWidth: 165,
                  minHeight: 90,
                  "&:hover": {
                    boxShadow: "lg",
                    borderColor: "neutral.outlinedHoverBorder",
                  },
                }}
              >
                <Radio
                  id={value.id}
                  value={value.name}
                  checked={defaultCheck == value.id}
                  onChange={()=>handleIndustryChange(value)}
                  checkedIcon={<CheckCircleRoundedIcon />}
                />
                <FormLabel
                  htmlFor={value.name}
                  style={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "16px",
                  }}
                >
                  {value.name?.slice(0,10)}
                </FormLabel>
                <FormLabel
                  htmlFor={value.name}
                  style={{
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "#0F4EF6",
                  }}
                >
                  {value.companies_count !== ""?value.companies_count:0} Companies <KeyboardArrowRight />
                </FormLabel>
              </Sheet>
            </RadioGroup>
          ))}
        </Carousel>
      </div>
    </>
  );
}

export default CompanyIndustyrCardsCarousal;
