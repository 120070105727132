import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileSidebar } from "../../../store/Layout/actions";
import "../../../assets/css/sidebar/index.css";
import FeatherIcon from "feather-icons-react";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(state => state.User.user);
  const routes = [
    
    {
      id: 1,
      name: "Profile",
      slug: "/profile",
      type: "all",
      type: "all",
      icon: "user",
    },
    {
      id: 2,
      name: "Dashoard",
      slug: "/dashboard",
      type: "all",
      type: "all",
      icon: "home",
    },
   
    {
      id: 3,
      name: "Referred Me",
      slug: "/refered-jobs",
      type: "all",
      type: "all",
      icon: "gift",
    },
    {
      id: 4,
      name: "My Refferals",
      slug: "/my-referals",
      type: "all",
      type: "all",
      icon: "fast-forward",
    },
   
    {
      id: 5,
      name: "Applied Jobs",
      type: "all",
      type: "all",
      icon: "send",
      slug: "/applied-jobs",
    },
    {
      id: 6,
      name: "Saved Jobs",
      type: "all",
      type: "all",
      icon: "save",
      slug: "/my-savedjobs",
    },
    {
      id: 7,
      name: "Chats",
      type: "all",
      type: "all",
      icon: "message-circle",
      slug: "/demochat",
    },
    {
      id: 9,
      name: "Interviews",
      type: "all",
      type: "all",
      icon: "activity",
      slug: "/interviews",
    },
    // {
    //   id: 8,
    //   name: "Chat",
    //   type: "all",
    //   type: "all",
    //   icon: "message-circle",
    //   slug: "/chat",
    // },
   
    {
      id: 10,
      name: "Leadership board",
      type: "all",
      type: "all",
      icon: "book",
      slug: "/leadership-board",
    },
    {
      id: 11,
      name: "My Earning",
      type: "all",
      type: "all",
      icon: "dollar-sign",
      slug: "/myearning",
    },
    {
      id: 12,
      name: "Settings",
      type: "normal",
      icon: "settings",
      slug: "/settings",
      type: "external",
    },
    {
      id: 13,
      name: "Logout",
      type: "all",
      type: "all",
      icon: "log-out",
      slug: "/logout",
    },
   
   
  ];

  const { tab } = useSelector(store => store.ProfileSidebar);
  const changeNavigation = item => {
    navigate(item.slug);
    dispatch(updateProfileSidebar(item.id));
    if (item.name === "Logout") {
      navigate("/logout");
    }
  };

  return (
    <div style={{marginLeft:""}}>
    <div className="profile_sidebar sticky_sidebar zepulScroll">
      <ul className="sidebar_list">
        {/* {routes?.map((item, index) => (
          <>
            {user?.type !== item.type ? (
              <li
                key={item.id}
                onClick={() => changeNavigation(item)}
                className={`${
                  location?.pathname === item.slug ? "sidebar_active" : ""
                } sidebar_content`}
              >
                <FeatherIcon icon={item.icon} size="14" />
                <span>{item.name}</span>
              </li>
            ) : null
            }
          </>
        ))} */}

        {routes?.map((item, index) => (
          <React.Fragment key={item.id}>
            {user?.type !== item.type ? (
              <li
                onClick={() => changeNavigation(item)}
                className={`${
                  location?.pathname === item.slug ? "sidebar_active" : ""
                } sidebar_content`}
              >
                <FeatherIcon icon={item.icon} size="14" />
                <span>{item.name}</span>
              </li>
            ) : (
              <React.Fragment key={`empty-${index}`} />
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
    </div>
  );
}

export default Sidebar;
