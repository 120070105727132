import React, { useEffect, useState } from "react";
import CompanyAndJobProfile from "./CompanyAndJobProfile";
import JobDetails from "./JobDetails";
import JobDetailsPage from "./JobDetails2";
import JobInsights from "./JobInsights";
import { getJobPostById } from "../../helpers/dataFetcher/jobs/jobs";
import { useParams } from "react-router-dom";
import { getCompanyById } from "../../helpers/dataFetcher/companies";
import CompanyAndJobProfileDesc from "./CompanyAndJobProfileDesc";
import { getCompanyJobsById } from "../../helpers/dataFetcher/companies";
import ZepulLoader from "../../Loader/ZepulLoader";

function BrowseJobsList() {

  const searchParams = new URLSearchParams(window.location.search);
const id = searchParams.get('id');

  // const { id } = useParams();
  const [Job, setJob] = useState({});
  const [company, setCompany] = useState({});
  const [compJobList,setCompJobList] = useState()
  const [loader,setLoader] = useState(true)
  useEffect(() => {
    window.scrollTo(0, 0);
    dataFetcher();
  }, []);
  async function dataFetcher() {
    const { data } = await getJobPostById(id);
    setJob(data);
    const { data: company_data } = await getCompanyById(data?.company_user_id);
    setCompany(company_data);
    const CompanyJobsData = await getCompanyJobsById(data?.company_user_id)
    const removeCurrentCompany = CompanyJobsData?.data?.filter((each)=>each.id !== id)
    console.log(removeCurrentCompany,"removeCurrentCompany")
    setCompJobList(removeCurrentCompany)
    // setCompJobList(CompanyJobsData?.data)
    setLoader(!loader)
  }

  if (loader) {
    return <ZepulLoader />; // Show the loader component while data is being fetched
  }

  return (
  
    <div className="section-box mt-50">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-12">
            <JobDetailsPage jobDetails={Job} company={company}/>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-12 pl-40 pl-lg-15 mt-lg-30 job_company_sidebar">
            <CompanyAndJobProfileDesc company={company} compJobList={compJobList}/>
          </div>
        </div>
      </div>
    </div>

  );
}
export default BrowseJobsList;
