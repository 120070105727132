import React, { useEffect, useState, lazy, Suspense, memo } from "react";
import MainSearchFilterBar from "../MainSearchFilterBar/MainSearchFilterBar";
import JobsList from "./Alljobs/JobsList";
import {
  findAllJobs,
  findAllFuncArea,
  findJobTypes,
  findKeySkills,
  findSalaryRangeTypes,
  findWorkMode,
} from "../../helpers/dataFetcher/jobs/jobs";
import {
  fetchFilteredJobs,
  applyFilteredJobs,
} from "../../helpers/dataFetcher/jobs/jobs";
import SidePanelFilters from "./JobsListSidePanel/SidePanelFilter";
import { useSearchParams } from "react-router-dom";
// import SmallScreenFilter from "../MainSearchFilterBar/smallScreenFilter";
import SocketConnection from "../../hooks/socket";
import Button from "@mui/joy/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { useSelector } from "react-redux";
import "../../assets/css/Jobs/jobsPage.css";

const SmallScreenFilter = lazy(() =>
  import("../MainSearchFilterBar/smallScreenFilter")
);

function MainJobsList() {
  const [load, setLoad] = useState(true);
  const [jobsList, setJobsList] = useState([]);
  const [serched, setSeached] = useState(true);
  const [functionalArea, setFunctionalArea] = useState();
  const [jobTypes, setJobTypes] = useState();
  const [work, setWork] = useState();
  const [keySkills, setKeySkills] = useState();
  const [filterList, setFilterList] = useState([]);
  const [salaryRange, setSalaryRange] = useState();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filterApplied, setFilterApplied] = useState(true);
  const [workMode, setWorkMode] = useState();
  const [filterClear, setFilterClear] = useState(false);
  const {
    lastMessage,
    sendMessage: sendSocketMessage,
    connectionStatus,
  } = SocketConnection();
  const userData = useSelector(state => state.User);

  useEffect(() => {
    // window.scrollTo(0, 0);
    setLoad(true);
    fetchAllJobs();
  }, []);

  // useEffect(() => {}, [jobsList]);
  // useEffect(() => {
  //   window.addEventListener("scroll", e => {
  //     const o = document.querySelector("html");

  //     // console.log(window.innerHeight+window.scrollY,document.body.offsetHeight)
  //   //   if ((o.innerHeight + o.scrollY) >= o.offsetHeight) {
  //   //     console.log('hello you are at the bottom')
  //   // }
  //   // console.log(document.body.offsetHeight,window.scrollY)
  //     if (o.offsetHeight + window.scrollY >2801) {
  //       // alert("End");
  //       setOffset(11), setLimit(20);
  //     }
  //   });
  // });
  let [searchParams, _] = useSearchParams();

  /*socket integration for Jobs page start */

  useEffect(() => {}, [connectionStatus]);

  useEffect(() => {
    if (lastMessage?.data) {
      const resultant = JSON.parse(lastMessage?.data);
      if (resultant?.type !== "verified_job") {
        filterJobsBased();
      }else {
        filterJobsBased();
      }
    }
  }, [lastMessage]);

  /*socket integration for Jobs page End */

  useEffect(() => {
    fechJobsBasedonOffset(
      searchParams.get("title"),
      searchParams.get("location"),
      searchParams.getAll("fn_areas"),
      searchParams.getAll("job_type"),
      searchParams.get("exp"),
      searchParams.getAll("salary"),
      searchParams.getAll("key_skills"),
      searchParams.getAll("work_mode")
    );
  }, [
    limit,
    offset,
    searchParams.get("title"),
    searchParams.get("location"),
    searchParams.get("fn_areas"),
    searchParams.get("job_type"),
    searchParams.get("exp"),
    searchParams.get("salary"),
    searchParams.get("key_skills"),
    searchParams.get("work_mode"),
  ]);

  const setSeshuModal = () => {
    filterJobsBased();
  };

  // const fechJobsBasedonOffset = async (
  //   title,
  //   location,
  //   fn_area,
  //   job_type,
  //   exp,
  //   salary,
  //   key_skills,
  //   work_mode
  // ) => {
  //   const response = await findAllJobs({
  //     job_post_title: title,
  //     location: location,
  //     job_type: job_type,
  //     // experience: exp,
  //     experience: exp?`0,${exp}`:"",
  //     salary: salary,
  //     functional_area: fn_area,
  //     key_skills: key_skills,
  //     work_mode:work_mode,
  //     limit: limit,
  //     offset: offset,
  //   });
  //   if (response.status === 200) {
  //     setLoad(false);
  //     setFilterApplied(false);
  //   }
  //   //  setJobsList([...jobsList, ...response.data]);
  //    setJobsList([...response.data]);
  // };

  /* Testing purpose of filters Bug */

  // useEffect(() => {
  //   filterJobsBased();
  // }, [searchParams]);

  const filterJobsBased = () => {
    fechJobsBasedonOffset(
      searchParams.get("title"),
      searchParams.get("location"),
      searchParams.getAll("fn_areas"),
      searchParams.getAll("job_type"),
      searchParams.get("exp"),
      searchParams.getAll("salary"),
      searchParams.getAll("key_skills"),
      searchParams.getAll("work_mode")
    );
  };

  /* Testing purpose of filters Bug */

  /*candidate id passing test in progress start*/
  const fechJobsBasedonOffset = async (
    title,
    location,
    fn_area,
    job_type,
    exp,
    salary,
    key_skills,
    work_mode
  ) => {
    if (localStorage.getItem("token") !== null) {
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        limit: limit,
        offset: offset,
        candidate_id: userData.user.id,
      });
      if (response.status === 200) {
        setLoad(false);
        setFilterApplied(false);
      }
      //  setJobsList([...jobsList, ...response.data]);
      setJobsList([...response.data]);
    } else {
      const response = await findAllJobs({
        job_post_title: title,
        location: location,
        job_type: job_type,
        // experience: exp,
        experience: exp ? `0,${exp}` : "",
        salary: salary,
        functional_area: fn_area,
        key_skills: key_skills,
        work_mode: work_mode,
        limit: limit,
        offset: offset,
      });
      if (response.status === 200) {
        setLoad(false);
        setFilterApplied(false);
      }
      //  setJobsList([...jobsList, ...response.data]);
      setJobsList([...response.data]);
    }
  };

  /*candidate id passing test in progress end*/

  const fetchAllJobs = async () => {
    const funcResponse = await findAllFuncArea();
    const fetchJobTypes = await findJobTypes();
    const fetchSalaryRange = await findSalaryRangeTypes();
    const fetchKeySkills = await findKeySkills();
    const fetchWorkMode = await findWorkMode();
    setFunctionalArea(funcResponse.data);
    setJobTypes(fetchJobTypes.data);
    setKeySkills(fetchKeySkills.data);
    setWorkMode(fetchWorkMode.data);
    setSalaryRange(fetchSalaryRange.data);
  };

  const handleSearch = async (text, location) => {
    setFilterList([...filterList, text, location]);

    const response = await fetchFilteredJobs(text, location);

    setJobsList(response.data);
  };

  const expFilter = async exp => {
    setFilterList([...filterList, exp]);
    const response = await applyFilteredJobs(exp);
    setJobsList(response.data);
  };
  const sidebarFilter = e => {};
  //  var top
  //   window.addEventListener("scroll", function(event) {
  //       top = this.scrollY

  // }, false);
  const [scroll, setScroll] = useState();
  useEffect(() => {
    window.addEventListener(
      "scroll",
      function (event) {
        var top = this.scrollY;
        setScroll(top);
      },
      false
    );
  }, [scroll]);

  return (
    <>
      <div className="section-box pt-2">
        <div className="employer_jobs_list_cont">
          <div className="row" style={{ position: "relative" }}>
            <div className="col-lg-3 col-md-4 col-sm-12 side_panel_container">
              <SidePanelFilters
                setJobsList={setJobsList}
                sidebarFilter={sidebarFilter}
                functionalArea={functionalArea}
                jobTypes={jobTypes}
                workTypes={workMode}
                salaryRange={salaryRange}
                keySkills={keySkills}
                top={scroll}
                updateSkills={data => setKeySkills(data)}
                updateSalaryRange={data => setSalaryRange(data)}
                update={data => setFunctionalArea(data)}
                expFilter={val => expFilter(val)}
                filterJobs={() => filterJobsBased()}
                setFilterClear={setFilterClear}
              />
            </div>

            <div className="col-lg-9 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <MainSearchFilterBar
                    limit={limit}
                    offset={offset}
                    setJobsList={setJobsList}
                    setLoad={setLoad}
                  />
                </div>

                <div className="sm_job_filter">
                  <Suspense fallback={<div>Loading...</div>}>
                    <SmallScreenFilter
                      functionalArea={functionalArea}
                      keySkills={keySkills}
                      salaryRange={salaryRange}
                      jobTypes={jobTypes}
                      workTypes={workMode}
                      filterJobs={() => filterJobsBased()}
                    />
                  </Suspense>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  <JobsList
                    jobsList={jobsList}
                    setJobsList={setJobsList}
                    functionalArea={functionalArea}
                    jobTypes={jobTypes}
                    salaryRange={salaryRange}
                    keySkills={keySkills}
                    update={data => setFunctionalArea(data)}
                    updateSkills={data => setKeySkills(data)}
                    updateSalaryRange={data => setSalaryRange(data)}
                    expFilter={val => expFilter(val)}
                    load={load}
                    fechJobsBasedonOffset={() => filterJobsBased()}
                    setSeshuModal={setSeshuModal}
                  />{" "}
                  {jobsList.length >= 10 ? (
                    <Button
                      style={{ backgroundColor: "#081753" }}
                      variant="solid"
                      className="refer-now-btn cursor-pointer "
                      disabled={jobsList.length == 0}
                      onClick={() => {
                        // setOffset(offset + 11);
                        setOffset(offset);
                        setLimit(limit + 11);
                      }}
                    >
                      Load More
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainJobsList;
