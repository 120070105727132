import React from "react";

function RelatedPosts() {
    return (
        <>
            <div className="related-posts mt-50">
                <h4 className="mb-30">Related Posts</h4>
                {/* <MultiCarousel /> */}
                {/* <div className="box-swiper">
                    <div className="swiper-container swiper-group-3">
                        <div className="swiper-wrapper pb-30 pt-5">
                            <div className="swiper-slide">
                                <div className="card-grid-3 hover-up p-15">
                                    <a href="blog-single.html">
                                        <figure><img alt="Zepul"
                                            src="assets/imgs/blog/img-blog-1.png" /></figure>
                                    </a>
                                    <h6 className="heading-md mt-15 mb-0"><a href="blog-single.html">Senior
                                        Full Stack, Creator Success Full Time</a></h6>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="card-grid-3 hover-up p-15">
                                    <a href="blog-single.html">
                                        <figure><img alt="Zepul"
                                            src="assets/imgs/blog/img-blog-2.png" /></figure>
                                    </a>
                                    <h6 className="heading-md mt-15 mb-0"><a href="blog-single.html">21 Job
                                        Tips: Make a Great Impression For You</a></h6>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="card-grid-3 hover-up p-15">
                                    <a href="blog-single.html">
                                        <figure><img alt="Zepul"
                                            src="assets/imgs/blog/img-blog-3.png" /></figure>
                                    </a>
                                    <h6 className="heading-md mt-15 mb-0"><a href="blog-single.html">How To
                                        Break Up Your Day In Morning</a></h6>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="card-grid-3 hover-up p-15">
                                    <a href="blog-single.html">
                                        <figure><img alt="Zepul"
                                            src="assets/imgs/blog/img-blog-4.png" /></figure>
                                    </a>
                                    <h6 className="heading-md mt-15 mb-0"><a href="blog-single.html">How To
                                        Create a Resume for a Job In France</a></h6>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-pagination swiper-pagination3"></div>
                    </div>
                </div> */}
            </div>
        </>
    )
}
export default RelatedPosts;