import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import CompanyOverview from "./overView";
import CompanyJobsList from "./companyJobsList";
import noJobs from "../../../assets/images/no_jobs.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)(({ theme }) => ({
  fontSize: 16,
  fontFamily: "Inter,sans-serif",
  textTransform: "none",
  "&:first-letter": {
    textTransform: "capitalize",
  },
}));

function EmployerDescription({ company, list }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <section className="company_overview_tabs">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                overflowX: "scroll",
                ".MuiTabs-indicator": {
                  backgroundColor: "#081753",
                  color: "#081753",
                },
                ".MuiTab-root.Mui-selected ": {
                  color: "#081753",
                  // backgroundColor:"#081753",
                  borderRadius: "8px",
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 700,
                  latterSpacing: "-0.1px !important",
                },
                "& .css-heg063-MuiTabs-flexContainer": {
                  overflow: "auto",
                },
              }}
            >
              {/* <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Jobs" {...a11yProps(1)} /> */}
              <StyledTab label="Overview" {...a11yProps(0)} />
              <StyledTab label="Jobs" {...a11yProps(0)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <CompanyOverview companyData={company} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* Jobs Section will add soon */}
            {list?.length > 0 ? (
              <CompanyJobsList lists={list} />
            ) : (
              <div className="d-flex align-items-center flex-column">
                <img
                  src={noJobs}
                  style={{ width: "300px", height: "300px" }}
                  alt="no jobs"
                />
                <p>No Jobs Found</p>
              </div>
            )}
          </TabPanel>
        </Box>
      </section>
    </>
  );
}

export default EmployerDescription;
