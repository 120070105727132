import * as React from "react";
import Button from "@mui/joy/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import { Link } from "react-router-dom";

export default function LoginNavs() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-demo-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="outlined"
        color="neutral"
        onClick={handleClick}
      >
        Login
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        style={{
          padding: "10px",
          background: "white",
          backdropFilter: "blur(13px)",
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="basic-demo-button"
      >
        <MenuItem onClick={handleClose} component={Link} to="/login">
          JobSeeker
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          target={"_blank"}
          to="//app.zepul.com/login?user_type=CA"
        >
          Employer
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          target={"_blank"}
          to="//app.zepul.com/login?user_type=SP"
        >
          Partner
        </MenuItem>
      </Menu>
    </div>
  );
}
