import React, { useState } from "react";
import Button from "@mui/joy/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import referDummy from "../../assets/images/referNowDummy.png";
import { useNavigate } from "react-router-dom";
import jobss from '../../assets/images/happy.png';
const styleCard = {
  background: "rgba(91, 101, 141, 0.16)",
  borderRadius: "16px",
  boxShadow: "0px !important",
};

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgba(91, 101, 141, 0.16) !important",
    borderRadius: "16px !important",
    boxShadow: "0px !important",
    minHeight: "225px",
  },
  referPostTxt: {
    fontFamily: "Satoshi-Variable",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "27px",
    color: "#081753 !important",
  },
});

function ReferPage1() {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <section className="section-box" style={{ paddingLeft: "2%" }}>
        <div className="latest_jobs_cont_main">
          <div className="row home_category_head">
            <div className="col-lg-7">
              <h2 className="refer_2_zepul_txt">
                How <span>Zepul </span>works for Referral Partners
              </h2>
              <p className="refer_2_zepulpara_txt">
                We incentivize the public to refer candidates from their network
                directly to you.Imagine having 100's of agents working on your
                position!
              </p>
            </div>
            <div className="col-lg-5 text-lg-end justify-content-end">
              <Button
                variant="outlined"
                className="apply-now-btn cursor-pointerf"
                onClick={() => navigate("/")}
                style={{
                  background: "#ffffff",
                  color: "#012480",
                  fontFamily: "Plus Jakarta Display,sans-serif",
                  border: "1px solid rgba(25, 103, 210, 0.23)",
                  fontWeight: "400",
                  fontSize: "16px",
                  marginRight: "23px",
                  borderRadius: "24px",
                }}
              >
                Show All <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
              </Button>
            </div>
          </div>

          <div className="refer2_main_cont">
            <div className="col-sm-12 col-md-12 col-lg-7">
              <div className="refer2_inside_cont1">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={4}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "20px" }}
                    >
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                01
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec"
                          >
                            Create an account on Zepul <span></span>
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            Register on Zepul as a referral partner to start
                            referring to active jobs with monetary rewards
                            available on the platform<span></span>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                02
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec2"
                          >
                            Find Active and Relevant Jobs{" "}
                            {/* <span className="refersec2_head_sec2_span">
                              advertise your position
                            </span>
                            to relevant networks */}
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            {/* We custom-build an audience on social media and
                            advertise your job & the offered reward - not to
                            candidates. reward to */}
                            Check out job listing for active job posting along
                            with the job description, requirements and referral
                            rewards
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      style={{ marginTop: "20px" }}
                    >
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                03
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec2"
                          >
                            {/* Receive{" "}
                            <span className="refersec2_head_sec2_span">
                              unlimited{" "}
                            </span>
                            referrals */}
                            Refer Suitable Candidates
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            {/* We custom-build an audience on social media and
                            advertise your <span>job & the offered reward</span> - not to
                            candidates. */}
                            Find and refer suitable and relevant profiles to
                            jobs active on the portal after taking their consent
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                      <Card className={classes.root}>
                        <div className="refersec2_num_cont">
                          <CardHeader
                            className={classes.header}
                            avatar={
                              <Avatar
                                sx={{
                                  bgcolor: "#081753",
                                  color: "#ffffff",
                                  fontSize: 20,
                                  fontWeight: 700,
                                }}
                                aria-label="recipe"
                              >
                                04
                              </Avatar>
                            }
                          />
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_head_sec"
                          >
                            {/* Hire the best <span>-- only pay if you retain</span> */}
                            Earn for Every Successful Hiring
                          </Typography>
                        </div>

                        <CardContent
                          sx={{
                            paddingTop: "0px !important",
                            paddingLeft: "5% !important",
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className="refersec2_paragra_sec"
                            sx={classes.text}
                          >
                            {/* We custom-build an audience on social media and
                            advertise your <span>job & the offered reward</span> - not to
                            candidates. */}
                            You will be rewarded for every successful hiring of
                            the candidates you have referred after completion of
                            probation period
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </div>

            <div className="col-sm-12 col-md-12 col-lg-5 refer2_container">
              <div className="refer2_inside_cont2">
                <div className="refer2_inside2_cont">
                  {/* <div style={{}}>
                    <div className="compan_inside_sec">
                      <h2 className="partners_txt1">For Partners</h2>
                      <h2 className="partners_txt2">Login</h2>
                      <Button
                        variant="outlined"
                        className="apply-now-btn cursor-pointerf"
                        onClick={() =>
                          window.open(
                            "https://app.zepul.com/login?user_type=CA",
                            "_blank"
                          )
                        }
                        style={{
                          background: "#ffffff",
                          color: "#012480",
                          fontFamily: "Plus Jakarta Display,sans-serif",
                          fontWeight: "400",
                          fontSize: "16px",
                          marginTop: "10px",
                          marginRight: "23px",
                          borderColor: "#ffffff",
                          borderRadius: "24px",
                          marginBottom: "15px !important",
                        }}
                      >
                        Refer Now{" "}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </Button>
                    </div>
                  </div> */}

                  <div style={{textAlign:"center",height:"100%"}}>
                  <img src={jobss} style={{height:"70%"}}/>
                  <h2 className="partners_txt1 mt-3">For Partners Login</h2>
                  <Button
                        variant="outlined"
                        className="apply-now-btn cursor-pointerf"
                        onClick={() =>
                          window.open(
                            "https://app.zepul.com/login?user_type=CA",
                            "_blank"
                          )
                        }
                        style={{
                          background: "#ffffff",
                          color: "#012480",
                          fontFamily: "Plus Jakarta Display,sans-serif",
                          fontWeight: "400",
                          fontSize: "16px",
                          marginTop: "10px",
                          marginRight: "23px",
                          borderColor: "#ffffff",
                          borderRadius: "24px",
                          marginBottom: "15px !important",
                        }}
                      >
                        Refer Now{" "}
                        <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                      </Button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReferPage1;
