import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/joy/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import zepulDesktop from "../../assets/images/zepulDesktop.png";
import { useNavigate } from "react-router-dom";
import signUpImage from "../../assets/images/signUp.png";
function EmployerPage1() {
  const navigate = useNavigate();
  return (
    <>
      <div className="hire_candidates_card_section_layout mt-5">
        <section className="refer_section_cont">
          <div className="referpage_main_container">
            <div className="refer_Page_main_sec">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="refer_head_sec">
                  <h2 className="refer_hire_txt">
                    Streamline your <br />
                    <span>hiring</span>
                    <br /> process to discover ideal candidates
                  </h2>
                  <p className="refer_headPara_txt">
                    Hire candidates who are an exact fit for your roles,
                    leveraging AI-driven technology to surface high-quality
                    individuals based on their proven skills, accomplishments,
                    and not just traditional resume content.
                  </p>
                  <Button
                    variant="outlined"
                    className="apply-now-btn cursor-pointerf"
                    onClick={() => navigate("/jobs-list")}
                    style={{
                      background: "#ffffff",
                      color: "#012480",
                      fontFamily: "Plus Jakarta Display,sans-serif",
                      fontWeight: "400",
                      fontSize: "16px",
                      marginTop: "10px",
                      marginRight: "23px",
                      borderColor: "#ffffff",
                      borderRadius: "24px",
                      marginBottom: "15px !important",
                    }}
                  >
                    Refer Now{" "}
                    <ArrowForwardIcon style={{ paddingLeft: "3px" }} />
                  </Button>
                </div>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="refer_dashboard_sec">
                  <img
                    src={signUpImage}
                    className="zepul_dash_img"
                    style={{ width: "80%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default EmployerPage1;
